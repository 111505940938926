import {
  ADD_AVATARS_ALTERNATIVA_INIT,
  ADD_AVATARS_ALTERNATIVA_SUCCESS,
  ADD_AVATARS_ALTERNATIVA_FAILURE,
  RESET_ADD_AVATARS_ALTERNATIVA,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetAddAvatarAlternativa() {
  return {
    type: RESET_ADD_AVATARS_ALTERNATIVA,
  };
}

/* Get AddAvatarAlternativa (Async) */

function addAvatarAlternativaSuccess(data) {
  return {
    type: ADD_AVATARS_ALTERNATIVA_SUCCESS,
    payload: data,
  };
}

function addAvatarAlternativaFailure(error) {
  console.log(error);
  return {
    type: ADD_AVATARS_ALTERNATIVA_FAILURE,
    payload: error,
  };
}

function addAvatarAlternativaInit() {
  return {
    type: ADD_AVATARS_ALTERNATIVA_INIT,
  };
}

export function addAvatarAlternativa(data) {
  return async (dispatch) => {
    dispatch(addAvatarAlternativaInit());
    try {
      const resp = await API.data.addAvatars(data);
      return dispatch(addAvatarAlternativaSuccess(resp));
    } catch (error) {
      return dispatch(addAvatarAlternativaFailure(error));
    }
  };
}
