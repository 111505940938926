import React from "react";
import Title from "../../component/load_usuario/title";
import FormAddUserExcel from "../../component/add_user_excel/form";

const AddUserExcel = (props) => {
  return (
    <section>
      <Title />
      <FormAddUserExcel id={props.match.params.id} />
    </section>
  );
};

export default AddUserExcel;
