import {
  POST_DELETE_JORNADA_INIT,
  POST_DELETE_JORNADA_SUCCESS,
  POST_DELETE_JORNADA_FAILURE,
  RESET_DELETE_JORNADA,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetDeleteJornada() {
  return {
    type: RESET_DELETE_JORNADA,
  };
}

/* Get JornadaHoliday (Async) */

function postDeleteJornadaSuccess(data) {
  return {
    type: POST_DELETE_JORNADA_SUCCESS,
    payload: data,
  };
}

function postDeleteJornadaFailure(error) {
  console.log(error);
  return {
    type: POST_DELETE_JORNADA_FAILURE,
    payload: error,
  };
}

function postDeleteJornadaInit(data) {
  return {
    type: POST_DELETE_JORNADA_INIT,
    payload: data.loading,
  };
}

export function postDeleteJornada(data) {
  return async (dispatch) => {
    dispatch(postDeleteJornadaInit(data));
    try {
      const resp = await API.data.postDeleteJornada(data);
      return dispatch(postDeleteJornadaSuccess(resp));
    } catch (error) {
      return dispatch(postDeleteJornadaFailure(error));
    }
  };
}
