import React from "react";
import Title from "../../component/add_group/title";
import FormAddGroup from "../../component/add_group/form";


const AddGroup = (props) => {
  return (
    <section>
      <Title />
      <FormAddGroup id={props.match.params.id} />
    </section>
  );
};

export default AddGroup;
