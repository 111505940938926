import {
  GET_BOLSA_RECURSO_INIT,
  GET_BOLSA_RECURSO_SUCCESS,
  GET_BOLSA_RECURSO_FAILURE,
  RESET_BOLSA_RECURSO,
  UPDATE_BOLSA_RECURSO_INIT,
  UPDATE_BOLSA_RECURSO_SUCCESS,
  UPDATE_BOLSA_RECURSO_FAILURE,
  RESET_UPDATE_BOLSA_RECURSO,
} from "./types";
import API from "./api";

export function resetBolsaRecurso() {
  return {
    type: RESET_BOLSA_RECURSO,
  };
}

function getBolsaRecursoSuccess(data) {
  return {
    type: GET_BOLSA_RECURSO_SUCCESS,
    payload: data,
  };
}

function getBolsaRecursoFailure(error) {
  console.log(error);
  return {
    type: GET_BOLSA_RECURSO_FAILURE,
    payload: error,
  };
}

function getBolsaRecursoInit(data) {
  return {
    type: GET_BOLSA_RECURSO_INIT,
    payload: data.loading,
  };
}

export function getBolsaRecurso(data) {
  return async (dispatch) => {
    dispatch(getBolsaRecursoInit(data));
    try {
      const resp = await API.data.getBolsa(data);
      return dispatch(getBolsaRecursoSuccess(resp));
    } catch (error) {
      return dispatch(getBolsaRecursoFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateBolsaRecurso() {
  return {
    type: RESET_UPDATE_BOLSA_RECURSO,
  };
}

function updateBolsaRecursoSuccess(data) {
  return {
    type: UPDATE_BOLSA_RECURSO_SUCCESS,
    payload: data,
  };
}

function updateBolsaRecursoFailure(error) {
  return {
    type: UPDATE_BOLSA_RECURSO_FAILURE,
    payload: error,
  };
}

function updateBolsaRecursoInit() {
  return {
    type: UPDATE_BOLSA_RECURSO_INIT,
  };
}

export function updateBolsaRecurso(data) {
  return async (dispatch) => {
    dispatch(updateBolsaRecursoInit());
    try {
      const resp = await API.data.updateBolsa(data);
      return dispatch(updateBolsaRecursoSuccess(resp));
    } catch (error) {
      return dispatch(updateBolsaRecursoFailure(error));
    }
  };
}
