import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { getCountry } from "../../actions/filters";

/* Language */
import { FormattedMessage } from "react-intl";

const FilterCountry = (props) => {
  const state = useSelector((state) => state.filters);
  const dispatch = useDispatch();
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getCountry({ loading: false }));
    };
    callsAPI();
  }, [dispatch]);

  return (
    <div className="containerFormBox">
      <Form.Group>
        <Form.Label>
          <FormattedMessage id="Countries" />
        </Form.Label>
        <Form.Control
          as="select"
          value={props.form[props.name]}
          onChange={props.handleChange}
          name={props.name}
          requeried="true"
        >
          <FormattedMessage id="Select">
            {(message) => <option value="">{message}</option>}
          </FormattedMessage>
          {state.statusCountry === 200 &&
            state.dataCountry.datos.map((item) => (
              <option key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export default FilterCountry;
