import React from "react";

/* Components */
import Title from "../../component/update_division/title";
import Form from "../../component/update_division/form";

const UpdateDivisionPage = (props) => {
  const div = props.match.params.id.split("-");
  return (
    <section>
      <Title />
      <Form id={div[0]} idEmpresa={div[1]} />
    </section>
  );
};

export default UpdateDivisionPage;
