import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import add from "../../assets/img/add.svg";

/* Actions */
import {
  GetAlternativas,
  resetAlternativas,
  DeleteAlternativa,
  resetDeleteAlternativa,
} from "../../actions/alternativas/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";

const Alternativas = (props) => {
  const dispatch = useDispatch();

  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);

  /* States */
  const getAlternativas = useSelector((state) => state.GetAlternativas);
  const div = props.match.params.id.split("-");
  let idPregunta = div[0];
  let idEmpresa = div[1];
  useEffect(() => {
    const FetchData = () => {
      dispatch(
        GetAlternativas({
          pregunta_id: parseInt(idPregunta, 10),
        })
      );
    };
    FetchData();
  }, [dispatch, idPregunta]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetAlternativas());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };

  /* Delete entidad */
  const submitDelete = () => {
    dispatch(DeleteAlternativa({ alternativa_id: idDelete }));
  };

  /* Reset el estado de delete*/
  const submitResetDelete = () => {
    dispatch(resetDeleteAlternativa());
    if (getAlternativas.statusDelete === 200) {
      if (getAlternativas.dataDelete.ejecucion.estado) {
        dispatch(
          GetAlternativas({
            pregunta_id: parseInt(idPregunta, 10),
          })
        );
      }
    }
  };

  const showAlternativas = () => {
    if (getAlternativas.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getAlternativas.data)) {
      return (
        <div className="containerAllEmpresas">
          {getAlternativas.data.map((item, key) => {
            return (
              <div className="eachEmpresa" key={key}>
                <div className="leftSideEachEmpresa">
                  <p className="containerTable">{item.id}</p>
                  <p className="containerTable">{item.clave}</p>
                  <Link
                    className="containerTable"
                    to={{
                      pathname: `/dashboard/update_alternativa/${item.id}`,
                      state: {
                        items: item,
                      },
                    }}
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                  <div className="containerTable">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li>
                            <Link
                              to={`/dashboard/add_alternativa_avatars/${item.id}`}
                            >
                              Agregar Imagen
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleModal({ id: item.id });
                  }}
                  className="rightSideEachEmpresa"
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getAlternativas.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <div className="containerEmpresa">
        <ModalDelete
          func={() => {
            submitDelete();
          }}
          state={getAlternativas}
          modal={modal}
          reset={submitResetDelete}
          setModal={setModal}
        />
        <p className="titleEmpresas">Alternativas</p>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideEachEmpresa">
            <p className="containerTable">ID</p>
            <p className="containerTable">Nombre</p>
            <p className="containerTable">Editar</p>
            <p className="containerTable">Más</p>
          </div>
          <div className="rightSideEachEmpresa">
            <p>Eliminar</p>
          </div>
        </div>
        {showAlternativas()}
      </div>
      <Link to={`/dashboard/add_alternativa/${idPregunta}-${idEmpresa}`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Alternativas;
