import initialState from "./initialState";
import {
  GET_COUNTRY_INIT,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILURE,
  RESET_COUNTRY,
  GET_LANGUAGE_INIT,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_FAILURE,
  RESET_LANGUAGE,
  GET_TIME_INIT,
  GET_TIME_SUCCESS,
  GET_TIME_FAILURE,
  RESET_TIME,
  GET_GROUP_INIT,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAILURE,
  RESET_GROUP,
  GET_DIVISION_INIT,
  GET_DIVISION_SUCCESS,
  GET_DIVISION_FAILURE,
  RESET_DIVISION,
  GET_SUBDIVISION_INIT,
  GET_SUBDIVISION_SUCCESS,
  GET_SUBDIVISION_FAILURE,
  RESET_SUBDIVISION,
  GET_SUBSUBDIVISION_INIT,
  GET_SUBSUBDIVISION_SUCCESS,
  GET_SUBSUBDIVISION_FAILURE,
  RESET_SUBSUBDIVISION,
  GET_LABELS_INIT,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAILURE,
  RESET_LABELS,
  GET_NODOS_INIT,
  GET_NODOS_SUCCESS,
  GET_NODOS_FAILURE,
  RESET_NODOS,
  GET_DIVISIONES_INIT,
  GET_DIVISIONES_SUCCESS,
  GET_DIVISIONES_FAILURE,
  RESET_DIVISIONES,
  GET_BOLSAS_INIT,
  GET_BOLSAS_SUCCESS,
  GET_BOLSAS_FAILURE,
  RESET_BOLSAS,
  GET_BOLSAS_DESEABLE_INIT,
  GET_BOLSAS_DESEABLE_SUCCESS,
  GET_BOLSAS_DESEABLE_FAILURE,
  RESET_BOLSAS_DESEABLE,
  GET_BOLSAS_RECURSO_INIT,
  GET_BOLSAS_RECURSO_SUCCESS,
  GET_BOLSAS_RECURSO_FAILURE,
  RESET_BOLSAS_RECURSO,
  GET_DESPLIEGUES_INIT,
  GET_DESPLIEGUES_SUCCESS,
  GET_DESPLIEGUES_FAILURE,
  RESET_DESPLIEGUES,
  GET_ROLES_INIT,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  RESET_ROLES,
  GET_ROLES_USER_INIT,
  GET_ROLES_USER_SUCCESS,
  GET_ROLES_USER_FAILURE,
  RESET_ROLES_USER,
  GET_AREAS_INIT,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE,
  RESET_AREAS,
  GET_TIEMPO_INIT,
  GET_TIEMPO_SUCCESS,
  GET_TIEMPO_FAILURE,
  RESET_TIEMPO,
} from "../../actions/filters/types";

export default function client(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_COUNTRY:
      return {
        ...state,
        dataCountry: {},
        loadingCountry: true,
        statusCountry: 0,
      };
    case GET_COUNTRY_INIT:
      return {
        ...state,
        loadingCountry: action.loading,
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        dataCountry: action.payload.data,
        loadingCountry: false,
        statusCountry: action.payload.status,
      };
    case GET_COUNTRY_FAILURE:
      return {
        ...state,
        dataCountry: {},
        loadingCountry: false,
        statusCountry: 501,
      };
    /* Language */
    case RESET_LANGUAGE:
      return {
        ...state,
        dataLanguage: {},
        loadingLanguage: true,
        statusLanguage: 0,
      };
    case GET_LANGUAGE_INIT:
      return {
        ...state,
        loadingLanguage: action.loading,
      };
    case GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        dataLanguage: action.payload.data,
        loadingLanguage: false,
        statusLanguage: action.payload.status,
      };
    case GET_LANGUAGE_FAILURE:
      return {
        ...state,
        dataLanguage: {},
        loadingLanguage: false,
        statusLanguage: 501,
      };
    /* Time */
    case RESET_TIME:
      return {
        ...state,
        dataTime: {},
        loadingTime: true,
        statusTime: 0,
      };
    case GET_TIME_INIT:
      return {
        ...state,
        loadingTime: action.loading,
      };
    case GET_TIME_SUCCESS:
      return {
        ...state,
        dataTime: action.payload.data,
        loadingTime: false,
        statusTime: action.payload.status,
      };
    case GET_TIME_FAILURE:
      return {
        ...state,
        dataTime: {},
        loadingTime: false,
        statusTime: 501,
      };
    /* group */
    case RESET_GROUP:
      return {
        ...state,
        dataGroup: {},
        loadingGroup: true,
        statusGroup: 0,
      };
    case GET_GROUP_INIT:
      return {
        ...state,
        loadingGroup: action.loading,
      };
    case GET_GROUP_SUCCESS:
      return {
        ...state,
        dataGroup: action.payload.data,
        loadingGroup: false,
        statusGroup: action.payload.status,
      };
    case GET_GROUP_FAILURE:
      return {
        ...state,
        dataGroup: {},
        loadingGroup: false,
        statusGroup: 501,
      };
    /* Division */
    case RESET_DIVISION:
      return {
        ...state,
        dataDivision: {},
        loadingDivision: true,
        statusDivision: 0,
      };
    case GET_DIVISION_INIT:
      return {
        ...state,
        loadingDivision: action.loading,
      };
    case GET_DIVISION_SUCCESS:
      return {
        ...state,
        dataDivision: action.payload.data,
        loadingDivision: false,
        statusDivision: action.payload.status,
      };
    case GET_DIVISION_FAILURE:
      return {
        ...state,
        dataDivision: {},
        loadingDivision: false,
        statusDivision: 501,
      };
    /* Sub Division */
    case RESET_SUBDIVISION:
      return {
        ...state,
        dataSubDivision: {},
        loadingSubDivision: true,
        statusSubDivision: 0,
      };
    case GET_SUBDIVISION_INIT:
      return {
        ...state,
        loadingSubDivision: action.loading,
      };
    case GET_SUBDIVISION_SUCCESS:
      return {
        ...state,
        dataSubDivision: action.payload.data,
        loadingSubDivision: false,
        statusSubDivision: action.payload.status,
      };
    case GET_SUBDIVISION_FAILURE:
      return {
        ...state,
        dataSubDivision: {},
        loadingSubDivision: false,
        statusSubDivision: 501,
      };
    /* Sub Division */
    case RESET_SUBSUBDIVISION:
      return {
        ...state,
        dataSubSubDivision: {},
        loadingSubSubDivision: true,
        statusSubSubDivision: 0,
      };
    case GET_SUBSUBDIVISION_INIT:
      return {
        ...state,
        loadingSubSubDivision: action.loading,
      };
    case GET_SUBSUBDIVISION_SUCCESS:
      return {
        ...state,
        dataSubSubDivision: action.payload.data,
        loadingSubSubDivision: false,
        statusSubSubDivision: action.payload.status,
      };
    case GET_SUBSUBDIVISION_FAILURE:
      return {
        ...state,
        dataSubSubDivision: {},
        loadingSubSubDivision: false,
        statusSubSubDivision: 501,
      };
    /*  Labels */
    case RESET_LABELS:
      return {
        ...state,
        dataLabels: {},
        loadingLabels: true,
        statusLabels: 0,
      };
    case GET_LABELS_INIT:
      return {
        ...state,
        loadingLabels: action.loading,
      };
    case GET_LABELS_SUCCESS:
      return {
        ...state,
        dataLabels: action.payload.data,
        loadingLabels: false,
        statusLabels: action.payload.status,
      };
    case GET_LABELS_FAILURE:
      return {
        ...state,
        dataLabels: {},
        loadingLabels: false,
        statusLabels: 501,
      };
    /* Nodos */
    case RESET_NODOS:
      return {
        ...state,
        dataNodos: {},
        loadingNodos: true,
        statusNodos: 0,
      };
    case GET_NODOS_INIT:
      return {
        ...state,
        loadingNodos: action.loading,
      };
    case GET_NODOS_SUCCESS:
      return {
        ...state,
        dataNodos: action.payload.data,
        loadingNodos: false,
        statusNodos: action.payload.status,
      };
    case GET_NODOS_FAILURE:
      return {
        ...state,
        dataNodos: {},
        loadingNodos: false,
        statusNodos: 501,
      };
    /* Divisiones */
    case RESET_DIVISIONES:
      return {
        ...state,
        dataDivisiones: {},
        loadingDivisiones: true,
        statusDivisiones: 0,
      };
    case GET_DIVISIONES_INIT:
      return {
        ...state,
        loadingDivisiones: action.loading,
      };
    case GET_DIVISIONES_SUCCESS:
      return {
        ...state,
        dataDivisiones: action.payload.data,
        loadingDivisiones: false,
        statusDivisiones: action.payload.status,
      };
    case GET_DIVISIONES_FAILURE:
      return {
        ...state,
        dataDivisiones: {},
        loadingDivisiones: false,
        statusDivisiones: 501,
      };
    /* Bolsas */
    case RESET_BOLSAS:
      return {
        ...state,
        dataBolsas: {},
        loadingBolsas: true,
        statusBolsas: 0,
      };
    case GET_BOLSAS_INIT:
      return {
        ...state,
        loadingBolsas: action.loading,
      };
    case GET_BOLSAS_SUCCESS:
      return {
        ...state,
        dataBolsas: action.payload.data,
        loadingBolsas: false,
        statusBolsas: action.payload.status,
      };
    case GET_BOLSAS_FAILURE:
      return {
        ...state,
        dataBolsas: {},
        loadingBolsas: false,
        statusBolsas: 501,
      };
    /* Bolsas */
    case RESET_BOLSAS_DESEABLE:
      return {
        ...state,
        dataBolsasDeseable: {},
        loadingBolsasDeseable: true,
        statusBolsasDeseable: 0,
      };
    case GET_BOLSAS_DESEABLE_INIT:
      return {
        ...state,
        loadingBolsasDeseable: action.loading,
      };
    case GET_BOLSAS_DESEABLE_SUCCESS:
      return {
        ...state,
        dataBolsasDeseable: action.payload.data,
        loadingBolsasDeseable: false,
        statusBolsasDeseable: action.payload.status,
      };
    case GET_BOLSAS_DESEABLE_FAILURE:
      return {
        ...state,
        dataBolsasDeseable: {},
        loadingBolsasDeseable: false,
        statusBolsasDeseable: 501,
      };
    /* Recursos */
    case RESET_BOLSAS_RECURSO:
      return {
        ...state,
        dataBolsasRecurso: {},
        loadingBolsasRecurso: true,
        statusBolsasRecurso: 0,
      };
    case GET_BOLSAS_RECURSO_INIT:
      return {
        ...state,
        loadingBolsasRecurso: action.loading,
      };
    case GET_BOLSAS_RECURSO_SUCCESS:
      return {
        ...state,
        dataBolsasRecurso: action.payload.data,
        loadingBolsasRecurso: false,
        statusBolsasRecurso: action.payload.status,
      };
    case GET_BOLSAS_RECURSO_FAILURE:
      return {
        ...state,
        dataBolsasRecurso: {},
        loadingBolsasRecurso: false,
        statusBolsasRecurso: 501,
      };
    /* Despliegues */
    case RESET_DESPLIEGUES:
      return {
        ...state,
        dataDespliegues: {},
        loadingDespliegues: true,
        statusDespliegues: 0,
      };
    case GET_DESPLIEGUES_INIT:
      return {
        ...state,
        loadingDespliegues: action.loading,
      };
    case GET_DESPLIEGUES_SUCCESS:
      return {
        ...state,
        dataDespliegues: action.payload.data,
        loadingDespliegues: false,
        statusDespliegues: action.payload.status,
      };
    case GET_DESPLIEGUES_FAILURE:
      return {
        ...state,
        dataDespliegues: {},
        loadingDespliegues: false,
        statusDespliegues: 501,
      };
    /* Roles */
    case RESET_ROLES:
      return {
        ...state,
        dataRoles: {},
        loadingRoles: true,
        statusRoles: 0,
      };
    case GET_ROLES_INIT:
      return {
        ...state,
        loadingRoles: action.loading,
      };
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        dataRoles: action.payload.data,
        loadingRoles: false,
        statusRoles: action.payload.status,
      };
    case GET_ROLES_FAILURE:
      return {
        ...state,
        dataRoles: {},
        loadingRoles: false,
        statusRoles: 501,
      };
    /* Roles  user*/
    case RESET_ROLES_USER:
      return {
        ...state,
        dataRolesUser: {},
        loadingRolesUser: true,
        statusRolesUser: 0,
      };
    case GET_ROLES_USER_INIT:
      return {
        ...state,
        loadingRolesUser: action.loading,
      };
    case GET_ROLES_USER_SUCCESS:
      return {
        ...state,
        dataRolesUser: action.payload.data,
        loadingRolesUser: false,
        statusRolesUser: action.payload.status,
      };
    case GET_ROLES_USER_FAILURE:
      return {
        ...state,
        dataRolesUser: {},
        loadingRolesUser: false,
        statusRolesUser: 501,
      };
    /* Areas*/
    case RESET_AREAS:
      return {
        ...state,
        dataAreas: {},
        loadingAreas: true,
        statusAreas: 0,
      };
    case GET_AREAS_INIT:
      return {
        ...state,
        loadingAreas: action.loading,
      };
    case GET_AREAS_SUCCESS:
      return {
        ...state,
        dataAreas: action.payload.data,
        loadingAreas: false,
        statusAreas: action.payload.status,
      };
    case GET_AREAS_FAILURE:
      return {
        ...state,
        dataAreas: {},
        loadingAreas: false,
        statusAreas: 501,
      };
    /* Areas*/
    case RESET_TIEMPO:
      return {
        ...state,
        dataTiempo: {},
        loadingTiempo: true,
        statusTiempo: 0,
      };
    case GET_TIEMPO_INIT:
      return {
        ...state,
        loadingTiempo: action.loading,
      };
    case GET_TIEMPO_SUCCESS:
      return {
        ...state,
        dataTiempo: action.payload.data,
        loadingTiempo: false,
        statusTiempo: action.payload.status,
      };
    case GET_TIEMPO_FAILURE:
      return {
        ...state,
        dataTiempo: {},
        loadingTiempo: false,
        statusTiempo: 501,
      };
    default:
      return state;
  }
}
