const initialState = {
  data: [],
  loading: false,
  errorMsg: "",
};

const GetHorariosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_HORARIOS_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_HORARIOS":
      return {
        ...state,
        data: [],
        loading: false,
        errorMsg: "",
      };
    case "GET_HORARIOS_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_HORARIOS_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
      };

    default:
      return state;
  }
};

export default GetHorariosReducer;
