export const RESET_VENTANA = "RESET_VENTANA";
export const GET_VENTANA_INIT = "GET_VENTANA_INIT";
export const GET_VENTANA_SUCCESS = "GET_VENTANA_SUCCESS";
export const GET_VENTANA_FAILURE = "GET_VENTANA_FAILURE";

export const RESET_UPDATE_VENTANA = "RESET_UPDATE_VENTANA";
export const UPDATE_VENTANA_INIT = "UPDATE_VENTANA_INIT";
export const UPDATE_VENTANA_SUCCESS = "UPDATE_VENTANA_SUCCESS";
export const UPDATE_VENTANA_FAILURE = "UPDATE_VENTANA_FAILURE";

export const RESET_UPDATE_VENTANA_USUARIO = "RESET_UPDATE_VENTANA_USUARIO";
export const UPDATE_VENTANA_USUARIO_INIT = "UPDATE_VENTANA_USUARIO_INIT";
export const UPDATE_VENTANA_USUARIO_SUCCESS = "UPDATE_VENTANA_USUARIO_SUCCESS";
export const UPDATE_VENTANA_USUARIO_FAILURE = "UPDATE_VENTANA_USUARIO_FAILURE";
