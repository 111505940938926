import {
  ADD_DESEABLE_INIT,
  ADD_DESEABLE_SUCCESS,
  ADD_DESEABLE_FAILURE,
  RESET_ADD_DESEABLE,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddDeseable() {
  return {
    type: RESET_ADD_DESEABLE,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addDeseableSuccess(data) {
  return {
    type: ADD_DESEABLE_SUCCESS,
    payload: data,
  };
}

function addDeseableFailure(error) {
  return {
    type: ADD_DESEABLE_FAILURE,
    payload: error,
  };
}

function addDeseableInit() {
  return {
    type: ADD_DESEABLE_INIT,
  };
}

export function addDeseable(data) {
  return async (dispatch) => {
    dispatch(addDeseableInit());
    try {
      const resp = await API.data.addDeseable(data);
      return dispatch(addDeseableSuccess(resp));
    } catch (error) {
      return dispatch(addDeseableFailure(error));
    }
  };
}
