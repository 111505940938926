import {
  GET_COMPANY_INIT,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAILURE,
  RESET_COMPANY,
  UPDATE_COMPANY_INIT,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  RESET_UPDATE_COMPANY,
} from "./types";
import API from "./api";

export function resetCompany() {
  return {
    type: RESET_COMPANY,
  };
}

function getCompanySuccess(data) {
  return {
    type: GET_COMPANY_SUCCESS,
    payload: data,
  };
}

function getCompanyFailure(error) {
  console.log(error);
  return {
    type: GET_COMPANY_FAILURE,
    payload: error,
  };
}

function getCompanyInit(data) {
  return {
    type: GET_COMPANY_INIT,
    payload: data.loading,
  };
}

export function getCompany(data) {
  return async (dispatch) => {
    dispatch(getCompanyInit(data));
    try {
      const resp = await API.data.getCompany(data);
      return dispatch(getCompanySuccess(resp));
    } catch (error) {
      return dispatch(getCompanyFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateCompany() {
  return {
    type: RESET_UPDATE_COMPANY,
  };
}

function updateCompanySuccess(data) {
  return {
    type: UPDATE_COMPANY_SUCCESS,
    payload: data,
  };
}

function updateCompanyFailure(error) {
  return {
    type: UPDATE_COMPANY_FAILURE,
    payload: error,
  };
}

function updateCompanyInit() {
  return {
    type: UPDATE_COMPANY_INIT,
  };
}

export function updateCompany(data) {
  return async (dispatch) => {
    dispatch(updateCompanyInit());
    try {
      const resp = await API.data.updateCompany(data);
      return dispatch(updateCompanySuccess(resp));
    } catch (error) {
      return dispatch(updateCompanyFailure(error));
    }
  };
}
