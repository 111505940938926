import {
  ADD_USUARIO_INIT,
  ADD_USUARIO_SUCCESS,
  ADD_USUARIO_FAILURE,
  RESET_ADD_USUARIO,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddUsuario() {
  return {
    type: RESET_ADD_USUARIO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addUsuarioSuccess(data) {
  return {
    type: ADD_USUARIO_SUCCESS,
    payload: data,
  };
}

function addUsuarioFailure(error) {
  return {
    type: ADD_USUARIO_FAILURE,
    payload: error,
  };
}

function addUsuarioInit() {
  return {
    type: ADD_USUARIO_INIT,
  };
}

export function addUsuario(data) {
  return async (dispatch) => {
    dispatch(addUsuarioInit());
    try {
      const resp = await API.data.addUsuario(data);
      return dispatch(addUsuarioSuccess(resp));
    } catch (error) {
      return dispatch(addUsuarioFailure(error));
    }
  };
}
