import {
  ADD_DIVISION_INIT,
  ADD_DIVISION_SUCCESS,
  ADD_DIVISION_FAILURE,
  RESET_ADD_DIVISION,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddDivision() {
  return {
    type: RESET_ADD_DIVISION,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addDivisionSuccess(data) {
  return {
    type: ADD_DIVISION_SUCCESS,
    payload: data,
  };
}

function addDivisionFailure(error) {
  return {
    type: ADD_DIVISION_FAILURE,
    payload: error,
  };
}

function addDivisionInit() {
  return {
    type: ADD_DIVISION_INIT,
  };
}

export function addDivision(data) {
  return async (dispatch) => {
    dispatch(addDivisionInit());
    try {
      const resp = await API.data.addDivision(data);
      return dispatch(addDivisionSuccess(resp));
    } catch (error) {
      return dispatch(addDivisionFailure(error));
    }
  };
}
