import {
  GET_PREGUNTA_INIT,
  GET_PREGUNTA_SUCCESS,
  GET_PREGUNTA_FAILURE,
  RESET_PREGUNTA,
  UPDATE_PREGUNTA_INIT,
  UPDATE_PREGUNTA_SUCCESS,
  UPDATE_PREGUNTA_FAILURE,
  RESET_UPDATE_PREGUNTA,
} from "./types";
import API from "./api";

export function resetPregunta() {
  return {
    type: RESET_PREGUNTA,
  };
}

function getPreguntaSuccess(data) {
  return {
    type: GET_PREGUNTA_SUCCESS,
    payload: data,
  };
}

function getPreguntaFailure(error) {
  console.log(error);
  return {
    type: GET_PREGUNTA_FAILURE,
    payload: error,
  };
}

function getPreguntaInit(data) {
  return {
    type: GET_PREGUNTA_INIT,
    payload: data.loading,
  };
}

export function getPregunta(data) {
  return async (dispatch) => {
    dispatch(getPreguntaInit(data));
    try {
      const resp = await API.data.getPregunta(data);
      return dispatch(getPreguntaSuccess(resp));
    } catch (error) {
      return dispatch(getPreguntaFailure(error));
    }
  };
}

/* Update  */

export function resetUpdatePregunta() {
  return {
    type: RESET_UPDATE_PREGUNTA,
  };
}

function updatePreguntaSuccess(data) {
  return {
    type: UPDATE_PREGUNTA_SUCCESS,
    payload: data,
  };
}

function updatePreguntaFailure(error) {
  return {
    type: UPDATE_PREGUNTA_FAILURE,
    payload: error,
  };
}

function updatePreguntaInit() {
  return {
    type: UPDATE_PREGUNTA_INIT,
  };
}

export function updatePregunta(data) {
  return async (dispatch) => {
    dispatch(updatePreguntaInit());
    try {
      const resp = await API.data.updatePregunta(data);
      return dispatch(updatePreguntaSuccess(resp));
    } catch (error) {
      return dispatch(updatePreguntaFailure(error));
    }
  };
}
