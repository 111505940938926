const initialState = {
  data: [],
  loading: false,
  errorMsg: "",
  loadingDelete: false,
  statusDelete: 0,
  errorMsgDelete: "",
};

const GetBolsaAtributosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_BOLSA_RECURSOS_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_BOLSA_RECURSOS":
      return {
        ...state,
        data: [],
        loading: false,
        errorMsg: "",
      };
    case "GET_BOLSA_RECURSOS_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_BOLSA_RECURSOS_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
      };
    /* Delete */
    case "RESET_DELETE_BOLSA_RECURSO":
      return {
        ...state,
        dataDelete: [],
        loadingDelete: false,
        statusDelete: 0,
        errorMsgDelete: "",
      };
    case "DELETE_BOLSA_RECURSO_FAIL":
      return {
        ...state,
        loadingDelete: false,
        statusDelete: 401,
        errorMsgDelete: "cant bring data",
      };
    case "DELETE_BOLSA_RECURSO_LOADING":
      return {
        ...state,
        loadingDelete: true,
        errorMsgDelete: "",
      };
    case "DELETE_BOLSA_RECURSO_SUCESS":
      return {
        ...state,
        loadingDelete: false,
        dataDelete: action.payload,
        errorMsgDelete: "",
        statusDelete: action.status,
      };
    default:
      return state;
  }
};

export default GetBolsaAtributosReducer;
