import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import FilterCompany from "./filterCompany";

function ModalAsignar(props) {
  const close = () => {
    //props.setModal(false);
    props.func();
  };

  const reset = () => {
    props.reset();
    props.setModal(false);
  };

  return (
    <>
      <Modal
        show={props.modal}
        onHide={() => reset()}
        onExit={() => reset()}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Asignar edificación
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.state.statusAsignar === 0 && (
            <div>
              {props.state.loadingAsignar ? (
                <h4>
                  <FormattedMessage id="Loading" />
                </h4>
              ) : (
                <div>
                  <h4>
                    <FormattedMessage id="AreYouSure" />
                  </h4>
                  <FilterCompany
                    name="asignar"
                    form={props.form}
                    handleChange={props.handleChangeAsignar}
                  />
                </div>
              )}
            </div>
          )}
          {props.state.statusAsignar === 200 && (
            <div>
              {props.state.dataAsignar.ejecucion.estado ? (
                <h4>Asignada con éxito</h4>
              ) : (
                <p>{props.state.dataAsignar.ejecucion.mensaje}</p>
              )}
            </div>
          )}
        </Modal.Body>
        {props.state.statusAsignar === 0 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button variant="secondary" onClick={close}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
        {props.state.statusAsignar === 200 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalAsignar;
