import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import {
  updateCredito,
  resetUpdateCredito,
  getCredito,
  resetCredito,
} from "../../actions/update_credito";

/* Componets */
import FilterTiempo from "../common/filterTiempo";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateCredito);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    codigo: "",
    descripcion: "",
    credito_unidad_tiempo_id: "",
    credito_cantidad_unidades_tiempo: "",
    ventana_cantidad_unidades_tiempo: "",
    ventana_unidad_tiempo_id: "",
    cantidad_creditos_por_ventana: "",
  });

  const [modalMessage, setModalMessage] = useState(false);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getCredito({ id: props.id }));
    };
    callsAPI();
  }, [dispatch, props.id]);

  useEffect(() => {
    return () => {
      dispatch(resetCredito());
      dispatch(resetUpdateCredito());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          setForm((prev) => ({
            ...prev,
            id: props.id,
            empresa_id: state.data.datos.empresa_id,
            codigo: state.data.datos.codigo,
            credito_cantidad_unidades_tiempo:
              state.data.datos.credito_cantidad_unidades_tiempo,
            credito_unidad_tiempo_id: state.data.datos.credito_unidad_tiempo_id,
            descripcion: state.data.datos.descripcion,
            ventana_cantidad_unidades_tiempo:
              state.data.datos.ventana_cantidad_unidades_tiempo,
            ventana_unidad_tiempo_id: state.data.datos.ventana_unidad_tiempo_id,
            cantidad_creditos_por_ventana:
              state.data.datos.cantidad_creditos_por_ventana,
          }));
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      id: parseInt(props.id, 10),
      empresa_id: parseInt(form.empresa_id, 10),
      codigo: form.codigo,
      descripcion: form.descripcion,
      credito_cantidad_unidades_tiempo: form.credito_cantidad_unidades_tiempo,
      credito_unidad_tiempo_id: form.credito_unidad_tiempo_id,
      ventana_cantidad_unidades_tiempo: form.ventana_cantidad_unidades_tiempo,
      ventana_unidad_tiempo_id: form.ventana_unidad_tiempo_id,
      cantidad_creditos_por_ventana: form.cantidad_creditos_por_ventana,
    };

    dispatch(updateCredito(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetUpdateCredito());
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Creditos y ventana de tiempo</h1>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Código</Form.Label>
              <Form.Control
                value={form.codigo}
                onChange={handleChange}
                type="text"
                name="codigo"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Descripción</Form.Label>
              <Form.Control
                value={form.descripcion}
                onChange={handleChange}
                type="text"
                name="descripcion"
              />
            </Form.Group>
          </div>

          <div className="containerFormTitle">
            <h1>Valores para creditos</h1>
          </div>
          <FilterTiempo
            name="credito_unidad_tiempo_id"
            form={form}
            handleChange={handleChange}
          />
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Cantidad unidad tiempo</Form.Label>
              <Form.Control
                value={form.credito_cantidad_unidades_tiempo}
                onChange={handleChange}
                type="number"
                name="credito_cantidad_unidades_tiempo"
              />
            </Form.Group>
          </div>
          <div className="containerFormTitle">
            <h1>Valores para ventanas</h1>
          </div>
          <FilterTiempo
            name="ventana_unidad_tiempo_id"
            form={form}
            handleChange={handleChange}
          />
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Cantidad unidad tiempo</Form.Label>
              <Form.Control
                value={form.ventana_cantidad_unidades_tiempo}
                onChange={handleChange}
                type="number"
                name="ventana_cantidad_unidades_tiempo"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Cantidad creditos por ventana</Form.Label>
              <Form.Control
                value={form.cantidad_creditos_por_ventana}
                onChange={handleChange}
                type="number"
                name="cantidad_creditos_por_ventana"
              />
            </Form.Group>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={state.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {state.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {state.statusUpdate === 200 && !state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={state.dataUpdate.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loading}
        />
      )}
      {state.statusUpdate === 200 && state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyUpdated" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
