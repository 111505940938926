import React from "react";
import { Form } from "react-bootstrap";

/* Language */
import { FormattedMessage } from "react-intl";

const FilterBolsas = (props) => {
  return (
    <div className="containerFormBox">
      <Form.Group>
        <Form.Label>Bolsas</Form.Label>
        <Form.Control
          as="select"
          value={props.form[props.name]}
          onChange={props.handleChange}
          name={props.name}
          requeried="true"
        >
          <FormattedMessage id="Select">
            {(message) => <option value="">{message}</option>}
          </FormattedMessage>
          {props.data.map((item) => (
            <option key={item.id} value={item.id}>
              {item.glosa}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export default FilterBolsas;
