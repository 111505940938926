import axios from "axios";

const API = {
  data: {
    async addAvatars(obj) {
      console.log(obj);
      const response = await axios.post(
        `/api/avatarscompany/${encodeURIComponent(JSON.stringify({}))}`,
        obj
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
