import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { resetLogin } from "../../actions/login";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

function ModalLogOut(props) {
  let history = useHistory();

  const [show, setShow] = useState(props.show);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(resetLogin());
    history.push("/");
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        onExit={logOut}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <FormattedMessage id="ExpiredSession" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="PleaseReconnect" />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalLogOut;
