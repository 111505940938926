import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import {
  getUsuario,
  resetUsuario,
  updateUsuario,
  resetUpdateUsuario,
} from "../../actions/update_usuario";

/* Componets */
import FilterCountry from "../common/filterCountry";
import FilterLanguage from "../common/filterLanguage";
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";
import FilterAreas from "../common/filterAreas";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateUsuario);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    email: "",
    estado: "",
    uid: "",
    pais_id: "",
    lenguaje_id: "",
    password: "",
    area_id: "",
    enviar_email_reserva: "",
    permite_reserva_para_otro: "",
  });

  const [modalMessage, setModalMessage] = useState(false);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getUsuario({ user_id: props.id }));
    };
    callsAPI();
  }, [dispatch, props.id]);

  useEffect(() => {
    return () => {
      dispatch(resetUsuario());
      dispatch(resetUpdateUsuario());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          setForm((prev) => ({
            ...prev,
            id: props.id,
            nombres: state.data.datos.nombres || "",
            apellido_paterno: state.data.datos.apellido_paterno || "",
            apellido_materno: state.data.datos.apellido_materno || "",
            email: state.data.datos.email || "",
            estado: state.data.datos.estado === 1 ? "1" : "0",
            uid: state.data.datos.uid || "",
            pais_id: state.data.datos.pais_id,
            lenguaje_id: state.data.datos.lenguaje_id,
            area_id: state.data.datos.area_id || "",
            enviar_email_reserva: state.data.datos.enviar_email_reserva
              ? "1"
              : "0",
            permite_reserva_para_otro: state.data.datos
              .permite_reserva_para_otro
              ? "1"
              : "0",
          }));
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      user_id: parseInt(props.id, 10),
      nombres: form.nombres,
      apellido_paterno: form.apellido_paterno,
      apellido_materno: form.apellido_materno,
      email: form.email,
      password: form.password,
      pais_id: form.pais_id,
      uid: form.uid,
      area_id: form.area_id,
      lenguaje_id: form.lenguaje_id,
      estado: form.estado === "1" ? 1 : 0,
      enviar_email_reserva: form.enviar_email_reserva === "1" ? true : false,
      permite_reserva_para_otro:
        form.permite_reserva_para_otro === "1" ? true : false,
    };

    dispatch(updateUsuario(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetUpdateUsuario());
  };

  if (state.loading || form.id === "") {
    return <Loading />;
  } else if (state.status === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.status === 200) {
    if (!state.data.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos usuario</h1>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Nombres</Form.Label>
              <Form.Control
                value={form.nombres}
                onChange={handleChange}
                type="text"
                name="nombres"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Apellido paterno</Form.Label>
              <Form.Control
                value={form.apellido_paterno}
                onChange={handleChange}
                type="text"
                name="apellido_paterno"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Apellido materno</Form.Label>
              <Form.Control
                value={form.apellido_materno}
                onChange={handleChange}
                type="text"
                name="apellido_materno"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>UID</Form.Label>
              <Form.Control
                value={form.uid}
                onChange={handleChange}
                type="text"
                name="uid"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                value={form.email}
                onChange={handleChange}
                type="text"
                name="email"
              />
            </Form.Group>
          </div>

          {/* <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                value={form.password}
                onChange={handleChange}
                type="text"
                name="password"
              />
            </Form.Group>
          </div> */}

          <FilterCountry
            name="pais_id"
            form={form}
            handleChange={handleChange}
          />
          <FilterLanguage
            name="lenguaje_id"
            form={form}
            handleChange={handleChange}
          />
          <FilterAreas
            id_parent={parseInt(props.empresa_id, 10)}
            name="area_id"
            form={form}
            handleChange={handleChange}
          />

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Form.Control
                as="select"
                value={form.estado}
                onChange={handleChange}
                name="estado"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Notificar reserva</Form.Label>
              <Form.Control
                as="select"
                value={form.enviar_email_reserva}
                onChange={handleChange}
                name="enviar_email_reserva"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Permite reserva para otro</Form.Label>
              <Form.Control
                as="select"
                value={form.permite_reserva_para_otro}
                onChange={handleChange}
                name="permite_reserva_para_otro"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={state.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {state.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {state.statusUpdate === 200 && !state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={state.dataUpdate.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
      {state.statusUpdate === 200 && state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyUpdated" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
