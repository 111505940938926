import {
  ADD_HORARIO_INIT,
  ADD_HORARIO_SUCCESS,
  ADD_HORARIO_FAILURE,
  RESET_ADD_HORARIO,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddHorario() {
  return {
    type: RESET_ADD_HORARIO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addHorarioSuccess(data) {
  return {
    type: ADD_HORARIO_SUCCESS,
    payload: data,
  };
}

function addHorarioFailure(error) {
  return {
    type: ADD_HORARIO_FAILURE,
    payload: error,
  };
}

function addHorarioInit() {
  return {
    type: ADD_HORARIO_INIT,
  };
}

export function addHorario(data) {
  return async (dispatch) => {
    dispatch(addHorarioInit());
    try {
      const resp = await API.data.addHorario(data);
      return dispatch(addHorarioSuccess(resp));
    } catch (error) {
      return dispatch(addHorarioFailure(error));
    }
  };
}
