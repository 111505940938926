import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

/* Actions */
import { addHorario, resetAddHorario } from "../../actions/add_horario";

/* Componets */
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const stateAdd = useSelector((state) => state.addHorario);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    abre: "08",
    abre1: "00",
    cierra: "17",
    cierra1: "00",
    dia_para_otro: "0",
  });

  const [modalMessage, setModalMessage] = useState(false);

  const myIds = props.id.split("(--)");
  const name = myIds[0].replaceAll("&", " ");

  useEffect(() => {
    return () => {
      dispatch(resetAddHorario());
    };
  }, [dispatch]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      fecha_servicio: moment(props.item.finicio.substr(0, 16)).format(
        "DD-MM-YYYY"
      ),
      jornada_id: props.item.id,
      nombre: name,
      abre: `${form.abre}:${form.abre1}`,
      cierra: `${form.cierra}:${form.cierra1}`,
      dia_para_otro: form.dia_para_otro === "1" ? true : false,
      empresa_id: myIds[1],
    };

    dispatch(addHorario(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    setForm({
      abre: "08",
      abre1: "00",
      cierra: "17",
      cierra1: "00",
      dia_para_otro: "0",
    });
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos horario</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group className="flex">
              <Form.Label className="full">
                <FormattedMessage id="HourStart" />
              </Form.Label>
              <Form.Control
                as="select"
                className="small"
                value={form.abre}
                onChange={handleChange}
                name="abre"
                requeried="true"
                data-number="ok"
              >
                <option value={"00"}>00</option>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
                <option value={"13"}>13</option>
                <option value={"14"}>14</option>
                <option value={"15"}>15</option>
                <option value={"16"}>16</option>
                <option value={"17"}>17</option>
                <option value={"18"}>18</option>
                <option value={"19"}>19</option>
                <option value={"20"}>20</option>
                <option value={"21"}>21</option>
                <option value={"22"}>22</option>
                <option value={"23"}>23</option>
              </Form.Control>

              <Form.Control
                as="select"
                className="small"
                value={form.abre1}
                onChange={handleChange}
                name="abre1"
                requeried="true"
                data-number="ok"
              >
                <option value={"00"}>00</option>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
                <option value={"13"}>13</option>
                <option value={"14"}>14</option>
                <option value={"15"}>15</option>
                <option value={"16"}>16</option>
                <option value={"17"}>17</option>
                <option value={"18"}>18</option>
                <option value={"19"}>19</option>
                <option value={"20"}>20</option>
                <option value={"21"}>21</option>
                <option value={"22"}>22</option>
                <option value={"23"}>23</option>
                <option value={"24"}>24</option>
                <option value={"25"}>25</option>
                <option value={"26"}>26</option>
                <option value={"27"}>27</option>
                <option value={"28"}>28</option>
                <option value={"29"}>29</option>
                <option value={"30"}>30</option>
                <option value={"31"}>31</option>
                <option value={"32"}>32</option>
                <option value={"33"}>33</option>
                <option value={"34"}>34</option>
                <option value={"35"}>35</option>
                <option value={"36"}>36</option>
                <option value={"37"}>37</option>
                <option value={"38"}>38</option>
                <option value={"39"}>39</option>
                <option value={"40"}>40</option>
                <option value={"41"}>41</option>
                <option value={"42"}>42</option>
                <option value={"43"}>43</option>
                <option value={"44"}>44</option>
                <option value={"45"}>45</option>
                <option value={"46"}>46</option>
                <option value={"47"}>47</option>
                <option value={"48"}>48</option>
                <option value={"49"}>49</option>
                <option value={"50"}>50</option>
                <option value={"51"}>51</option>
                <option value={"52"}>52</option>
                <option value={"53"}>53</option>
                <option value={"54"}>54</option>
                <option value={"55"}>55</option>
                <option value={"56"}>56</option>
                <option value={"57"}>57</option>
                <option value={"58"}>58</option>
                <option value={"59"}>59</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group className="flex">
              <Form.Label className="full">
                <FormattedMessage id="HourEnd" />
              </Form.Label>
              <Form.Control
                as="select"
                className="small"
                value={form.cierra}
                onChange={handleChange}
                name="cierra"
                requeried="true"
                data-number="ok"
              >
                <option value={"00"}>00</option>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
                <option value={"13"}>13</option>
                <option value={"14"}>14</option>
                <option value={"15"}>15</option>
                <option value={"16"}>16</option>
                <option value={"17"}>17</option>
                <option value={"18"}>18</option>
                <option value={"19"}>19</option>
                <option value={"20"}>20</option>
                <option value={"21"}>21</option>
                <option value={"22"}>22</option>
                <option value={"23"}>23</option>
              </Form.Control>

              <Form.Control
                as="select"
                className="small"
                value={form.cierra1}
                onChange={handleChange}
                name="cierra1"
                requeried="true"
                data-number="ok"
              >
                <option value={"00"}>00</option>
                <option value={"01"}>01</option>
                <option value={"02"}>02</option>
                <option value={"03"}>03</option>
                <option value={"04"}>04</option>
                <option value={"05"}>05</option>
                <option value={"06"}>06</option>
                <option value={"07"}>07</option>
                <option value={"08"}>08</option>
                <option value={"09"}>09</option>
                <option value={"10"}>10</option>
                <option value={"11"}>11</option>
                <option value={"12"}>12</option>
                <option value={"13"}>13</option>
                <option value={"14"}>14</option>
                <option value={"15"}>15</option>
                <option value={"16"}>16</option>
                <option value={"17"}>17</option>
                <option value={"18"}>18</option>
                <option value={"19"}>19</option>
                <option value={"20"}>20</option>
                <option value={"21"}>21</option>
                <option value={"22"}>22</option>
                <option value={"23"}>23</option>
                <option value={"24"}>24</option>
                <option value={"25"}>25</option>
                <option value={"26"}>26</option>
                <option value={"27"}>27</option>
                <option value={"28"}>28</option>
                <option value={"29"}>29</option>
                <option value={"30"}>30</option>
                <option value={"31"}>31</option>
                <option value={"32"}>32</option>
                <option value={"33"}>33</option>
                <option value={"34"}>34</option>
                <option value={"35"}>35</option>
                <option value={"36"}>36</option>
                <option value={"37"}>37</option>
                <option value={"38"}>38</option>
                <option value={"39"}>39</option>
                <option value={"40"}>40</option>
                <option value={"41"}>41</option>
                <option value={"42"}>42</option>
                <option value={"43"}>43</option>
                <option value={"44"}>44</option>
                <option value={"45"}>45</option>
                <option value={"46"}>46</option>
                <option value={"47"}>47</option>
                <option value={"48"}>48</option>
                <option value={"49"}>49</option>
                <option value={"50"}>50</option>
                <option value={"51"}>51</option>
                <option value={"52"}>52</option>
                <option value={"53"}>53</option>
                <option value={"54"}>54</option>
                <option value={"55"}>55</option>
                <option value={"56"}>56</option>
                <option value={"57"}>57</option>
                <option value={"58"}>58</option>
                <option value={"59"}>59</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="AttendTheNextDay" />
              </Form.Label>
              <Form.Control
                as="select"
                value={form.dia_para_otro}
                onChange={handleChange}
                name="dia_para_otro"
                requeried="true"
                data-number="ok"
              >
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormButton">
            <div>
              <Button
                disabled={stateAdd.loading}
                type="submit"
                variant="warning"
              >
                {stateAdd.loading ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Add" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {stateAdd.status === 200 && !stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={stateAdd.data.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
      {stateAdd.status === 200 && stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyAdded" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
