import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import {
  getEdificacion,
  resetEdificacion,
} from "../../actions/update_edificacion";

import {
  addAvatarEdificacion,
  resetAddAvatarEdificacion,
} from "../../actions/add_edificacion_avatars";

/* Componets */
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessageAvatars";

/* Config */
import config from "../../data";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateEdificacion);
  const stateAvatars = useSelector((state) => state.addEdificacionAvatars);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    id: "",
    avatar1: "",
    avatar1Text: false,
    avatar2: "",
    avatar2Text: false,
    avatar3: "",
    avatar3Text: false,
  });

  const [modalMessage, setModalMessage] = useState(false);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getEdificacion({ edificacion_id: props.id }));
    };
    callsAPI();
  }, [dispatch, props.id]);

  useEffect(() => {
    return () => {
      dispatch(resetEdificacion());
      dispatch(resetAddAvatarEdificacion());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          setForm((prev) => ({
            ...prev,
            id: props.id,
            avatar1Text: state.data.datos.avatar1
              ? state.data.datos.avatar1.archivo_imagen
              : false,
            avatar2Text: state.data.datos.avatar2
              ? state.data.datos.avatar2.archivo_imagen
              : false,
            avatar3Text: state.data.datos.avatar3
              ? state.data.datos.avatar3.archivo_imagen
              : false,
          }));
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id]);

  const toBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  /* Para cambiar los use states */
  const handleChangeFile = async (event) => {
    event.persist();
    const filles = await toBase64(event.target.files[0]);

    const files1 = filles.split("base64,");

    setForm((prev) => ({
      ...prev,
      [event.target.name]: files1[1],
    }));
  };

  const resetForm = () => {
    setForm({
      id: "",
      avatar1: "",
      avatar1Text: false,
      avatar2: "",
      avatar2Text: false,
      avatar3: "",
      avatar3Text: false,
    });
    dispatch(resetAddAvatarEdificacion({}));
    dispatch(getEdificacion({ edificacion_id: props.id }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    setModalMessage(true);
    dispatch(addAvatarEdificacion(form));
  };

  if (state.loading || form.id === "") {
    return <Loading />;
  } else if (state.status === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.status === 200) {
    if (!state.data.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos empresas </h1>
          </div>
          <div className="containerFormBox">
            <Form.Group controlId="code">
              <Form.Label>
                {form.avatar1Text ? (
                  <FormattedMessage id="Avatar1IsOk" />
                ) : (
                  <FormattedMessage id="Avatar1" />
                )}
              </Form.Label>
              <Form.File>
                <Form.File.Input
                  defaultValue={form.avatar1}
                  onChange={handleChangeFile}
                  name="avatar1"
                />
              </Form.File>
              <div className="containerFormImg">
                <img alt="avatar1" src={`${config.api}/${form.avatar1Text}`} />
              </div>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group controlId="code">
              <Form.Label>
                {form.avatar2Text ? (
                  <FormattedMessage id="Avatar2IsOk" />
                ) : (
                  <FormattedMessage id="Avatar2" />
                )}
              </Form.Label>
              <Form.File>
                <Form.File.Input
                  defaultValue={form.avatar2}
                  onChange={handleChangeFile}
                  name="avatar2"
                />
              </Form.File>
              <div className="containerFormImg">
                <img alt="avatar2" src={`${config.api}/${form.avatar2Text}`} />
              </div>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group controlId="code">
              <Form.Label>
                {form.avatar3Text ? (
                  <FormattedMessage id="Avatar3IsOk" />
                ) : (
                  <FormattedMessage id="Avatar3" />
                )}
              </Form.Label>
              <Form.File>
                <Form.File.Input
                  defaultValue={form.avatar3}
                  onChange={handleChangeFile}
                  name="avatar3"
                />
              </Form.File>
              <div className="containerFormImg">
                <img alt="avatar3" src={`${config.api}/${form.avatar3Text}`} />
              </div>
            </Form.Group>
          </div>
          <div className="containerFormButton">
            <div>
              <Button
                disabled={stateAvatars.loadingAddAvatars}
                type="submit"
                variant="warning"
              >
                {stateAvatars.loadingAddAvatars ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {stateAvatars.statusAddAvatars === 200 && (
        <ModalMessage
          message={stateAvatars.dataAddAvatars}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAvatars.loadingAddAvatars}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
