const initialState = {
  data: [],
  loading: false,
  errorMsg: "",
  loadingDelete: false,
  statusDelete: 0,
  errorMsgDelete: "",
};

const GetDeseableReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DESEABLES_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_DESEABLES":
      return {
        ...state,
        data: [],
        loading: false,
        errorMsg: "",
      };
    case "GET_DESEABLES_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_DESEABLES_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
      };
    /* Delete */
    case "RESET_DELETE_DESEABLE":
      return {
        ...state,
        dataDelete: [],
        loadingDelete: false,
        statusDelete: 0,
        errorMsgDelete: "",
      };
    case "DELETE_DESEABLE_FAIL":
      return {
        ...state,
        loadingDelete: false,
        statusDelete: 401,
        errorMsgDelete: "cant bring data",
      };
    case "DELETE_DESEABLE_LOADING":
      return {
        ...state,
        loadingDelete: true,
        errorMsgDelete: "",
      };
    case "DELETE_DESEABLE_SUCESS":
      return {
        ...state,
        loadingDelete: false,
        dataDelete: action.payload,
        errorMsgDelete: "",
        statusDelete: action.status,
      };
    default:
      return state;
  }
};

export default GetDeseableReducer;
