import initialState from "./initialState";
import {
  GET_BOLSA_ATRIBUTO_INIT,
  GET_BOLSA_ATRIBUTO_SUCCESS,
  GET_BOLSA_ATRIBUTO_FAILURE,
  RESET_BOLSA_ATRIBUTO,
  UPDATE_BOLSA_ATRIBUTO_INIT,
  UPDATE_BOLSA_ATRIBUTO_SUCCESS,
  UPDATE_BOLSA_ATRIBUTO_FAILURE,
  RESET_UPDATE_BOLSA_ATRIBUTO,
} from "../../actions/update_bolsa_atributo/types";

export default function bolsaAtributo(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_BOLSA_ATRIBUTO:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_BOLSA_ATRIBUTO_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_BOLSA_ATRIBUTO_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_BOLSA_ATRIBUTO_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case RESET_UPDATE_BOLSA_ATRIBUTO:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_BOLSA_ATRIBUTO_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_BOLSA_ATRIBUTO_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_BOLSA_ATRIBUTO_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };
    default:
      return state;
  }
}
