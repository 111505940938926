import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import add from "../../assets/img/add.svg";

// Actions
import {
  DeleteGroups,
  ResetDeleteGroup,
  GetGroups,
  resetGroups,
} from "../../actions/group/api";
// Components
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";
import { Link } from "react-router-dom";

const Groups = () => {
  const dispatch = useDispatch();

  // State
  const getGroups = useSelector((state) => state.GetGroups);

  useEffect(() => {
    const FetchData = () => {
      dispatch(GetGroups({}));
    };
    FetchData();
  }, [dispatch]);

  // AQUI RESET K NO SIRVE PA NA
  useEffect(() => {
    return () => {
      dispatch(resetGroups());
    };
  }, [dispatch]);

  // state para modal delete uwu
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);
  // funcion para modalMatar
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };
  // funcion para matar
  const functDelete = () => {
    dispatch(DeleteGroups({ id: idDelete }));
  };
  // reset de la funcion matar
  const resetDelete = () => {
    dispatch(ResetDeleteGroup());
    if (getGroups.statusDelete === 200) {
      if (getGroups.dataDelete.ejecucion.estado) {
        dispatch(GetGroups({}));
      }
    }
  };

  const showGroups = () => {
    if (getGroups.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getGroups.data)) {
      return (
        <div className="containerAllGroups">
          {getGroups.data.map((group, key) => {
            return (
              <div className="eachGroups" key={key}>
                <div className="leftSideEachGroups">
                  <p className="containerTableID">{group.id}</p>
                  <p className="containerSubTableGroupsName">{group.nombre}</p>
                  <Link
                    className="containerSubTableGroups"
                    to={{
                      pathname: `/dashboard/update_groups/${group.id}`,
                      state: {
                        items: group,
                      },
                    }}
                  >
                    <div className="containerSubTableGroups">
                      <i className="far fa-edit"></i>
                    </div>
                  </Link>

                  <div className="containerSubTableGroups">
                    <i className="fas fa-ellipsis-h"></i>
                  </div>
                </div>
                <div
                  className="rightSideEachGroups"
                  onClick={() => {
                    handleModal({ id: group.id });
                  }}
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getGroups.errorMsg !== "") {
      return <ModalTimeOut show={true} />;
    }
  };

  return (
    <React.Fragment>
      <div className="containerGroups">
        <ModalDelete
          func={() => {
            functDelete();
          }}
          state={getGroups}
          modal={modal}
          reset={resetDelete}
          setModal={setModal}
        />

        <p className="titleGroups">Grupos</p>
        <div className="lineGray"></div>
        <div className="containerTableGroups">
          <div className="leftSideTableGroups">
            <p className="titleLeftSideTableGroupsID">ID</p>
            <p className="titleLeftSideTableGroups">Nombre</p>
            <p className="titleLeftSideTableGroups">Editar</p>
            <p className="titleLeftSideTableGroups">Más</p>
          </div>
          <div className="rightSideTableGroups">
            <p>Eliminar</p>
          </div>
        </div>
        {showGroups()}
      </div>
      <Link to={`/dashboard/add_group/`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Groups;
