import initialState from "./initialState";
import {
  FETCH_UPLOAD_USEREXCEL_VALIDATE_INIT,
  FETCH_UPLOAD_USEREXCEL_VALIDATE_SUCCESS,
  FETCH_UPLOAD_USEREXCEL_VALIDATE_FAILURE,
  RESET_FORM_USEREXCEL_VALIDATE,
} from "../../actions/add_user_excel/types";

export default function addUserExcelValidate(state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_USEREXCEL_VALIDATE:
      return {
        ...state,
        loading: false,
        status: null,
        data: {},
      };
    case FETCH_UPLOAD_USEREXCEL_VALIDATE_INIT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_UPLOAD_USEREXCEL_VALIDATE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case FETCH_UPLOAD_USEREXCEL_VALIDATE_FAILURE:
      return {
        ...state,
        loading: false,
        status: 500,
      };

    default:
      return state;
  }
}
