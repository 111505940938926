import {
  ADD_EDIFICACION_INIT,
  ADD_EDIFICACION_SUCCESS,
  ADD_EDIFICACION_FAILURE,
  RESET_ADD_EDIFICACION,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddEdificacion() {
  return {
    type: RESET_ADD_EDIFICACION,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addEdificacionSuccess(data) {
  return {
    type: ADD_EDIFICACION_SUCCESS,
    payload: data,
  };
}

function addEdificacionFailure(error) {
  return {
    type: ADD_EDIFICACION_FAILURE,
    payload: error,
  };
}

function addEdificacionInit() {
  return {
    type: ADD_EDIFICACION_INIT,
  };
}

export function addEdificacion(data) {
  return async (dispatch) => {
    dispatch(addEdificacionInit());
    try {
      const resp = await API.data.addEdificacion(data);
      return dispatch(addEdificacionSuccess(resp));
    } catch (error) {
      return dispatch(addEdificacionFailure(error));
    }
  };
}
