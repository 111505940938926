// Compañies politicas ocupa style de granularidad
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

/* Actions */
import {
  GetAccesosRecursos,
  resetAccesosRecursos,
  ActiveRecurso,
  ResetActiveRecurso,
} from "../../actions/accesos_recursos/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalActive from "../common/modalActive";

const CompaniesPoliticas = (props) => {
  const dispatch = useDispatch();

  /* States */
  const accesosRecursos = useSelector((state) => state.GetAccesosRecursos);

  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);
  const [state, setState] = useState(false);

  useEffect(() => {
    const FetchData = () => {
      const myIds = props.match.params.id.split("-");
      dispatch(
        GetAccesosRecursos({
          empresa_id: parseInt(myIds[2], 10), // {{empresa_id}},
          recurso_id: parseInt(myIds[0], 10), //{{recurso_id}},
          considera_todo_el_grupo: parseInt(myIds[1], 10) === 1 ? true : false,
        })
      );
    };
    FetchData();
  }, [dispatch, props.match.params.id]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetAccesosRecursos());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
    setState(obj.state);
  };

  /* Delete entidad */
  const submitDelete = () => {
    const myIds = props.match.params.id.split("-");
    dispatch(
      ActiveRecurso({ recurso_id: myIds[0], user_id: idDelete, state: state })
    );
  };

  const submitResetDelete = () => {
    dispatch(ResetActiveRecurso());
    if (accesosRecursos.statusActive === 200) {
      if (accesosRecursos.dataActive.ejecucion.estado) {
        const myIds = props.match.params.id.split("-");
        dispatch(
          GetAccesosRecursos({
            empresa_id: parseInt(myIds[2], 10), // {{empresa_id}},
            recurso_id: parseInt(myIds[0], 10), //{{recurso_id}},
            considera_todo_el_grupo:
              parseInt(myIds[1], 10) === 1 ? true : false,
          })
        );
      }
    }
  };

  const showCompaniesPoliticas = () => {
    if (accesosRecursos.loading) {
      return <Loading />;
    } else if (!_.isEmpty(accesosRecursos.data)) {
      return (
        <div className="containerAllGranularidad ">
          {accesosRecursos.data.map((item, key) => {
            return (
              <div
                className={
                  item.uso_exclusivo
                    ? "eachGranularidad active"
                    : "eachGranularidad "
                }
                key={key}
              >
                <div className="leftSideGranularidad">
                  <p className="containerTableID">{item.id}</p>
                  <p className="containerTable">{item.email}</p>
                  <p className="containerTable">
                    {item.uso_exclusivo ? "SI" : "No"}
                  </p>
                  <div className="containerTable">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          {!item.uso_exclusivo && (
                            <li
                              onClick={() => {
                                handleModal({
                                  id: item.id,
                                  state: item.uso_exclusivo,
                                });
                              }}
                            >
                              Activar
                            </li>
                          )}
                          {item.uso_exclusivo && (
                            <li
                              onClick={() => {
                                handleModal({
                                  id: item.id,
                                  state: item.uso_exclusivo,
                                });
                              }}
                            >
                              Desactivar
                            </li>
                          )}
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            );
          })}
        </div>
      );
    } else if (accesosRecursos.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };

  return (
    <React.Fragment>
      <div className="containerGranularidad">
        <ModalActive
          func={() => {
            submitDelete();
          }}
          state={accesosRecursos}
          modal={modal}
          reset={submitResetDelete}
          setModal={setModal}
        />
        <p className="titleGranularidad">Empresa recursos</p>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideGranularidad">
            <p className="containerTableID">ID</p>
            <p className="containerTable">Nombre</p>
            <p className="containerTable">Permiso</p>
            <p className="containerTable">Más</p>
          </div>
        </div>
        {showCompaniesPoliticas()}
      </div>
    </React.Fragment>
  );
};

export default CompaniesPoliticas;
