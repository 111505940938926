const initialState = {
  data: [],
  loading: false,
  errorMsg: "",

  loadingDelete: false,
  statusDelete: 0,
  errorMsgDelete: "",

  loadingAsignar: false,
  statusAsignar: 0,
  errorMsgAsignar: "",

  loadingAsignarBolsas: false,
  statusAsignarBolsas: 0,
  errorMsgAsignarBolsas: "",

  loadingQr: false,
  statusQr: 0,
  errorMsgQr: "",
};

const GetEdificacionesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_EDIFICACIONES_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_EDIFICACIONES":
      return {
        ...state,
        data: [],
        loading: false,
        errorMsg: "",
      };
    case "GET_EDIFICACIONES_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_EDIFICACIONES_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
      };
    /* Delete */
    case "RESET_DELETE_EDIFICACION":
      return {
        ...state,
        dataDelete: [],
        loadingDelete: false,
        statusDelete: 0,
        errorMsgDelete: "",
      };
    case "DELETE_EDIFICACION_FAIL":
      return {
        ...state,
        loadingDelete: false,
        statusDelete: 401,
        errorMsgDelete: "cant bring data",
      };
    case "DELETE_EDIFICACION_LOADING":
      return {
        ...state,
        loadingDelete: true,
        errorMsgDelete: "",
      };
    case "DELETE_EDIFICACION_SUCESS":
      return {
        ...state,
        loadingDelete: false,
        dataDelete: action.payload,
        errorMsgDelete: "",
        statusDelete: action.status,
      };
    /* Asignar*/
    case "RESET_ASIGNAR_EDIFICACION":
      return {
        ...state,
        dataAsignar: [],
        loadingAsignar: false,
        statusAsignar: 0,
        errorMsgAsignar: "",
      };
    case "ASIGNAR_EDIFICACION_FAIL":
      return {
        ...state,
        loadingAsignar: false,
        statusAsignar: 401,
        errorMsgAsignar: "cant bring data",
      };
    case "ASIGNAR_EDIFICACION_LOADING":
      return {
        ...state,
        loadingAsignar: true,
        errorMsgAsignar: "",
      };
    case "ASIGNAR_EDIFICACION_SUCESS":
      return {
        ...state,
        loadingAsignar: false,
        dataAsignar: action.payload,
        errorMsgAsignar: "",
        statusAsignar: action.status,
      };
    /* Asignar bolsas */
    case "RESET_ASIGNAR_BOLSAS":
      return {
        ...state,
        dataAsignarBolsas: [],
        loadingAsignarBolsas: false,
        statusAsignarBolsas: 0,
        errorMsgAsignarBolsas: "",
      };
    case "ASIGNAR_BOLSAS_FAIL":
      return {
        ...state,
        loadingAsignarBolsas: false,
        statusAsignarBolsas: 401,
        errorMsgAsignarBolsas: "cant bring data",
      };
    case "ASIGNAR_BOLSAS_LOADING":
      return {
        ...state,
        loadingAsignarBolsas: true,
        errorMsgAsignarBolsas: "",
      };
    case "ASIGNAR_BOLSAS_SUCESS":
      return {
        ...state,
        loadingAsignarBolsas: false,
        dataAsignarBolsas: action.payload,
        errorMsgAsignarBolsas: "",
        statusAsignarBolsas: action.status,
      };
    /* Obtener qr */
    case "RESET_QR":
      return {
        ...state,
        dataQr: [],
        loadingQr: false,
        statusQr: 0,
        errorMsgQr: "",
      };
    case "OBTENER_QR_FAIL":
      return {
        ...state,
        loadingQr: false,
        statusQr: 401,
        errorMsgQr: "cant bring data",
      };
    case "OBTENER_QR_LOADING":
      return {
        ...state,
        loadingQr: true,
        errorMsgQr: "",
      };
    case "OBTENER_QR_SUCESS":
      return {
        ...state,
        loadingQr: false,
        dataQr: action.payload,
        errorMsgQr: "",
        statusQr: action.status,
      };
    default:
      return state;
  }
};

export default GetEdificacionesReducer;
