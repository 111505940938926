import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import add from "../../assets/img/add.svg";

/* Actions */
import { GetJornadas, resetJornadas } from "../../actions/jornadas/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";

const Jornadas = (props) => {
  const dispatch = useDispatch();

  /* States */
  const getJornadas = useSelector((state) => state.GetJornadas);
  const myIds = props.match.params.id.split("(--)");
  const name = myIds[0].replaceAll("&", " ");
  useEffect(() => {
    const FetchData = () => {
      dispatch(
        GetJornadas({
          name: name, //"[[26]] Edificio La Dehesa II",
          abre: "09:00",
          cierra: "18:00",
        })
      );
    };
    FetchData();
  }, [dispatch, name]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetJornadas());
    };
  }, [dispatch]);

  const showJornadas = () => {
    if (getJornadas.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getJornadas.data)) {
      return (
        <div className="containerAllEmpresas">
          {getJornadas.data.horarios.map((item, key) => {
            return (
              <React.Fragment key={`padre-${key}`}>
                <div className="eachEmpresa">
                  <div className="leftSideEachEmpresa">
                    <p className="containerTable">{item.edi_division.nombre}</p>
                    <p className="containerTable">{item.edi_division.nombre}</p>
                    <Link
                      className="containerTable"
                      to={{
                        pathname: `/dashboard/update_recurso/${props.match.params.id}`,
                        state: {
                          items: item,
                        },
                      }}
                    >
                      <i>iEditar</i>
                    </Link>
                    <div className="containerTable">
                      <span className="containerAction">Action</span>
                    </div>
                  </div>
                </div>
                {item.horarios.map((hr, i) => {
                  return (
                    <div
                      key={`mykey-${hr.dia.id}-${key}-${i}`}
                      className="eachEmpresa special"
                    >
                      <div className="leftSideEachEmpresa">
                        <p className="containerTable">{hr.dia.nombre}</p>
                        <p className="containerTable">{hr.abre}</p>
                        <p className="containerTable">{hr.cierra}</p>
                        <p className="containerTable">
                          {hr.atiende ? "Si" : "No"}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </React.Fragment>
            );
          })}
        </div>
      );
    } else if (getJornadas.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <div className="containerEmpresa">
        <p className="titleEmpresas">Jornadas</p>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideEachEmpresa">
            <p className="containerTable">ID</p>
            <p className="containerTable">Nombre</p>
            <p className="containerTable">Editar</p>
            <p className="containerTable">Más</p>
          </div>
        </div>
        {showJornadas()}
      </div>

      <div className="containerDelete">
        <Link to={`/dashboard/delete_jornada/${props.match.params.id}`}>
          <i className="fas fa-trash-alt"></i>
        </Link>
      </div>

      <Link to={`/dashboard/add_jornada/${props.match.params.id}`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Jornadas;
