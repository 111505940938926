import axios from "axios";

/* Get Atributos*/
export const GetAtributos = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_ATRIBUTOS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/atributos/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_ATRIBUTOS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_ATRIBUTOS_FAIL",
    });
    console.log("Error en getAtributos Action: veni a ver el desmadre", err);
  }
};

/* Reset Atributos */
export const resetAtributos = () => {
  return {
    type: "RESET_ATRIBUTOS",
  };
};

export const DeleteAtributo = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_ATRIBUTO_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/atributos/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_ATRIBUTO_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_ATRIBUTO_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteAtributo = () => {
  return {
    type: "RESET_DELETE_ATRIBUTO",
  };
};
