import {
  GET_JORNADA_HOLIDAY_INIT,
  GET_JORNADA_HOLIDAY_SUCCESS,
  GET_JORNADA_HOLIDAY_FAILURE,
  RESET_JORNADA_HOLIDAY,
  POST_NEW_JORNADA_INIT,
  POST_NEW_JORNADA_SUCCESS,
  POST_NEW_JORNADA_FAILURE,
  RESET_NEW_JORNADA,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetJornadaHoliday() {
  return {
    type: RESET_JORNADA_HOLIDAY,
  };
}

/* Get JornadaHoliday (Async) */

function getJornadaHolidaySuccess(data) {
  return {
    type: GET_JORNADA_HOLIDAY_SUCCESS,
    payload: data,
  };
}

function getJornadaHolidayFailure(error) {
  console.log(error);
  return {
    type: GET_JORNADA_HOLIDAY_FAILURE,
    payload: error,
  };
}

function getJornadaHolidayInit(data) {
  return {
    type: GET_JORNADA_HOLIDAY_INIT,
    payload: data.loading,
  };
}

export function getJornadaHoliday(data) {
  return async (dispatch) => {
    dispatch(getJornadaHolidayInit(data));
    try {
      const resp = await API.data.getJornadaHoliday(data);
      return dispatch(getJornadaHolidaySuccess(resp));
    } catch (error) {
      return dispatch(getJornadaHolidayFailure(error));
    }
  };
}

export function resetNewJornada() {
  return {
    type: RESET_NEW_JORNADA,
  };
}

/* Get JornadaHoliday (Async) */

function postNewJornadaSuccess(data) {
  return {
    type: POST_NEW_JORNADA_SUCCESS,
    payload: data,
  };
}

function postNewJornadaFailure(error) {
  console.log(error);
  return {
    type: POST_NEW_JORNADA_FAILURE,
    payload: error,
  };
}

function postNewJornadaInit(data) {
  return {
    type: POST_NEW_JORNADA_INIT,
    payload: data.loading,
  };
}

export function postNewJornada(data) {
  return async (dispatch) => {
    dispatch(postNewJornadaInit(data));
    try {
      const resp = await API.data.postNewJornada(data);
      return dispatch(postNewJornadaSuccess(resp));
    } catch (error) {
      return dispatch(postNewJornadaFailure(error));
    }
  };
}
