import {
  ADD_CARGO_INIT,
  ADD_CARGO_SUCCESS,
  ADD_CARGO_FAILURE,
  RESET_ADD_CARGO,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddCargo() {
  return {
    type: RESET_ADD_CARGO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addCargoSuccess(data) {
  return {
    type: ADD_CARGO_SUCCESS,
    payload: data,
  };
}

function addCargoFailure(error) {
  return {
    type: ADD_CARGO_FAILURE,
    payload: error,
  };
}

function addCargoInit() {
  return {
    type: ADD_CARGO_INIT,
  };
}

export function addCargo(data) {
  return async (dispatch) => {
    dispatch(addCargoInit());
    try {
      const resp = await API.data.addCargo(data);
      return dispatch(addCargoSuccess(resp));
    } catch (error) {
      return dispatch(addCargoFailure(error));
    }
  };
}
