import axios from "axios";

/* Get AccesosRecursos*/
export const GetAccesosRecursos = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_ACCESOS_RECURSOS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/accesosrecursos/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_ACCESOS_RECURSOS_SUCESS",
      payload: data.datos,
    });
    //console.log(data);
  } catch (err) {
    console.log(err);
    dispatch({
      type: "GET_ACCESOS_RECURSOS_FAIL",
    });
    console.log(
      "Error en getAccesosRecursos Action: veni a ver el desmadre",
      err
    );
  }
};

/* Reset AccesosRecursos */
export const resetAccesosRecursos = () => {
  return {
    type: "RESET_ACCESOS_RECURSOS",
  };
};

// Delete
export const ActiveRecurso = (obj) => async (dispatch) => {
  dispatch({
    type: "ACTIVE_RECURSO_LOADING",
  });
  try {
    const res = await axios.post(`/api/accesosrecursos`, obj);
    const { status } = res;
    let data = await res.data;
    dispatch({
      type: "ACTIVE_RECURSO_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "ACTIVE_RECURSO_FAIL",
    });
    console.log("Error en delete group u.u", err);
  }
};
export const ResetActiveRecurso = () => {
  return {
    type: "RESET_ACTIVE_RECURSO",
  };
};
