import {
  ADD_AVATARS_COMPANY_INIT,
  ADD_AVATARS_COMPANY_SUCCESS,
  ADD_AVATARS_COMPANY_FAILURE,
  RESET_ADD_AVATARS_COMPANY,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetAddAvatarCompany() {
  return {
    type: RESET_ADD_AVATARS_COMPANY,
  };
}

/* Get AddAvatarCompany (Async) */

function addAvatarCompanySuccess(data) {
  return {
    type: ADD_AVATARS_COMPANY_SUCCESS,
    payload: data,
  };
}

function addAvatarCompanyFailure(error) {
  console.log(error);
  return {
    type: ADD_AVATARS_COMPANY_FAILURE,
    payload: error,
  };
}

function addAvatarCompanyInit() {
  return {
    type: ADD_AVATARS_COMPANY_INIT,
  };
}

export function addAvatarCompany(data) {
  return async (dispatch) => {
    dispatch(addAvatarCompanyInit());
    try {
      const resp = await API.data.addAvatars(data);
      return dispatch(addAvatarCompanySuccess(resp));
    } catch (error) {
      return dispatch(addAvatarCompanyFailure(error));
    }
  };
}
