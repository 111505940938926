import {
  GET_ATRIBUTO_INIT,
  GET_ATRIBUTO_SUCCESS,
  GET_ATRIBUTO_FAILURE,
  RESET_ATRIBUTO,
  UPDATE_ATRIBUTO_INIT,
  UPDATE_ATRIBUTO_SUCCESS,
  UPDATE_ATRIBUTO_FAILURE,
  RESET_UPDATE_ATRIBUTO,
} from "./types";
import API from "./api";

export function resetAtributo() {
  return {
    type: RESET_ATRIBUTO,
  };
}

function getAtributoSuccess(data) {
  return {
    type: GET_ATRIBUTO_SUCCESS,
    payload: data,
  };
}

function getAtributoFailure(error) {
  console.log(error);
  return {
    type: GET_ATRIBUTO_FAILURE,
    payload: error,
  };
}

function getAtributoInit(data) {
  return {
    type: GET_ATRIBUTO_INIT,
    payload: data.loading,
  };
}

export function getAtributo(data) {
  return async (dispatch) => {
    dispatch(getAtributoInit(data));
    try {
      const resp = await API.data.getAtributo(data);
      return dispatch(getAtributoSuccess(resp));
    } catch (error) {
      return dispatch(getAtributoFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateAtributo() {
  return {
    type: RESET_UPDATE_ATRIBUTO,
  };
}

function updateAtributoSuccess(data) {
  return {
    type: UPDATE_ATRIBUTO_SUCCESS,
    payload: data,
  };
}

function updateAtributoFailure(error) {
  return {
    type: UPDATE_ATRIBUTO_FAILURE,
    payload: error,
  };
}

function updateAtributoInit() {
  return {
    type: UPDATE_ATRIBUTO_INIT,
  };
}

export function updateAtributo(data) {
  return async (dispatch) => {
    dispatch(updateAtributoInit());
    try {
      const resp = await API.data.updateAtributo(data);
      return dispatch(updateAtributoSuccess(resp));
    } catch (error) {
      return dispatch(updateAtributoFailure(error));
    }
  };
}
