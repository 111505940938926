import {
  ADD_NODO_INIT,
  ADD_NODO_SUCCESS,
  ADD_NODO_FAILURE,
  RESET_ADD_NODO,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddNodo() {
  return {
    type: RESET_ADD_NODO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addNodoSuccess(data) {
  return {
    type: ADD_NODO_SUCCESS,
    payload: data,
  };
}

function addNodoFailure(error) {
  return {
    type: ADD_NODO_FAILURE,
    payload: error,
  };
}

function addNodoInit() {
  return {
    type: ADD_NODO_INIT,
  };
}

export function addNodo(data) {
  return async (dispatch) => {
    dispatch(addNodoInit());
    try {
      const resp = await API.data.addNodo(data);
      return dispatch(addNodoSuccess(resp));
    } catch (error) {
      return dispatch(addNodoFailure(error));
    }
  };
}
