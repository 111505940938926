import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import add from "../../assets/img/add.svg";

/* Actions */
import {
  GetDeseables,
  resetDeseables,
  DeleteDeseable,
  resetDeleteDeseable,
} from "../../actions/deseables/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";

const Deseables = (props) => {
  const dispatch = useDispatch();

  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);

  /* States */
  const getDeseables = useSelector((state) => state.GetDeseables);

  useEffect(() => {
    const FetchData = () => {
      dispatch(
        GetDeseables({ bolsa_deseable_id: parseInt(props.match.params.id, 10) })
      );
    };
    FetchData();
  }, [dispatch, props.match.params.id]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetDeseables());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };

  /* Delete entidad */
  const submitDelete = () => {
    dispatch(DeleteDeseable({ deseable_id: idDelete }));
  };

  /* Reset el estado de delete*/
  const submitResetDelete = () => {
    dispatch(resetDeleteDeseable());
    if (getDeseables.statusDelete === 200) {
      if (getDeseables.dataDelete.ejecucion.estado) {
        dispatch(
          GetDeseables({
            bolsa_deseable_id: parseInt(props.match.params.id, 10),
          })
        );
      }
    }
  };

  const showDeseables = () => {
    if (getDeseables.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getDeseables.data)) {
      return (
        <div className="containerAllEmpresas">
          {getDeseables.data.map((item, key) => {
            return (
              <div className="eachEmpresa" key={key}>
                <div className="leftSideEachEmpresa">
                  <p className="containerTable">{item.id}</p>
                  <p className="containerTable">{item.glosa}</p>
                  <p className="containerTable">{item.tag}</p>
                  <Link
                    className="containerTable"
                    to={{
                      pathname: `/dashboard/update_deseable/${item.id}`,
                      state: {
                        items: item,
                      },
                    }}
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                  <div className="containerTable">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleModal({ id: item.id });
                  }}
                  className="rightSideEachEmpresa"
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getDeseables.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <div className="containerEmpresa">
        <ModalDelete
          func={() => {
            submitDelete();
          }}
          state={getDeseables}
          modal={modal}
          reset={submitResetDelete}
          setModal={setModal}
        />
        <p className="titleEmpresas">Deseables</p>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideEachEmpresa">
            <p className="containerTable">ID</p>
            <p className="containerTable">Nombre</p>
            <p className="containerTable">Tag</p>
            <p className="containerTable">Editar</p>
            <p className="containerTable">Más</p>
          </div>
          <div className="rightSideEachEmpresa">
            <p>Eliminar</p>
          </div>
        </div>
        {showDeseables()}
      </div>
      <Link to={`/dashboard/add_deseable/${props.match.params.id}`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Deseables;
