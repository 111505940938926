import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
/* Actions */
import {
  getAccesos,
  resetAccesos,
  addAcceso,
  resetAddAcceso,
} from "../../actions/accesos";

/* Language */
import { FormattedMessage } from "react-intl";

/* Componets */
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

const Content = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.accesos);
  const [modalMessage, setModalMessage] = useState(false);
  const [arbol, setArbol] = useState([]);

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getAccesos({
          loading: true,
          empresa_id: props.id,
          considera_todo_el_grupo: props.acceso === "1" ? true : false,
          user_id: props.user,
        })
      );
    };
    callsAPI();
  }, [dispatch, props.id, props.acceso, props.user]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          setArbol(state.data.datos);
        }
      }
    };
    callsAPI();
  }, [dispatch, state.loading, state.data, state.status]);

  useEffect(() => {
    return () => {
      dispatch(resetAccesos());
    };
  }, [dispatch]);

  if (state.loading) {
    return <Loading />;
  } else if (state.status === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.status === 200) {
    if (!state.data.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    dispatch(addAcceso({ datos: arbol, users: props.users }));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetAddAcceso());
    dispatch(
      getAccesos({
        loading: true,
        empresa_id: props.id,
        considera_todo_el_grupo: props.acceso === "1" ? true : false,
        user_id: props.user,
      })
    );
  };

  const handleArbol = (
    empresa,
    usuario,
    edificio,
    fdivision,
    callback,
    herencia
  ) => {
    const newArbol = [...arbol];

    /* Arbol  empresa */
    const index = newArbol.findIndex((item) => item.empresa.id === empresa);

    /* Arbol  usuario*/
    const index1 = newArbol[index].usuarios.findIndex(
      (item) => item.user_id === usuario
    );

    /* Arbol  edificacion*/
    const index2 = newArbol[index].usuarios[index1].arr_edificaciones.findIndex(
      (item) => item.edificacion_id === edificio
    );

    /* Arbol  divisiones*/
    const index3 = newArbol[index].usuarios[index1].arr_edificaciones[
      index2
    ].accesos.findIndex((item) => item.id === fdivision);

    let valor =
      newArbol[index].usuarios[index1].arr_edificaciones[index2].accesos[index3]
        .seleccionado === true
        ? false
        : true;

    if (!valor) {
      if (
        newArbol[index].usuarios[index1].arr_edificaciones[index2].accesos[
          index3
        ].restricciones
      ) {
        for (let o = 0; o < 7; o++) {
          newArbol[index].usuarios[index1].arr_edificaciones[index2].accesos[
            index3
          ].restricciones[o].tiene_acceso = false;
        }
      }
    }

    if (!herencia) {
      newArbol[index].usuarios[index1].arr_edificaciones[index2].accesos[
        index3
      ].seleccionado =
        newArbol[index].usuarios[index1].arr_edificaciones[index2].accesos[
          index3
        ].seleccionado === true
          ? false
          : true;
    } else {
      newArbol[index].usuarios[index1].arr_edificaciones[index2].accesos[
        index3
      ].seleccionado = herencia;
    }

    let arr1 = newArbol[index].usuarios[index1].arr_edificaciones[
      index2
    ].accesos.filter(function (e) {
      return e.edi_division_id === fdivision;
    });

    arr1.forEach((element) => {
      callback(empresa, usuario, edificio, element.id, callback, valor);
    });

    setArbol(newArbol);
  };

  const handleArbolDays = (
    empresa,
    usuario,
    edificio,
    fdivision,
    callback,
    herencia,
    haveDays
  ) => {
    const newArbol = [...arbol];

    /* Arbol  empresa */
    const index = newArbol.findIndex((item) => item.empresa.id === empresa);

    /* Arbol  usuario*/
    const index1 = newArbol[index].usuarios.findIndex(
      (item) => item.user_id === usuario
    );

    /* Arbol  edificacion*/
    const index2 = newArbol[index].usuarios[index1].arr_edificaciones.findIndex(
      (item) => item.edificacion_id === edificio
    );

    /* Arbol  divisiones*/
    const index3 = newArbol[index].usuarios[index1].arr_edificaciones[
      index2
    ].accesos.findIndex((item) => item.id === fdivision);

    if (haveDays >= 0) {
      let checkDays =
        newArbol[index].usuarios[index1].arr_edificaciones[index2].accesos[
          index3
        ].restricciones[haveDays].tiene_acceso === true
          ? false
          : true;

      newArbol[index].usuarios[index1].arr_edificaciones[index2].accesos[
        index3
      ].restricciones[haveDays].tiene_acceso = checkDays;
    }

    setArbol(newArbol);
  };

  const divisionComponent = (arr, id, callback, empresa, usuario, edificio) => {
    let arr1 = arr.accesos.filter(function (e) {
      return e.edi_division_id === id;
    });
    let content = [];

    arr1.forEach((fdivision) => {
      content.push(
        <div key={`${fdivision.id}}`}>
          <span
            className={fdivision.seleccionado ? "active" : ""}
            data-test={fdivision.id}
            onClick={() =>
              handleArbol(empresa, usuario, edificio, fdivision.id, handleArbol)
            }
          >
            {fdivision.nombre}
          </span>
          {callback(arr, fdivision.id, callback, empresa, usuario, edificio)}
          {fdivision.restricciones &&
            fdivision.restricciones.map((dias, indexDias) => {
              return (
                <div key={dias.id}>
                  <span
                    className={dias.tiene_acceso ? "active" : ""}
                    onClick={() => {
                      handleArbolDays(
                        empresa,
                        usuario,
                        edificio,
                        fdivision.id,
                        handleArbol,
                        false,
                        indexDias
                      );
                    }}
                  >
                    {dias.nombre}
                  </span>
                </div>
              );
            })}
        </div>
      );
    });

    return content;
  };

  return (
    <div className="containerAccesos">
      {state.statusAdd === 200 && !state.dataAdd.ejecucion.estado && (
        <ModalMessage
          message={state.dataAdd.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingAdd}
        />
      )}
      {state.statusAdd === 200 && state.dataAdd.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyAdded" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingAdd}
        />
      )}
      {arbol.map((empresa) => {
        return (
          <div key={empresa.empresa.id}>
            <span className="empresa">{empresa.empresa.nombre}</span>
            <React.Fragment>
              {empresa.usuarios.map((usuario) => {
                return (
                  <div key={`${empresa.empresa.id}-${usuario.user_id}`}>
                    <span>{usuario.email}</span>
                    <React.Fragment>
                      {usuario.arr_edificaciones.map((edificio) => {
                        return (
                          <div
                            key={`${empresa.empresa.id}-${usuario.user_id}-${edificio.edificacion_id}`}
                          >
                            <span>{edificio.nombre}</span>
                            {divisionComponent(
                              edificio,
                              edificio.accesos[0].edi_division_id,
                              divisionComponent,
                              empresa.empresa.id,
                              usuario.user_id,
                              edificio.edificacion_id
                            )}
                          </div>
                        );
                      })}
                    </React.Fragment>
                  </div>
                );
              })}
            </React.Fragment>
          </div>
        );
      })}
      <div>
        <Button
          onClick={handleSubmit}
          disabled={state.loadingAdd}
          variant="warning"
        >
          {state.loadingAdd ? (
            <FormattedMessage id="Loading" />
          ) : (
            <FormattedMessage id="Add" />
          )}
        </Button>
      </div>
    </div>
  );
};

export default Content;
