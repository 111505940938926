import axios from "axios";

const API = {
  data: {
    async getCountry(obj) {
      const response = await axios.get(
        `/api/country/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getLanguage(obj) {
      const response = await axios.get(
        `/api/language/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getTime(obj) {
      const response = await axios.get(
        `/api/time/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getGroup(obj) {
      const response = await axios.get(
        `/api/group/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getDivision(obj) {
      const response = await axios.get(
        `/api/division/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getSubDivision(obj) {
      const response = await axios.get(
        `/api/subdivision/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getSubSubDivision(obj) {
      const response = await axios.get(
        `/api/subsubdivision/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getLabels(obj) {
      const response = await axios.get(
        `/api/labels/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getNodos(obj) {
      const response = await axios.get(
        `/api/nodos/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getDivisiones(obj) {
      const response = await axios.get(
        `/api/divisiones/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getBolsas(obj) {
      const response = await axios.get(
        `/api/bolsas/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getBolsasDeseables(obj) {
      const response = await axios.get(
        `/api/bolsasdeseable/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getBolsasRecursos(obj) {
      const response = await axios.get(
        `/api/bolsasrecurso/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getDespliegues(obj) {
      const response = await axios.get(
        `/api/despliegues/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getRoles(obj) {
      const response = await axios.get(
        `/api/roles/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getRolesUser(obj) {
      const response = await axios.get(
        `/api/rolesUser/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getAreas(obj) {
      const response = await axios.get(
        `/api/areas/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async getTiempo(obj) {
      const response = await axios.get(
        `/api/credito/tiempo/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
