import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
function ModalDelete(props) {
  let history = useHistory();
  const go = (obj) => {
    if (obj.type === "edit") {
      history.push(`/dashboard/update_division/${props.id}`);
    } else {
      const name = props.name.replaceAll(" ", "&");
      history.push(`/dashboard/jornadas/${name}(--)${props.idCompany}`);
    }
  };

  const reset = () => {
    props.setModal(false);
  };

  return (
    <>
      <Modal
        show={props.modal}
        onHide={() => reset()}
        onExit={() => reset()}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <FormattedMessage id="Delete" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h4>selecciona donde deseas ir</h4>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => go({ type: "edit" })}>
            Editar
          </Button>
          <Button variant="secondary" onClick={() => go({ type: "jornada" })}>
            Jornada
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalDelete;
