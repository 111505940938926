import {
  UPDATE_CARGO_INIT,
  UPDATE_CARGO_SUCCESS,
  UPDATE_CARGO_FAILURE,
  RESET_UPDATE_CARGO,
} from "./types";
import API from "./api";

export function resetUpdateCargo() {
  return {
    type: RESET_UPDATE_CARGO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function updateCargoSuccess(data) {
  return {
    type: UPDATE_CARGO_SUCCESS,
    payload: data,
  };
}

function updateCargoFailure(error) {
  return {
    type: UPDATE_CARGO_FAILURE,
    payload: error,
  };
}

function updateCargoInit() {
  return {
    type: UPDATE_CARGO_INIT,
  };
}

export function updateCargo(data) {
  return async (dispatch) => {
    dispatch(updateCargoInit());
    try {
      const resp = await API.data.updateCargo(data);
      return dispatch(updateCargoSuccess(resp));
    } catch (error) {
      return dispatch(updateCargoFailure(error));
    }
  };
}
