import React from "react";

/* Components */
import Title from "../../component/update_password/title";
import Form from "../../component/update_password/form";

const UpdateUsuarioPage = (props) => {
  return (
    <section>
      <Title />
      <Form
        empresa_id={props.location.state.items}
        id={props.match.params.id}
      />
    </section>
  );
};

export default UpdateUsuarioPage;
