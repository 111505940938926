import {
  GET_BOLSA_ATRIBUTO_INIT,
  GET_BOLSA_ATRIBUTO_SUCCESS,
  GET_BOLSA_ATRIBUTO_FAILURE,
  RESET_BOLSA_ATRIBUTO,
  UPDATE_BOLSA_ATRIBUTO_INIT,
  UPDATE_BOLSA_ATRIBUTO_SUCCESS,
  UPDATE_BOLSA_ATRIBUTO_FAILURE,
  RESET_UPDATE_BOLSA_ATRIBUTO,
} from "./types";
import API from "./api";

export function resetBolsaAtributo() {
  return {
    type: RESET_BOLSA_ATRIBUTO,
  };
}

function getBolsaAtributoSuccess(data) {
  return {
    type: GET_BOLSA_ATRIBUTO_SUCCESS,
    payload: data,
  };
}

function getBolsaAtributoFailure(error) {
  console.log(error);
  return {
    type: GET_BOLSA_ATRIBUTO_FAILURE,
    payload: error,
  };
}

function getBolsaAtributoInit(data) {
  return {
    type: GET_BOLSA_ATRIBUTO_INIT,
    payload: data.loading,
  };
}

export function getBolsaAtributo(data) {
  return async (dispatch) => {
    dispatch(getBolsaAtributoInit(data));
    try {
      const resp = await API.data.getBolsa(data);
      return dispatch(getBolsaAtributoSuccess(resp));
    } catch (error) {
      return dispatch(getBolsaAtributoFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateBolsaAtributo() {
  return {
    type: RESET_UPDATE_BOLSA_ATRIBUTO,
  };
}

function updateBolsaAtributoSuccess(data) {
  return {
    type: UPDATE_BOLSA_ATRIBUTO_SUCCESS,
    payload: data,
  };
}

function updateBolsaAtributoFailure(error) {
  return {
    type: UPDATE_BOLSA_ATRIBUTO_FAILURE,
    payload: error,
  };
}

function updateBolsaAtributoInit() {
  return {
    type: UPDATE_BOLSA_ATRIBUTO_INIT,
  };
}

export function updateBolsaAtributo(data) {
  return async (dispatch) => {
    dispatch(updateBolsaAtributoInit());
    try {
      const resp = await API.data.updateBolsa(data);
      return dispatch(updateBolsaAtributoSuccess(resp));
    } catch (error) {
      return dispatch(updateBolsaAtributoFailure(error));
    }
  };
}
