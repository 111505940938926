import initialState from "./initialState";
import {
  GET_DESEABLE_INIT,
  GET_DESEABLE_SUCCESS,
  GET_DESEABLE_FAILURE,
  RESET_DESEABLE,
  UPDATE_DESEABLE_INIT,
  UPDATE_DESEABLE_SUCCESS,
  UPDATE_DESEABLE_FAILURE,
  RESET_UPDATE_DESEABLE,
} from "../../actions/update_deseable/types";

export default function deseable(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_DESEABLE:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_DESEABLE_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_DESEABLE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_DESEABLE_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case RESET_UPDATE_DESEABLE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_DESEABLE_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_DESEABLE_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_DESEABLE_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };
    default:
      return state;
  }
}
