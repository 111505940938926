const initialState = {
  dataUpdate: {},
  loadingUpdate: false,
  statusUpdate: 0,
};
const UpdateGroup = (state = initialState, action) => {
  switch (action.type) {
    // UPDATE
    case "RESET_UPDATE_GROUPS":
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case "UPDATE_GROUPS_LOADING":
      return {
        ...state,
        loadingUpdate: true,
      };
    case "UPDATE_GROUPS_SUCESS":
      return {
        ...state,
        dataUpdate: action.payload,
        loadingUpdate: false,
        statusUpdate: action.status,
      };
    case "UPDATE_GROUPS_FAIL":
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };
    default:
      return state;
  }
};

export default UpdateGroup;
