import React from "react";
import Login from "../../component/login/login";
import Waves from "../../component/login/waves";
import "../../assets/style/login.scss";

const LoginPage = () => {
  return (
    <div className="containerLoginPage">
      <Login />
      <Waves />
    </div>
  );
};

export default LoginPage;
