import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

// Actions
import { UpdateGroup, ResetUpdateGroup } from "../../actions/group/api";
// Components
import ModalMessage from "../common/modalMessage";
/* Language */
import { FormattedMessage } from "react-intl";

const FormGroup = (props) => {
  const updateGroup = useSelector((state) => state.UpdateGroup);

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    nombre: props.item.nombre,
  });

  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(ResetUpdateGroup());
    };
  }, [dispatch]);

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      nombre: form.nombre,
      id: props.id,
    };

    dispatch(UpdateGroup(obj));
    setModalMessage(true);
  };
  const resetForm = () => {
    setForm({
      nombre: form.nombre,
    });
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos grupo</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Name" /><span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.nombre}
                onChange={handleChange}
                type="text"
                name="nombre"
              />
            </Form.Group>
          </div>
          <div className="containerFormButton">
            <div>
              <Button
                disabled={updateGroup.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {updateGroup.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {updateGroup.statusUpdate === 200 &&
        !updateGroup.dataUpdate.ejecucion.estado && (
          <ModalMessage
            message={updateGroup.dataUpdate.ejecucion.mensaje}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={updateGroup.loading}
          />
        )}
      {updateGroup.statusUpdate === 200 &&
        updateGroup.dataUpdate.ejecucion.estado && (
          <ModalMessage
            message={<FormattedMessage id="SuccessfullyUpdated" />}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={updateGroup.loadingUpdate}
          />
        )}
    </React.Fragment>
  );
};

export default FormGroup;
