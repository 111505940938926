import axios from "axios";

const API = {
  data: {
    async handleUserUploadValidate(item) {
      const fullPath = document.getElementById("recfile").files;
      let formData = new FormData();
      formData.append("recfile", fullPath[0]);
      formData.append("activo", item.activo);
      formData.append("formato_nombre", item.formato_nombre);
      formData.append("lenguaje_id", item.lenguaje_id);
      formData.append("empresa_id", item.empresa_id);
      formData.append("type", item.type);
      formData.append("md5", item.md5);

      const response = await axios({
        method: "post",
        url: "/api/usuarios/uploadValidate",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      return { data: response.data, status: response.status };
    },
  },
};
export default API;
