import { combineReducers } from "redux";

/* Llamamos a nuestro reducers */
import login from "./login";
import GetCompaniesReducer from "./home/getCompaniesReducer";
import addCompany from "./add_company";
import addCompanyAvatars from "./add_company_avatars";
import updateCompany from "./update_company";
import filters from "./filters";
import GetGroupsReducer from "./groups/getGroupsReducer";
import GetCargosReducer from "./cargos/getCargosReducer";
import addCargo from "./add_cargo";
import updateCargo from "./update_cargo";
import GetEdificacionesReducer from "./edificaciones/getEdificacionesReducer";
import addEdificacion from "./add_edificacion";
import updateEdificacion from "./update_edificacion";
import addEdificacionAvatars from "./add_edificacion_avatars";
import GetNodosReducer from "./nodos/getNodosReducer";
import addNodo from "./add_nodo";
import updateNodo from "./update_nodo";
import GetDivisionesReducer from "./division/getDivisionesReducer";
import addDivision from "./add_division";
import updateDivision from "./update_division";
import addDivisionAvatars from "./add_division_avatars";
import updateDivisionAtributos from "./update_division_atributos";
import updateDivisionDeseables from "./update_division_deseable";
import updateDivisionRecursos from "./update_division_recurso";
import GetBolsaAtributosReducer from "./bolsa_atributos/getBolsaAtributosReducer";
import addBolsaAtributo from "./add_bolsa_atributo";
import updateBolsaAtributo from "./update_bolsa_atributo";
import GetPreguntasReducer from "./preguntas/getPreguntasReducer";
import addPregunta from "./add_pregunta";
import updatePregunta from "./update_pregunta";
import GetAlternativasReducer from "./alternativas/getAlternativasReducer";
import addAlternativa from "./add_alternativa";
import updateAlternativa from "./update_alternativa";
import addAlternativaAvatars from "./add_alternativa_avatars";
import GetAtributosReducer from "./atributos/getAtributosReducer";
import updateAtributo from "./update_atributo";
import addAtributo from "./add_atributo";
import AddGroupReducer from "./groups/add_group/addGroupReducer";
import GetBolsaRecursosReducer from "./bolsa_recursos/getBolsaRecursosReducer";
import addBolsaRecurso from "./add_bolsa_recurso";
import updateBolsaRecurso from "./update_bolsa_recurso";
import GetRecursosReducer from "./recursos/getRecursosReducers";
import GetAccesosRecursosReducers from "./accesos_recursos/getAccesosRecursosReducers";
import updateRecurso from "./update_recurso";
import addRecurso from "./add_recurso";
import GetBolsaDeseablesReducer from "./bolsa_deseables/getBolsaDeseablesReducer";
import addBolsaDeseable from "./add_bolsa_deseable";
import updateBolsaDeseable from "./update_bolsa_deseable";
import GetDeseablesReducer from "./deseables/getDeseablesReducers";
import addDeseable from "./add_deseable";
import updateDeseable from "./update_deseable";
import GetUsuariosReducer from "./usuarios/getUsuariosReducers";
import addUsuario from "./add_usuario";
import addUserExcel from "./add_user_excel";
import loadUsuario from "./load_usuario";
import updateUsuario from "./update_usuario";
import updatePassword from "./update_password";
import GetJornadasReducer from "./jornadas/getJornadasReducer";
import addJornada from "./add_jornada";
import deleteJornada from "./delete_jornada";
import GetHorariosReducer from "./horarios";
import addHorario from "./add_horario";
import companiesPoliticas from "./companies_politicas";
import accesos from "./accesos";
import UpdateGroup from "./groups/update_group/updateGroupReducer";
import GetGranularityReducer from "./granularidad/getGranularityReducer";
import UpdateGranularityReducer from "./granularidad/update_granularidad/updateGranularityReducer";
import reservas from "./reservas";
import code from "./code";
import areas from "./areas";
import addArea from "./add_area";
import updateArea from "./update_area/";
import credito from "./credito";
import addCredito from "./add_credito";
import updateCredito from "./update_credito/";
import ventana from "./ventana";
import addVentana from "./add_ventana";
import updateVentana from "./update_ventana";
import ventanaReserva from "./ventana_reserva";

const rootReducer = combineReducers({
  filters,
  login,
  GetCompanies: GetCompaniesReducer,
  addCompany,
  addCompanyAvatars,
  updateCompany,
  GetGroups: GetGroupsReducer,
  GetCargos: GetCargosReducer,
  addCargo,
  updateCargo,
  GetEdificaciones: GetEdificacionesReducer,
  addEdificacion,
  updateEdificacion,
  addEdificacionAvatars,
  GetNodos: GetNodosReducer,
  addNodo,
  updateNodo,
  GetDivisiones: GetDivisionesReducer,
  addDivision,
  updateDivision,
  addDivisionAvatars,
  updateDivisionAtributos,
  updateDivisionDeseables,
  updateDivisionRecursos,
  GetBolsaAtributos: GetBolsaAtributosReducer,
  addBolsaAtributo,
  updateBolsaAtributo,
  GetPreguntas: GetPreguntasReducer,
  addPregunta,
  updatePregunta,
  GetAlternativas: GetAlternativasReducer,
  addAlternativa,
  updateAlternativa,
  addAlternativaAvatars,
  GetAtributos: GetAtributosReducer,
  updateAtributo,
  addAtributo,
  AddGroup: AddGroupReducer,
  GetBolsaRecursos: GetBolsaRecursosReducer,
  addBolsaRecurso,
  updateBolsaRecurso,
  GetRecursos: GetRecursosReducer,
  addRecurso,
  updateRecurso,
  GetBolsaDeseables: GetBolsaDeseablesReducer,
  addBolsaDeseable,
  updateBolsaDeseable,
  GetDeseables: GetDeseablesReducer,
  addDeseable,
  updateDeseable,
  GetUsuarios: GetUsuariosReducer,
  addUsuario,
  loadUsuario,
  updateUsuario,
  updatePassword,
  GetJornadas: GetJornadasReducer,
  addJornada,
  deleteJornada,
  GetHorarios: GetHorariosReducer,
  addHorario,
  GetCompaniesPoliticas: companiesPoliticas,
  accesos,
  UpdateGroup,
  GetGranularity: GetGranularityReducer,
  UpdateGranularity: UpdateGranularityReducer,
  GetAccesosRecursos: GetAccesosRecursosReducers,
  reservas,
  code,
  GetAreas: areas,
  addArea,
  updateArea,
  addUserExcel,
  GetCreditos: credito,
  GetVentanas: ventana,
  addCredito,
  updateCredito,
  addVentana,
  updateVentana,
  GetVentanasReserva: ventanaReserva,
});

export default rootReducer;
