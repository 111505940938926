import axios from "axios";

const API = {
  data: {
    async getAccesos(obj) {
      axios.defaults.timeout = 60 * 20 * 1000;
      const response = await axios({
        method: "get",
        url: `/api/accesos/${encodeURIComponent(JSON.stringify(obj))}`,
        timeout: 60 * 20 * 1000, // Let's say you want to wait at least 4 mins
        data: obj,
      });

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async addAcceso(obj) {
      axios.defaults.timeout = 60 * 20 * 1000;
      const response = await axios({
        method: "post",
        url: "/api/accesos",
        timeout: 60 * 20 * 1000, // Let's say you want to wait at least 4 mins
        data: obj,
      });

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
