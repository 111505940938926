import React from "react";

/* Components */
import Title from "../../component/update_atributo/title";
import Form from "../../component/update_atributo/form";

const UpdateAtributoPage = (props) => {
  return (
    <section>
      <Title />
      <Form id={props.match.params.id} />
    </section>
  );
};

export default UpdateAtributoPage;
