import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import FilterBolsasArr from "./filterBolsasArr";

function ModalBolsasd(props) {
  const close = () => {
    //props.setModal(false);
    props.func();
  };

  const reset = () => {
    props.reset();
    props.setModal(false);
  };

  return (
    <>
      <Modal
        show={props.modal}
        onHide={() => reset()}
        onExit={() => reset()}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Desasignar bolsa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.state.statusAsignarBolsas === 0 && (
            <div>
              {props.state.loadingAsignarBolsas ? (
                <h4>
                  <FormattedMessage id="Loading" />
                </h4>
              ) : (
                <div>
                  <FilterBolsasArr
                    data={props.bolsa.arr}
                    name="asignarBolsa"
                    form={props.form}
                    handleChange={props.handleChangeAsignar}
                  />
                </div>
              )}
            </div>
          )}
          {props.state.statusAsignarBolsas === 200 && (
            <div>
              {props.state.dataAsignarBolsas.ejecucion.estado ? (
                <h4>desasignada con éxito</h4>
              ) : (
                <p>{props.state.dataAsignarBolsas.ejecucion.mensaje}</p>
              )}
            </div>
          )}
        </Modal.Body>
        {props.state.statusAsignarBolsas === 0 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button variant="secondary" onClick={close}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
        {props.state.statusAsignarBolsas === 200 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalBolsasd;
