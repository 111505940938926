import React from "react";

const Title = () => {
  return (
    <div className="containerEmpresa">
      <p className="titleEmpresas">Accesos</p>
      <div className="lineGray"></div>
    </div>
  );
};

export default Title;
