const initialState = {
  dataJornadaHoliday: [],
  loadingJornadaHoliday: true,
  statusJornadaHoliday: 0,
  dataNewJornada: [],
  loadingNewJornada: false,
  statusNewJornada: 0,
};

export default initialState;
