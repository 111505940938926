import {
  POST_LOGIN_INIT,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  RESET_LOGIN,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetLogin() {
  return {
    type: RESET_LOGIN,
  };
}

/*  Llamada login Async manejamos 3 estados */

function postLoginSuccess(data) {
  return {
    type: POST_LOGIN_SUCCESS,
    payload: data,
  };
}

function postLoginFailure(error) {
  return {
    type: POST_LOGIN_FAILURE,
    payload: error,
  };
}

function postLoginInit() {
  return {
    type: POST_LOGIN_INIT,
  };
}

export function postLogin(data) {
  return async (dispatch) => {
    dispatch(postLoginInit());
    try {
      const resp = await API.data.postLogin(data);
      return dispatch(postLoginSuccess(resp));
    } catch (error) {
      return dispatch(postLoginFailure(error));
    }
  };
}
