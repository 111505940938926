import React from "react";

/* Components */
import Title from "../../component/update_bolsa_deseable/title";
import Form from "../../component/update_bolsa_deseable/form";

const UpdateBolsaDeseablePage = (props) => {
  return (
    <section>
      <Title />
      <Form id={props.match.params.id} />
    </section>
  );
};

export default UpdateBolsaDeseablePage;
