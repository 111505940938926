import React, { useEffect } from "react";

/* Components */
import Title from "../../component/update_cargo/title";
import Form from "../../component/update_cargo/form";

const UpdateCompanyPage = (props) => {
  useEffect(() => {
    const callsAPI = async () => {
      if (!props.location.state) {
        props.history.push("/");
      }
    };
    callsAPI();
  }, [props.history, props.location.state]);
  return (
    <section>
      <Title />
      {props.location.state && (
        <Form item={props.location.state.items} id={props.match.params.id} />
      )}
    </section>
  );
};

export default UpdateCompanyPage;
