import {
  ADD_COMPANY_INIT,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAILURE,
  RESET_ADD_COMPANY,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddCompany() {
  return {
    type: RESET_ADD_COMPANY,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addCompanySuccess(data) {
  return {
    type: ADD_COMPANY_SUCCESS,
    payload: data,
  };
}

function addCompanyFailure(error) {
  return {
    type: ADD_COMPANY_FAILURE,
    payload: error,
  };
}

function addCompanyInit() {
  return {
    type: ADD_COMPANY_INIT,
  };
}

export function addCompany(data) {
  return async (dispatch) => {
    dispatch(addCompanyInit());
    try {
      const resp = await API.data.addCompany(data);
      return dispatch(addCompanySuccess(resp));
    } catch (error) {
      return dispatch(addCompanyFailure(error));
    }
  };
}
