import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";

// Actions
import {
  DeleteVentana,
  ResetDeleteVentana,
  GetVentanas,
  resetVentanas,
} from "../../actions/ventana/";
// Components
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";
import FilterCompany from "../common/filterCompany";
import { Link } from "react-router-dom";

const Ventanas = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: "",
  });

  // State
  const getVentanas = useSelector((state) => state.GetVentanas);

  useEffect(() => {
    const FetchData = () => {};
    FetchData();
  }, [dispatch]);

  // AQUI RESET K NO SIRVE PA NA
  useEffect(() => {
    return () => {
      dispatch(resetVentanas());
    };
  }, [dispatch]);

  // state para modal delete uwu
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);
  // funcion para modalMatar
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };
  // funcion para matar
  const functDelete = () => {
    dispatch(DeleteVentana({ ventana_id: idDelete }));
  };
  // reset de la funcion matar
  const resetDelete = () => {
    dispatch(ResetDeleteVentana());
    if (getVentanas.statusDelete === 200) {
      if (getVentanas.dataDelete.ejecucion.estado) {
        dispatch(GetVentanas({ empresa_id: parseInt(form.id, 10) }));
      }
    }
  };

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    if (event.target.value !== "") {
      dispatch(GetVentanas({ empresa_id: parseInt(event.target.value, 10) }));
    } else {
      dispatch(resetVentanas());
    }
  };

  const showVentanas = () => {
    if (getVentanas.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getVentanas.data)) {
      return (
        <div className="containerAllEmpresas">
          {getVentanas.data.map((group, key) => {
            return (
              <div className="eachEmpresa" key={key}>
                <div className="leftSideEachEmpresa">
                  <p className="containerTable">{group.id}</p>
                  <p className="containerTable">{group.nombre}</p>
                  <p className="containerTable">{group.creditos_usados}</p>
                  <p className="containerTable">
                    {group.usuarios_con_topes ? "Si" : "No"}
                  </p>
                  <p className="containerTable">
                    {moment(group.inicio.substr(0, 16)).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                  </p>
                  <p className="containerTable">
                    {moment(group.termino.substr(0, 16)).format(
                      "DD-MM-YYYY HH:mm"
                    )}
                  </p>
                  <Link
                    className="containerTable"
                    to={{
                      pathname: `/dashboard/update_ventana/${group.id}-${form.id}`,
                      state: {
                        items: group,
                      },
                    }}
                  >
                    <div className="containerSubTableGroups">
                      <i className="far fa-edit"></i>
                    </div>
                  </Link>
                  <div className="containerTable">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li>
                            <Link
                              className="containerTableMore"
                              to={{
                                pathname: `/dashboard/ventana_reserva/${group.id}`,
                                state: {
                                  items: form.id,
                                },
                              }}
                            >
                              Reserva ventanas
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="containerTable"
                              to={{
                                pathname: `/dashboard/update_ventana_usuario/${group.id}-${form.id}`,
                                state: {
                                  items: group,
                                },
                              }}
                            >
                              Ventana usuario tope
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  className="rightSideEachEmpresa"
                  onClick={() => {
                    handleModal({ id: group.id });
                  }}
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getVentanas.errorMsg !== "") {
      return <ModalTimeOut show={true} />;
    }
  };

  return (
    <React.Fragment>
      <div className="containerEmpresa">
        <ModalDelete
          func={() => {
            functDelete();
          }}
          state={getVentanas}
          modal={modal}
          reset={resetDelete}
          setModal={setModal}
        />

        <p className="titleEmpresas">Ventanas</p>
        <FilterCompany name="id" form={form} handleChange={handleChange} />

        <section className="containerMasivo">
          {form.id !== "" && (
            <span className="cargadorMasivo">
              <Link
                className="containerTable"
                to={{
                  pathname: `/dashboard/update_ventana_usuario/${0}-${form.id}`,
                  state: {
                    items: null,
                  },
                }}
              >
                <i className="fas fa-user"></i> Ventana usuario con tope
              </Link>
            </span>
          )}
        </section>

        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideEachEmpresa">
            <p className="containerTable">ID</p>
            <p className="containerTable">Nombre</p>
            <p className="containerTable">Creditos usados</p>
            <p className="containerTable">Tope usuario</p>
            <p className="containerTable">Inicio</p>
            <p className="containerTable">Termino</p>
            <p className="containerTable">Editar</p>
            <p className="containerTable">Más</p>
          </div>
          <div className="rightSideEachEmpresa">
            <p>Eliminar</p>
          </div>
        </div>
        {showVentanas()}
      </div>
    </React.Fragment>
  );
};

export default Ventanas;
