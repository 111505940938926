import {
  ADD_AVATARS_EDIFICACION_INIT,
  ADD_AVATARS_EDIFICACION_SUCCESS,
  ADD_AVATARS_EDIFICACION_FAILURE,
  RESET_ADD_AVATARS_EDIFICACION,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetAddAvatarEdificacion() {
  return {
    type: RESET_ADD_AVATARS_EDIFICACION,
  };
}

/* Get AddAvatarEdificacion (Async) */

function addAvatarEdificacionSuccess(data) {
  return {
    type: ADD_AVATARS_EDIFICACION_SUCCESS,
    payload: data,
  };
}

function addAvatarEdificacionFailure(error) {
  console.log(error);
  return {
    type: ADD_AVATARS_EDIFICACION_FAILURE,
    payload: error,
  };
}

function addAvatarEdificacionInit() {
  return {
    type: ADD_AVATARS_EDIFICACION_INIT,
  };
}

export function addAvatarEdificacion(data) {
  return async (dispatch) => {
    dispatch(addAvatarEdificacionInit());
    try {
      const resp = await API.data.addAvatars(data);
      return dispatch(addAvatarEdificacionSuccess(resp));
    } catch (error) {
      return dispatch(addAvatarEdificacionFailure(error));
    }
  };
}
