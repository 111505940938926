import axios from "axios";

/* Get Code*/
export const GetCode = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_CODE_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/code/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_CODE_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "GET_CODE_FAIL",
    });
    console.log("Error en getCodeAction: veni a ver el desmadre", err);
  }
};

/* Reset Code */
export const resetCode = () => {
  return {
    type: "RESET_CODE",
  };
};
