// Edificacion ocupa el style de groups
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import add from "../../assets/img/add.svg";

/* Actions */
import {
  GetEdificaciones,
  resetEdificaciones,
  DeleteEdificacion,
  resetDeleteEdificacion,
  AsignarEdificacion,
  resetAsignarEdificacion,
  AsignarBolsas,
  resetAsignarBolsas,
  resetQr,
  ObtenerQr,
} from "../../actions/edificaciones/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";
import FilterCompany from "../common/filterCompany";
import ModalAsignar from "../common/modalAsignar";
import ModalDesasignar from "../common/modalDesasignar";
import ModalBolsas from "../common/modalBolsas";
import ModalBolsasd from "../common/modalBolsasd";
import ModalQr from "../common/modalQr";

const Edificaciones = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: "",
    asignar: "",
    asignarBolsa: "",
  });
  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);
  const [modala, setModala] = useState(false);
  const [modald, setModald] = useState(false);
  const [modalba, setModalba] = useState(false);
  const [modalbd, setModalbd] = useState(false);
  const [modalqr, setModalqr] = useState(false);
  const [bolsa, setBolsa] = useState({ arr: [], type: "" });

  /* States */
  const getEdificaciones = useSelector((state) => state.GetEdificaciones);

  useEffect(() => {
    const FetchData = () => {};
    FetchData();
  }, [dispatch]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetEdificaciones());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };
  /* Funcion open modal */
  const handleModala = (obj) => {
    setModala(true);
    setIdDelete(obj.id);
  };

  const handleModalqr = (obj) => {
    setModalqr(true);
    setIdDelete(obj.id);
  };

  /* Funcion open modal */
  const handleModald = (obj) => {
    setModald(true);
    setIdDelete(obj.id);
  };

  /* Funcion open modal */
  const handleModalba = (obj) => {
    setModalba(true);
    setIdDelete(obj.id);
    setBolsa({ arr: obj.arr, type: obj.type });
  };

  /* Funcion open modal */
  const handleModalbd = (obj) => {
    setModalbd(true);
    setIdDelete(obj.id);
    setBolsa({ arr: obj.arr, type: obj.type });
  };

  /* Para cambiar los use states */
  const handleChangeAsignar = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    if (event.target.value !== "") {
      dispatch(
        GetEdificaciones({ empresa_id: parseInt(event.target.value, 10) })
      );
    } else {
      dispatch(resetEdificaciones());
    }
  };

  /* Delete entidad */
  const submitDelete = () => {
    dispatch(
      DeleteEdificacion({ edificacion_id: idDelete, empresa_id: form.id })
    );
  };

  /* Delete entidad */
  const submitQr = () => {
    dispatch(ObtenerQr({ edificacion_id: idDelete }));
  };

  /* Reset el estado de delete*/
  const submitResetDelete = () => {
    dispatch(resetDeleteEdificacion());
    if (getEdificaciones.statusDelete === 200) {
      if (getEdificaciones.dataDelete.ejecucion.estado) {
        dispatch(GetEdificaciones({ empresa_id: form.id }));
      }
    }
  };

  /* Reset el estado de qr*/
  const submitResetQr = () => {
    dispatch(resetQr());
    if (getEdificaciones.statusQr === 200) {
      if (getEdificaciones.dataQr.ejecucion.estado) {
        dispatch(GetEdificaciones({ empresa_id: form.id }));
      }
    }
  };

  /* asignar entidad */
  const submitAsignar = (type) => {
    if (type === "asignar") {
      dispatch(
        AsignarEdificacion({
          type,
          edificacion_id: idDelete,
          empresa_id: form.asignar,
        })
      );
    } else {
      dispatch(
        AsignarEdificacion({
          type,
          edificacion_id: idDelete,
          empresa_id: form.id,
        })
      );
    }
  };

  /* Reset el estado de delete*/
  const submitResetAsignar = (type) => {
    dispatch(resetAsignarEdificacion());
    if (getEdificaciones.statusAsignar === 200) {
      if (getEdificaciones.dataAsignar.ejecucion.estado) {
        dispatch(GetEdificaciones({ empresa_id: form.id }));
      }
    }
  };

  /* asignar entidad */
  const submitAsignarBolsa = (type, bolsa) => {
    dispatch(
      AsignarBolsas({
        type,
        bolsa,
        edificacion_id: idDelete,
        bolsa_atributo_id: parseInt(form.asignarBolsa),
        bolsa_deseable_id: parseInt(form.asignarBolsa),
        bolsa_recurso_id: parseInt(form.asignarBolsa),
      })
    );
  };

  /* Reset el estado de delete*/
  const submitResetAsignarBolsa = (type) => {
    dispatch(resetAsignarBolsas());
    setForm((prev) => ({ ...prev, asignarBolsa: "" }));
    if (getEdificaciones.statusAsignarBolsas === 200) {
      if (getEdificaciones.dataAsignarBolsas.ejecucion.estado) {
        dispatch(GetEdificaciones({ empresa_id: form.id }));
      }
    }
  };

  const showEdificaciones = () => {
    if (getEdificaciones.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getEdificaciones.data)) {
      return (
        <div className="containerAllGroups">
          {getEdificaciones.data.map((item, key) => {
            return (
              <div className="eachGroups" key={key}>
                <div className="leftSideEachGroups">
                  <p className="containerTableID">{item.id}</p>
                  <p className="containerSubTableGroupsName">{item.nombre}</p>
                  <Link
                    className="containerSubTableGroups"
                    to={{
                      pathname: `/dashboard/update_edificacion/${item.id}`,
                      state: {
                        items: item,
                      },
                    }}
                  >
                    <div className="containerSubTableGroups">
                      <i className="far fa-edit"></i>
                    </div>
                  </Link>
                  <div className="containerSubTableGroups">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li>
                            <Link
                              to={`/dashboard/add_edificacion_avatars/${item.id}`}
                            >
                              Agregar Imagen
                            </Link>
                          </li>
                          <li>
                            <Link to={`/dashboard/nodos/${item.id}`}>
                              Nodos
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={{
                                pathname: `/dashboard/divisiones/${item.id}-${form.id}`,
                                state: {
                                  arrAtri: item.bolsa_atributos,
                                  arrDes: item.bolsa_deseables,
                                  arrRec: item.bolsa_recursos,
                                },
                              }}
                            >
                              Edi divisiones
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              handleModala({ id: item.id });
                            }}
                          >
                            Asignar a empresa
                          </li>
                          <li
                            onClick={() => {
                              handleModald({ id: item.id });
                            }}
                          >
                            Desasignar a empresa
                          </li>

                          <li
                            onClick={() => {
                              handleModalba({
                                id: item.id,
                                type: "atributos",
                                arr: item.bolsa_atributos,
                              });
                            }}
                          >
                            Asignar bolsa de atributos
                          </li>
                          <li
                            onClick={() => {
                              handleModalbd({
                                id: item.id,
                                type: "atributos",
                                arr: item.bolsa_atributos,
                              });
                            }}
                          >
                            Desasignar bolsa de atributos
                          </li>

                          <li
                            onClick={() => {
                              handleModalba({
                                id: item.id,
                                type: "deseables",
                                arr: item.bolsa_deseables,
                              });
                            }}
                          >
                            Asignar bolsa de deseables
                          </li>
                          <li
                            onClick={() => {
                              handleModalbd({
                                id: item.id,
                                type: "deseables",
                                arr: item.bolsa_deseables,
                              });
                            }}
                          >
                            Desasignar bolsa de deseables
                          </li>

                          <li
                            onClick={() => {
                              handleModalba({
                                id: item.id,
                                type: "recursos",
                                arr: item.bolsa_recursos,
                              });
                            }}
                          >
                            Asignar bolsa de recursos
                          </li>
                          <li
                            onClick={() => {
                              handleModalbd({
                                id: item.id,
                                type: "recursos",
                                arr: item.bolsa_recursos,
                              });
                            }}
                          >
                            Desasignar bolsa de recursos
                          </li>
                          <li
                            onClick={() => {
                              handleModalqr({ id: item.id });
                            }}
                          >
                            Obtener QR
                          </li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleModal({ id: item.id });
                  }}
                  className="rightSideEachGroups"
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getEdificaciones.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };

  return (
    <React.Fragment>
      <div className="containerGroups">
        <ModalDelete
          func={() => {
            submitDelete();
          }}
          state={getEdificaciones}
          modal={modal}
          reset={submitResetDelete}
          setModal={setModal}
        />
        <ModalQr
          func={() => {
            submitQr();
          }}
          state={getEdificaciones}
          modal={modalqr}
          reset={submitResetQr}
          setModal={setModalqr}
        />
        <ModalAsignar
          func={() => {
            submitAsignar("asignar");
          }}
          handleChangeAsignar={handleChangeAsignar}
          form={form}
          state={getEdificaciones}
          modal={modala}
          reset={submitResetAsignar}
          setModal={setModala}
        />
        <ModalDesasignar
          func={() => {
            submitAsignar("desasignar");
          }}
          handleChangeAsignar={handleChangeAsignar}
          form={form}
          state={getEdificaciones}
          modal={modald}
          reset={submitResetAsignar}
          setModal={setModald}
          bolsa={bolsa}
        />
        <ModalBolsas
          func={() => {
            submitAsignarBolsa("asignar", bolsa.type);
          }}
          handleChangeAsignar={handleChangeAsignar}
          form={form}
          state={getEdificaciones}
          modal={modalba}
          reset={submitResetAsignarBolsa}
          setModal={setModalba}
          bolsa={bolsa}
        />
        <ModalBolsasd
          func={() => {
            submitAsignarBolsa("desasignar", bolsa.type);
          }}
          handleChangeAsignar={handleChangeAsignar}
          form={form}
          state={getEdificaciones}
          modal={modalbd}
          reset={submitResetAsignarBolsa}
          setModal={setModalbd}
          bolsa={bolsa}
        />
        <p className="titleGroups">Edificaciones</p>
        <FilterCompany name="id" form={form} handleChange={handleChange} />
        <div className="lineGray"></div>
        <div className="containerTableGroups">
          <div className="leftSideTableGroups">
            <p className="titleLeftSideTableGroupsID">ID</p>
            <p className="titleLeftSideTableGroups">Nombre</p>
            <p className="titleLeftSideTableGroups">Editar</p>
            <p className="titleLeftSideTableGroups">Más</p>
          </div>
          <div className="rightSideTableGroups">
            <p>Eliminar</p>
          </div>
        </div>
        {showEdificaciones()}
      </div>
      <Link to={`/dashboard/add_edificacion/${form.id}`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Edificaciones;
