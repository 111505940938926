import axios from "axios";

// GET
export const GetGranularity = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_GRANULARITY_LOADING",
    });
    const response = await axios.get(
      `/api/granularidad/${encodeURIComponent(JSON.stringify(obj))}`
    );
    let data = await response.data;
    dispatch({
      type: "GET_GRANULARITY_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_GRANULARITY_FAIL",
    });
    console.log("error get granularidad actions", err);
  }
};
export const ResetGranularity = () => {
  return {
    type: "RESET_GET_GRANULARITY",
  };
};

// UPDATE
export const UpdateGranularity = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_GRANULARITY_LOADING",
    });
    const response = await axios.put(`/api/granularidad/`, obj);
    const { status } = response;
    let data = await response.data;

    // CONSOLE LOG QUE DICE LO DE EJECUCION

    dispatch({
      type: "UPDATE_GRANULARITY_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "UPDATE_GRANULARITY_FAIL",
    });
    console.log("error en update granularity actions", err);
  }
};

export const ResetUpdateGranularity = () => {
  return {
    type: "RESET_UPDATE_GRANULARITY",
  };
};
