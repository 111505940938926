import axios from "axios";

/* Get DevisionesReservas*/
export const GetDevisionesReservas = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_DIVISION_RESERVAS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/divisionreserva/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_DIVISION_RESERVAS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_DIVISION_RESERVAS_FAIL",
    });
    console.log(
      "Error en getDevisionesReservas Action: veni a ver el desmadre",
      err
    );
  }
};

/* Reset DevisionesReservas */
export const resetDevisionesReservas = () => {
  return {
    type: "RESET_DIVISION_RESERVAS",
  };
};

export const DeleteReserva = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_RESERVA_LOADING",
  });
  try {
    const response = await axios.post(`/api/divisionreserva`, obj);
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_RESERVA_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_RESERVA_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteReserva = () => {
  return {
    type: "RESET_DELETE_RESERVA",
  };
};
