import React from "react";
//import { FormattedMessage } from "react-intl";

/* Components */
import Title from "../../component/add_alternativa/title";
import Form from "../../component/add_alternativa/form";

const AddAlternativaPage = (props) => {
  const div = props.match.params.id.split("-");
  return (
    <section>
      <Title />
      <Form id={div[0]} idBolsa={div[1]} />
    </section>
  );
};

export default AddAlternativaPage;
