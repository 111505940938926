import {
  GET_EDIFICACION_INIT,
  GET_EDIFICACION_SUCCESS,
  GET_EDIFICACION_FAILURE,
  RESET_EDIFICACION,
  UPDATE_EDIFICACION_INIT,
  UPDATE_EDIFICACION_SUCCESS,
  UPDATE_EDIFICACION_FAILURE,
  RESET_UPDATE_EDIFICACION,
} from "./types";
import API from "./api";

export function resetEdificacion() {
  return {
    type: RESET_EDIFICACION,
  };
}

function getEdificacionSuccess(data) {
  return {
    type: GET_EDIFICACION_SUCCESS,
    payload: data,
  };
}

function getEdificacionFailure(error) {
  console.log(error);
  return {
    type: GET_EDIFICACION_FAILURE,
    payload: error,
  };
}

function getEdificacionInit(data) {
  return {
    type: GET_EDIFICACION_INIT,
    payload: data.loading,
  };
}

export function getEdificacion(data) {
  return async (dispatch) => {
    dispatch(getEdificacionInit(data));
    try {
      const resp = await API.data.getEdificacion(data);
      return dispatch(getEdificacionSuccess(resp));
    } catch (error) {
      return dispatch(getEdificacionFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateEdificacion() {
  return {
    type: RESET_UPDATE_EDIFICACION,
  };
}

function updateEdificacionSuccess(data) {
  return {
    type: UPDATE_EDIFICACION_SUCCESS,
    payload: data,
  };
}

function updateEdificacionFailure(error) {
  return {
    type: UPDATE_EDIFICACION_FAILURE,
    payload: error,
  };
}

function updateEdificacionInit() {
  return {
    type: UPDATE_EDIFICACION_INIT,
  };
}

export function updateEdificacion(data) {
  return async (dispatch) => {
    dispatch(updateEdificacionInit());
    try {
      const resp = await API.data.updateEdificacion(data);
      return dispatch(updateEdificacionSuccess(resp));
    } catch (error) {
      return dispatch(updateEdificacionFailure(error));
    }
  };
}
