import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// actios
import { ResetAddGroup, AddGroup } from "../../actions/group/api";
// component
import ModalMessage from "../common/modalMessage";
// lenguaje
import { FormattedMessage } from "react-intl";

const FormAddGroup = (props) => {
  // states
  const addGroup = useSelector((state) => state.AddGroup);

  const dispatch = useDispatch();
  const [form, setForm] = useState({
    nombre: "",
  });

  const [modalMessage, setModalMessage] = useState(false);
  useEffect(() => {
    return () => {
      dispatch(ResetAddGroup());
    };
  }, [dispatch]);

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      // console.log("me quero matar")
      nombre: form.nombre,
    };
    console.log(obj);
    dispatch(AddGroup(obj));
    setModalMessage(true);
  };
  const resetForm = () => {
    setForm({
      nombre: "",
    });
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos grupo</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Name" />
                <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.nombre}
                onChange={handleChange}
                type="text"
                name="nombre"
              />
            </Form.Group>
          </div>
          <div className="containerFormButton">
            <div>
              <Button
                disabled={addGroup.loading}
                type="submit"
                variant="warning"
              >
                {addGroup.loading ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Add" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {addGroup.status === 200 && !addGroup.data.ejecucion.estado && (
        <ModalMessage
          message={addGroup.data.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={addGroup.loading}
        />
      )}
      {addGroup.status === 200 && addGroup.data.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyAdded" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={addGroup.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormAddGroup;
