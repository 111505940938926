import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useDispatch } from "react-redux";

/* Componentes  comunes*/
import Menu from "../../component/common/menu";
import TopBar from "../../component/common/topBar";

/* Componentes */
import Home from "../home/homePage";
import AddCompany from "../add_company/addCompanyPage";
import UpdateCompany from "../update_company/updateCompanyPage";
import GroupPage from "../home/groupPage";
import UpdateGroup from "../../component/update_group/updateGroup";
import AddCompanyAvatars from "../add_company_avatars/addCompanyAvatarsPage";
import Cargos from "../../component/home/cargos";
import AddCargo from "../add_cargo/addCargoPage";
import UpdateCargo from "../update_cargo/updateCargoPage";
import Edificaciones from "../../component/home/edificaciones";
import AddEdificacion from "../add_edificacion/addEdificacionPage";
import UpdateEdificacion from "../update_edificacion/updateEdificacionPage";
import AddEdificacionAvatars from "../add_edificacion_avatars/addEdificacionAvatarsPage";
import AddNodo from "../add_nodo/addNodoPage";
import Nodos from "../../component/home/nodos";
import UpdateNodo from "../update_nodo/updateNodoPage";
import Divisiones from "../../component/home/divisiones";
import AddHorario from "../add_horario";
import AddGroup from "../add_group/addGroupPage";
import AddDivision from "../add_division/addDivisionPage";
import UpdateDivision from "../update_division/updateDivisionPage";
import AddDivisionAvatars from "../add_division_avatars/addDivisionAvatarsPage";
import UpdateDivisionAtributos from "../update_division_atributos/updateDivisionAtributosPage";
import UpdateDivisionDeseables from "../update_division_deseable/updateDivisionDeseablesPage";
import UpdateDivisionRecursos from "../update_division_recurso/updateDivisionRecursoPage";
import BolsaAtributos from "../../component/home/bolsaAtributos";
import AddBolsaAtributo from "../add_bolsa_atributo/addBolsaAtributoPage";
import UpdateBolsaAtributo from "../update_bolsa_atributo/updateBolsaAtributoPage";
import Preguntas from "../../component/home/preguntas";
import AddPregunta from "../add_pregunta/addPreguntaPage";
import UpdatePregunta from "../update_pregunta/updatePreguntaPage";
import Alternativas from "../../component/home/alternativas";
import AddAlternativa from "../add_alternativa/addAlternativaPage";
import UpdateAlternativa from "../update_alternativa/updateAlternativaPage";
import AddAlternativaAvatars from "../add_alternativa_avatars/addAlternativaAvatarsPage";
import Atributos from "../../component/home/atributos";
import AddAtributo from "../../container/add_atributo/addAtributoPage";
import UpdateAtributo from "../../container/update_atributo/updateAtributoPage";
import BolsaRecursos from "../../component/home/bolsaRecursos";
import AddBolsaRecurso from "../add_bolsa_recurso/addBolsaRecursoPage";
import UpdateBolsaRecurso from "../update_bolsa_recurso/updateBolsaRecursoPage";
import Recursos from "../../component/home/recursos";
import AccesosRecursos from "../../component/home/accesosRecursos";
import AddRecurso from "../add_recurso/addRecursoPage";
import UpdateRecurso from "../update_recurso/updateRecursoPage";
import BolsaDeseables from "../../component/home/bolsaDeseables";
import AddBolsaDeseable from "../add_bolsa_deseable/addBolsaDeseablePage";
import UpdateBolsaDeseable from "../update_bolsa_deseable/updateBolsaDeseablePage";
import Deseables from "../../component/home/deseables";
import AddDeseable from "../add_deseable/addDeseablePage";
import UpdateDeseable from "../update_deseable/updateDeseablePage";
import Usuarios from "../../component/home/usuarios";
import AddUsuario from "../add_usuario";
import LoadUsuario from "../load_usuario";
import UpdateUsuario from "../update_usuario";
import UpdatePassword from "../update_password";
import Jornadas from "../../component/home/jornadas";
import AddJornada from "../add_jornada";
import DeleteJornada from "../delete_jornada";
import Horarios from "../../component/home/horarios";
import CompaniesPoliticas from "../../component/home/companiesPoliticas";
import Accesos from "../../container/accesos/index";
import Reservas from "../../component/home/reservas";
import Areas from "../../component/home/areas";
import AddArea from "../add_area/";
import UpdateArea from "../update_area";
import AddUserExcel from "../add_user_excel";
import Ventana from "../../component/home/ventana";
import AddVentana from "../add_ventana";
import UpdateVentana from "../update_ventana";
import UpdateVentanaUsuario from "../update_ventana_usuario";
import Credito from "../../component/home/credito";
import AddCredito from "../add_credito";
import UpdateCredito from "../update_credito";
import VentanaReserva from "../../component/home/ventanaReserva";

import Granularity from "../../component/home/granularity";
import UpdateGranularity from "../../component/update_granularity/updateGranularity";
/* Actions */
import { resetLogin } from "../../actions/login";

/* Translate */
import { IntlProvider } from "react-intl";

import messages_es from "../../translate/es";
import messages_en from "../../translate/en";

const messages = {
  es: messages_es,
  en: messages_en,
};

const HomePage = ({ history, match }) => {
  const dispatch = useDispatch();

  /* Control de login */
  useEffect(() => {
    let auth = localStorage.getItem("auth");
    auth = auth === "true";
    if (!auth) {
      history.push("/");
      dispatch(resetLogin());
    }
  });

  /* Cerrar sesión */
  const logOut = () => {
    dispatch(resetLogin());
    history.push("/");
  };

  /* Verificamos si tenemos localstorage con translate */
  let language = navigator.language.split(/[-_]/)[0];
  if (
    localStorage.getItem("language") === "es" ||
    localStorage.getItem("language") === "en"
  ) {
    language = localStorage.getItem("language");
  } else if (language !== "es" && language !== "en") {
    language = "en";
  }

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div className="containerContentPage">
        <Menu logOut={logOut} />
        <div className="containerDashBoard">
          <TopBar />
          <Route exact path={`${match.path}/home`} component={Home} />
          <Route
            exact
            path={`${match.path}/add_company`}
            component={AddCompany}
          />
          <Route exact path={`${match.path}/groups`} component={GroupPage} />
          <Route
            exact
            path={`${match.path}/update_groups/:id`}
            component={UpdateGroup}
          />
          <Route exact path={`${match.path}/add_group`} component={AddGroup} />
          <Route
            exact
            path={`${match.path}/update_company/:id`}
            component={UpdateCompany}
          />
          <Route
            exact
            path={`${match.path}/add_company_avatars/:id`}
            component={AddCompanyAvatars}
          />
          <Route exact path={`${match.path}/cargos`} component={Cargos} />
          <Route
            exact
            path={`${match.path}/add_cargo/:id`}
            component={AddCargo}
          />
          <Route
            exact
            path={`${match.path}/update_cargo/:id`}
            component={UpdateCargo}
          />
          <Route
            exact
            path={`${match.path}/edificaciones`}
            component={Edificaciones}
          />
          <Route
            exact
            path={`${match.path}/add_edificacion/:id`}
            component={AddEdificacion}
          />
          <Route
            exact
            path={`${match.path}/update_edificacion/:id`}
            component={UpdateEdificacion}
          />
          <Route
            exact
            path={`${match.path}/add_edificacion_avatars/:id`}
            component={AddEdificacionAvatars}
          />
          <Route exact path={`${match.path}/nodos/:id`} component={Nodos} />
          <Route
            exact
            path={`${match.path}/add_nodo/:id`}
            component={AddNodo}
          />
          <Route
            exact
            path={`${match.path}/update_nodo/:id`}
            component={UpdateNodo}
          />
          <Route
            exact
            path={`${match.path}/divisiones/:id`}
            component={Divisiones}
          />
          <Route
            exact
            path={`${match.path}/add_division/:id`}
            component={AddDivision}
          />
          <Route
            exact
            path={`${match.path}/update_division/:id`}
            component={UpdateDivision}
          />
          <Route
            exact
            path={`${match.path}/add_division_avatars/:id`}
            component={AddDivisionAvatars}
          />
          <Route
            exact
            path={`${match.path}/update_division_atributos/:id`}
            component={UpdateDivisionAtributos}
          />
          <Route
            exact
            path={`${match.path}/update_division_deseables/:id`}
            component={UpdateDivisionDeseables}
          />
          <Route
            exact
            path={`${match.path}/update_division_recursos/:id`}
            component={UpdateDivisionRecursos}
          />
          <Route
            exact
            path={`${match.path}/bolsas_atributos`}
            component={BolsaAtributos}
          />
          <Route
            exact
            path={`${match.path}/add_bolsa_atributo/:id`}
            component={AddBolsaAtributo}
          />
          <Route
            exact
            path={`${match.path}/update_bolsa_atributo/:id`}
            component={UpdateBolsaAtributo}
          />
          <Route
            exact
            path={`${match.path}/preguntas/:id`}
            component={Preguntas}
          />
          <Route
            exact
            path={`${match.path}/add_pregunta/:id`}
            component={AddPregunta}
          />
          <Route
            exact
            path={`${match.path}/update_pregunta/:id`}
            component={UpdatePregunta}
          />
          <Route
            exact
            path={`${match.path}/alternativas/:id`}
            component={Alternativas}
          />
          <Route
            exact
            path={`${match.path}/add_alternativa/:id`}
            component={AddAlternativa}
          />
          <Route
            exact
            path={`${match.path}/update_alternativa/:id`}
            component={UpdateAlternativa}
          />
          <Route
            exact
            path={`${match.path}/add_alternativa_avatars/:id`}
            component={AddAlternativaAvatars}
          />
          <Route
            exact
            path={`${match.path}/atributos/:id`}
            component={Atributos}
          />
          <Route
            exact
            path={`${match.path}/add_atributo/:id`}
            component={AddAtributo}
          />
          <Route
            exact
            path={`${match.path}/update_atributo/:id`}
            component={UpdateAtributo}
          />
          <Route
            exact
            path={`${match.path}/bolsas_recursos`}
            component={BolsaRecursos}
          />
          <Route
            exact
            path={`${match.path}/add_bolsa_recurso/:id`}
            component={AddBolsaRecurso}
          />
          <Route
            exact
            path={`${match.path}/update_bolsa_recurso/:id`}
            component={UpdateBolsaRecurso}
          />
          <Route
            exact
            path={`${match.path}/recursos/:id`}
            component={Recursos}
          />
          <Route
            exact
            path={`${match.path}/add_recurso/:id`}
            component={AddRecurso}
          />
          <Route
            exact
            path={`${match.path}/update_recurso/:id`}
            component={UpdateRecurso}
          />
          <Route
            exact
            path={`${match.path}/bolsas_deseables`}
            component={BolsaDeseables}
          />
          <Route
            exact
            path={`${match.path}/add_bolsa_deseable/:id`}
            component={AddBolsaDeseable}
          />
          <Route
            exact
            path={`${match.path}/update_bolsa_deseable/:id`}
            component={UpdateBolsaDeseable}
          />
          <Route
            exact
            path={`${match.path}/deseables/:id`}
            component={Deseables}
          />
          <Route
            exact
            path={`${match.path}/add_deseable/:id`}
            component={AddDeseable}
          />
          <Route
            exact
            path={`${match.path}/update_deseable/:id`}
            component={UpdateDeseable}
          />
          <Route exact path={`${match.path}/usuarios`} component={Usuarios} />
          <Route
            exact
            path={`${match.path}/add_usuario/:id`}
            component={AddUsuario}
          />
          <Route
            exact
            path={`${match.path}/load_usuario/`}
            component={LoadUsuario}
          />
          <Route
            exact
            path={`${match.path}/update_usuario/:id`}
            component={UpdateUsuario}
          />
          <Route
            exact
            path={`${match.path}/update_password/:id`}
            component={UpdatePassword}
          />
          <Route
            exact
            path={`${match.path}/jornadas/:id`}
            component={Jornadas}
          />
          <Route
            exact
            path={`${match.path}/add_jornada/:id`}
            component={AddJornada}
          />
          <Route
            exact
            path={`${match.path}/delete_jornada/:id`}
            component={DeleteJornada}
          />
          <Route
            exact
            path={`${match.path}/horarios/:id`}
            component={Horarios}
          />
          <Route
            exact
            path={`${match.path}/add_horario/:id`}
            component={AddHorario}
          />
          <Route
            exact
            path={`${match.path}/companies_politicas`}
            component={CompaniesPoliticas}
          />
          <Route exact path={`${match.path}/accesos/:id`} component={Accesos} />
          <Route
            exact
            path={`${match.path}/granularity`}
            component={Granularity}
          />
          <Route
            exact
            path={`${match.path}/update_granularity/:id`}
            component={UpdateGranularity}
          />
          <Route
            exact
            path={`${match.path}/accesos_recursos/:id`}
            component={AccesosRecursos}
          />
          <Route
            exact
            path={`${match.path}/reservas/:id`}
            component={Reservas}
          />
          <Route
            exact
            path={`${match.path}/add_area/:id`}
            component={AddArea}
          />
          <Route exact path={`${match.path}/areas`} component={Areas} />
          <Route
            exact
            path={`${match.path}/update_area/:id`}
            component={UpdateArea}
          />
          <Route
            exact
            path={`${match.path}/add_user_excel/`}
            component={AddUserExcel}
          />

          <Route exact path={`${match.path}/creditos`} component={Credito} />
          <Route
            exact
            path={`${match.path}/add_ventana/:id`}
            component={AddVentana}
          />

          <Route exact path={`${match.path}/ventanas`} component={Ventana} />
          <Route
            exact
            path={`${match.path}/add_credito/:id`}
            component={AddCredito}
          />
          <Route
            exact
            path={`${match.path}/update_credito/:id`}
            component={UpdateCredito}
          />
          <Route
            exact
            path={`${match.path}/update_ventana/:id`}
            component={UpdateVentana}
          />
          <Route
            exact
            path={`${match.path}/update_ventana_usuario/:id`}
            component={UpdateVentanaUsuario}
          />
          <Route
            exact
            path={`${match.path}/ventana_reserva/:id`}
            component={VentanaReserva}
          />
        </div>
      </div>
    </IntlProvider>
  );
};

export default HomePage;
