import axios from "axios";

/* Get Usuarios*/
export const GetUsuarios = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_USUARIOS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/usuarios/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_USUARIOS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_USUARIOS_FAIL",
    });
    console.log("Error en getUsuarios Action: veni a ver el desmadre", err);
  }
};

/* Reset Usuarios */
export const resetUsuarios = () => {
  return {
    type: "RESET_USUARIOS",
  };
};

export const DeleteUsuario = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_USUARIO_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/usuarios/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_USUARIO_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_USUARIO_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteUsuario = () => {
  return {
    type: "RESET_DELETE_USUARIO",
  };
};

export const AddRoles = (obj) => async (dispatch) => {
  dispatch({
    type: "ADD_ROLES_LOADING",
  });
  try {
    const response = await axios.post(`/api/roles`, obj);
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "ADD_ROLES_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "ADD_ROLES_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetAddRoles = () => {
  return {
    type: "RESET_ADD_ROLES",
  };
};
