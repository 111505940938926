import axios from "axios";

/* Get Divisiones*/
export const GetDivisiones = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_DIVISIONES_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/divisiones/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_DIVISIONES_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_DIVISIONES_FAIL",
    });
    console.log("Error en getDivisionesAction: veni a ver el desmadre", err);
  }
};

/* Reset Divisiones */
export const resetDivisiones = () => {
  return {
    type: "RESET_DIVISIONES",
  };
};

export const DeleteDivision = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_DIVISION_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/divisiones/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_DIVISION_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_DIVISION_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteDivision = () => {
  return {
    type: "RESET_DELETE_DIVISION",
  };
};

/* Get DivisionesArbol*/
export const GetDivisionesArbol = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_DIVISIONES_ARBOL_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/divisionesarbol/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_DIVISIONES_ARBOL_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_DIVISIONES_ARBOL_FAIL",
    });
    console.log(
      "Error en getDivisionesArbolAction: veni a ver el desmadre",
      err
    );
  }
};

/* Reset DivisionesArbol */
export const resetDivisionesArbol = () => {
  return {
    type: "RESET_DIVISIONES_ARBOL",
  };
};
