import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

/* Actions */
import { GetCode, resetCode } from "../../actions/code/index";

function ModalCode(props) {
  /* States */
  const stateCode = useSelector((state) => state.code);

  const dispatch = useDispatch();

  const close = () => {
    dispatch(GetCode({}));
  };

  const reset = () => {
    dispatch(resetCode());
    props.setModal(false);
  };

  return (
    <>
      <Modal
        show={props.modal}
        onHide={() => reset()}
        onExit={() => reset()}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Código
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {stateCode.status === 0 && (
            <div>
              {stateCode.loading ? (
                <h4>
                  <FormattedMessage id="Loading" />
                </h4>
              ) : (
                <h4>
                  <FormattedMessage id="AreYouSure" />
                </h4>
              )}
            </div>
          )}
          {stateCode.status === 200 && (
            <div>
              {stateCode.data.ejecucion.estado ? (
                <h4>Código fue enviado</h4>
              ) : (
                <p>{stateCode.data.ejecucion.mensaje}</p>
              )}
            </div>
          )}
        </Modal.Body>
        {stateCode.status === 0 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button variant="secondary" onClick={close}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
        {stateCode.status === 200 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalCode;
