const messages = {
  Hello: "Hola",
  Yes: "Si",
  No: "No",
  Select: "Seleccione",
  Name: "Nombre",
  TimeZone: "Uso horario",
  Countries: "Países",
  Languages: "Idiomas",
  Group: "Grupo",
  Email: "Email",
  LastName: "Apellido",
  SecondLastName: "Segundo Apellido",
  Groups: "Grupos",
  ExpiredSession: "Sesión expirada",
  PleaseReconnect: "Por favor reingrese",
  Open: "Abierto",
  Password: "Contraseña",
  Add: "Agregar",
  Loading: "Cargando",
  Ok: "Aceptar",
  SuccessfullyAdded: "Agregado con éxito",
  SuccessfullyUpdated: "Actualizado con éxito",
  SuccessfullyDeleted: "Eliminado con éxito",
  ThereWasAnError: "Hubo un error",
  Information: "Información",
  Update: "Actualizar",
  Cancel: "Cancelar",
  Delete: "Eliminar",
  AreYouSure: "¿Estas seguro?",
  Avatar1: "Imágen 1",
  Avatar1IsOk: "Avatar 1 cargado",
  Avatar2: "Imágen 2",
  Avatar2IsOk: "Avatar 2 cargado",
  Avatar3: "Imágen 3",
  Avatar3IsOk: "Avatar 3 cargado",
  Companies: "Empresas",
  DateStart: "Fecha inicio",
  DateEnd: "Fecha termino",
  Monday: "Lunes",
  Tuesday: "Martes",
  Wednesday: "Miércoles",
  Thursday: "Jueves",
  Friday: "Viernes",
  Saturday: "Sábado",
  Sunday: "Domingo",
  Holiday: "Feriados",
  Date: "Fecha",
  HourStart: "Comienzo",
  HourEnd: "Termino",
  AttendTheNextDay: "Attend the next day",
};

export default messages;
