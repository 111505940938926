import initialState from "./initialState";
import {
  ADD_USUARIO_INIT,
  ADD_USUARIO_SUCCESS,
  ADD_USUARIO_FAILURE,
  RESET_ADD_USUARIO,
} from "../../actions/add_usuario/types";

export default function usuario(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_ADD_USUARIO:
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case ADD_USUARIO_INIT:
      return {
        ...state,
        loading: true,
      };
    case ADD_USUARIO_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case ADD_USUARIO_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    default:
      return state;
  }
}
