import {
  GET_BOLSA_DESEABLE_INIT,
  GET_BOLSA_DESEABLE_SUCCESS,
  GET_BOLSA_DESEABLE_FAILURE,
  RESET_BOLSA_DESEABLE,
  UPDATE_BOLSA_DESEABLE_INIT,
  UPDATE_BOLSA_DESEABLE_SUCCESS,
  UPDATE_BOLSA_DESEABLE_FAILURE,
  RESET_UPDATE_BOLSA_DESEABLE,
} from "./types";
import API from "./api";

export function resetBolsaDeseable() {
  return {
    type: RESET_BOLSA_DESEABLE,
  };
}

function getBolsaDeseableSuccess(data) {
  return {
    type: GET_BOLSA_DESEABLE_SUCCESS,
    payload: data,
  };
}

function getBolsaDeseableFailure(error) {
  console.log(error);
  return {
    type: GET_BOLSA_DESEABLE_FAILURE,
    payload: error,
  };
}

function getBolsaDeseableInit(data) {
  return {
    type: GET_BOLSA_DESEABLE_INIT,
    payload: data.loading,
  };
}

export function getBolsaDeseable(data) {
  return async (dispatch) => {
    dispatch(getBolsaDeseableInit(data));
    try {
      const resp = await API.data.getBolsa(data);
      return dispatch(getBolsaDeseableSuccess(resp));
    } catch (error) {
      return dispatch(getBolsaDeseableFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateBolsaDeseable() {
  return {
    type: RESET_UPDATE_BOLSA_DESEABLE,
  };
}

function updateBolsaDeseableSuccess(data) {
  return {
    type: UPDATE_BOLSA_DESEABLE_SUCCESS,
    payload: data,
  };
}

function updateBolsaDeseableFailure(error) {
  return {
    type: UPDATE_BOLSA_DESEABLE_FAILURE,
    payload: error,
  };
}

function updateBolsaDeseableInit() {
  return {
    type: UPDATE_BOLSA_DESEABLE_INIT,
  };
}

export function updateBolsaDeseable(data) {
  return async (dispatch) => {
    dispatch(updateBolsaDeseableInit());
    try {
      const resp = await API.data.updateBolsa(data);
      return dispatch(updateBolsaDeseableSuccess(resp));
    } catch (error) {
      return dispatch(updateBolsaDeseableFailure(error));
    }
  };
}
