import axios from "axios";

const API = {
  data: {
    async getJornadaHoliday(obj) {
      const response = await axios.get(
        `/api/jornadaholiday/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async postNewJornada(obj) {
      try {
        axios.defaults.timeout = 60 * 20 * 1000;
        const response = await axios({
          method: "post",
          url: "/api/jornadas",
          timeout: 60 * 20 * 1000, // Let's say you want to wait at least 4 mins
          data: obj,
        });

        const { status } = response;
        let data = await response.data;

        return {
          data,
          status,
        };
      } catch (error) {
        return console.log("error en carga", error);
      }
    },
  },
};
export default API;
