import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DashboardPage from "../dashboard/dashboardPage";
import LoginPage from "../login/loginPage";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/" exact component={LoginPage} />
          <Route path="/dashboard" component={DashboardPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
