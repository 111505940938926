import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { GetCompanies, resetCompanies } from "../../actions/home/api/index";

/* Language */
import { FormattedMessage } from "react-intl";

const FilterCountry = (props) => {
  const state = useSelector((state) => state.GetCompanies);
  const dispatch = useDispatch();
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(GetCompanies({}));
    };
    callsAPI();
  }, [dispatch]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetCompanies());
    };
  }, [dispatch]);

  return (
    <div
      className={props.filterForm ? "containerFormBox" : "containerFormFilter"}
    >
      <Form.Group>
        <Form.Label className={props.labelDark ? "dark" : ""}>
          <FormattedMessage id="Companies" />
        </Form.Label>
        <Form.Control
          as="select"
          value={props.form[props.name]}
          onChange={props.handleChange}
          name={props.name}
          requeried="true"
        >
          <FormattedMessage id="Select">
            {(message) => <option value="">{message}</option>}
          </FormattedMessage>
          {!_.isEmpty(state.data) &&
            state.data.map((item, key) => (
              <option key={key} value={item.id}>
                {item.nombre}
              </option>
            ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export default FilterCountry;
