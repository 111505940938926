import initialState from "./initialState";
import {
  GET_ACCESOS_INIT,
  GET_ACCESOS_SUCCESS,
  GET_ACCESOS_FAILURE,
  RESET_ACCESOS,
  ADD_ACCESO_INIT,
  ADD_ACCESO_SUCCESS,
  ADD_ACCESO_FAILURE,
  RESET_ADD_ACCESO,
} from "../../actions/accesos/types";

export default function client(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_ACCESOS:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_ACCESOS_INIT:
      return {
        ...state,
        loading: action.loading,
      };
    case GET_ACCESOS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_ACCESOS_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case RESET_ADD_ACCESO:
      return {
        ...state,
        dataAdd: {},
        loadingAdd: false,
        statusAdd: 0,
      };
    case ADD_ACCESO_INIT:
      return {
        ...state,
        loadingAdd: true,
      };
    case ADD_ACCESO_SUCCESS:
      return {
        ...state,
        dataAdd: action.payload.data,
        loadingAdd: false,
        statusAdd: action.payload.status,
      };
    case ADD_ACCESO_FAILURE:
      return {
        ...state,
        dataAdd: {},
        loadingAdd: false,
        statusAdd: 501,
      };
    default:
      return state;
  }
}
