import {
  ADD_CREDITO_INIT,
  ADD_CREDITO_SUCCESS,
  ADD_CREDITO_FAILURE,
  RESET_ADD_CREDITO,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddCredito() {
  return {
    type: RESET_ADD_CREDITO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addCreditoSuccess(data) {
  return {
    type: ADD_CREDITO_SUCCESS,
    payload: data,
  };
}

function addCreditoFailure(error) {
  return {
    type: ADD_CREDITO_FAILURE,
    payload: error,
  };
}

function addCreditoInit() {
  return {
    type: ADD_CREDITO_INIT,
  };
}

export function addCredito(data) {
  return async (dispatch) => {
    dispatch(addCreditoInit());
    try {
      const resp = await API.data.addCredito(data);
      return dispatch(addCreditoSuccess(resp));
    } catch (error) {
      return dispatch(addCreditoFailure(error));
    }
  };
}
