import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import add from "../../assets/img/add.svg";
import Tree from "react-tree-graph";

/* Actions */
import {
  GetDivisiones,
  resetDivisiones,
  DeleteDivision,
  resetDeleteDivision,
  GetDivisionesArbol,
  resetDivisionesArbol,
} from "../../actions/division/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";
import ModalDivision from "../common/modalDivision";

const Divisiones = (props) => {
  const dispatch = useDispatch();

  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [modald, setModald] = useState(false);
  const [idDelete, setIdDelete] = useState(false);
  const [name, setName] = useState(false);
  const [list, setList] = useState(true);
  const [creditos, setCreditos] = useState([]);

  const myIds = props.match.params.id.split("-");

  const edificacion_id = myIds[0];
  /* States */
  const getDivisiones = useSelector((state) => state.GetDivisiones);

  useEffect(() => {
    const FetchData = () => {
      dispatch(GetDivisiones({ edificacion_id: parseInt(edificacion_id, 10) }));
      dispatch(
        GetDivisionesArbol({
          edificacion_id: parseInt(edificacion_id, 10),
        })
      );
    };
    FetchData();
  }, [dispatch, edificacion_id]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetDivisionesArbol());
      dispatch(resetDivisiones());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };

  const handleModald = (obj) => {
    setModald(true);
    setIdDelete(obj.id);
    setName(obj.name);
  };

  /* Delete entidad */
  const submitDelete = () => {
    dispatch(DeleteDivision({ edi_division_id: idDelete }));
  };

  const addCreditos = (division) => {
    const arr = [...creditos];

    const index = arr.findIndex((o) => o === division);

    if (index < 0) {
      arr.push(division);
    } else {
      arr.splice(index, 1);
    }
    setCreditos(arr);
  };

  /* Reset el estado de delete*/
  const submitResetDelete = () => {
    dispatch(resetDeleteDivision());
    if (getDivisiones.statusDelete === 200) {
      if (getDivisiones.dataDelete.ejecucion.estado) {
        dispatch(GetDivisiones({ edificacion_id: parseInt(myIds[0], 10) }));
        dispatch(
          GetDivisionesArbol({
            edificacion_id: parseInt(myIds[0], 10),
          })
        );
      }
    }
  };

  const onClick = (event, nodeKey) => {
    event.preventDefault();
    /* Actualizar */
    const idarr = nodeKey.split(" ");
    let id = idarr[0].replace("[[", "");
    id = id.replace("]]", "");
    handleModald({ id: parseInt(id, 10), name: nodeKey });
  };

  const onRightClick = (event, nodeKey, a) => {
    event.preventDefault();
    /* Eliminar */
    const idarr = nodeKey.split(" ");
    let id = idarr[0].replace("[[", "");
    id = id.replace("]]", "");
    handleModal({ id: parseInt(id, 10) });
  };

  const showDivisiones = () => {
    if (getDivisiones.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getDivisiones.data)) {
      return (
        <div className="containerAllEmpresas">
          {getDivisiones.data.map((item, key) => {
            const index = creditos.findIndex((o) => o === item.id);
            const name = item.name.replaceAll(" ", "&");
            return (
              <div className="eachEmpresa" key={key}>
                <div className="leftSideEachEmpresa">
                  <p className="containerTable">{item.id}</p>
                  <p className="containerTable">{item.nombre}</p>
                  <p className="containerTable">
                    {item.envio_correo_a_buzon ? "Si" : "No"}
                  </p>
                  <p className="containerTable">
                    <button
                      onClick={() => addCreditos(item.id)}
                      className={index >= 0 ? "activeButton" : "normalButton"}
                    >
                      Credito
                    </button>
                  </p>
                  <Link
                    className="containerTable"
                    to={{
                      pathname: `/dashboard/update_division/${item.id}-${myIds[1]}`,
                      state: {
                        items: item,
                      },
                    }}
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                  <div className="containerTable">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li>
                            <Link
                              to={`/dashboard/add_division_avatars/${item.id}-${myIds[1]}`}
                            >
                              Agregar Imagen
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={{
                                pathname: `/dashboard/update_division_atributos/${item.id}-${myIds[1]}`,
                                state: {
                                  arrAtri: props.location.state.arrAtri,
                                },
                              }}
                            >
                              Atributos
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={{
                                pathname: `/dashboard/update_division_deseables/${item.id}-${myIds[1]}`,
                                state: {
                                  arrDes: props.location.state.arrDes,
                                },
                              }}
                            >
                              Deseables
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={{
                                pathname: `/dashboard/update_division_recursos/${item.id}-${myIds[1]}`,
                                state: {
                                  arrRec: props.location.state.arrRec,
                                },
                              }}
                            >
                              Recursos
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/dashboard/jornadas/${name}(--)${myIds[1]}`}
                            >
                              Jornadas
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/dashboard/horarios/${name}(--)${myIds[1]}`}
                            >
                              Horarios
                            </Link>
                          </li>
                          <li>
                            <Link to={`/dashboard/reservas/${item.id}`}>
                              Reservas
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleModal({ id: item.id });
                  }}
                  className="rightSideEachEmpresa"
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getDivisiones.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };

  const showDivisionesTree = () => {
    if (getDivisiones.loadingArbol) {
      return <Loading />;
    } else if (getDivisiones.dataArbol.id) {
      return (
        <div className="custom-container">
          <Tree
            svgProps={{
              className: "custom",
            }}
            gProps={{
              onClick: onClick,
              onContextMenu: onRightClick,
            }}
            data={getDivisiones.dataArbol}
            height={1200}
            width={1200}
          />
        </div>
      );
    } else if (getDivisiones.errorMsgArbol !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };

  return (
    <React.Fragment>
      <div className="containerEmpresa">
        <ModalDelete
          func={() => {
            submitDelete();
          }}
          state={getDivisiones}
          modal={modal}
          reset={submitResetDelete}
          setModal={setModal}
        />
        <ModalDivision
          id={`${idDelete}-${myIds[1]}`}
          idCompany={myIds[1]}
          modal={modald}
          name={name}
          setModal={setModald}
        />
        <p className="titleEmpresas">Divisiones</p>
        <div className="lineGray"></div>\
        <section className="containerFilter">
          <section className="containerTabs">
            <div onClick={() => setList(true)} className={list ? "active" : ""}>
              Lista
            </div>
            <div
              onClick={() => setList(false)}
              className={!list ? "active" : ""}
            >
              Arbol
            </div>
          </section>

          <section className="containerMasivo top">
            <span className="cargadorMasivo">
              <Link
                to={{
                  pathname: `/dashboard/add_ventana/${myIds[1]}`,
                  state: {
                    items: creditos,
                  },
                }}
              >
                <i className="fas fa-coins"></i> Creditos
              </Link>
            </span>
          </section>
        </section>
        {list ? (
          <React.Fragment>
            <div className="containerSubTitleTable">
              <div className="leftSideEachEmpresa">
                <p className="containerTable">ID</p>
                <p className="containerTable">Nombre</p>
                <p className="containerTable">Envio correo</p>
                <p className="containerTable">Creditos</p>
                <p className="containerTable">Editar</p>
                <p className="containerTable">Más</p>
              </div>
              <div className="rightSideEachEmpresa">
                <p>Eliminar</p>
              </div>
            </div>
            {showDivisiones()}
          </React.Fragment>
        ) : (
          showDivisionesTree()
        )}
      </div>
      <Link to={`/dashboard/add_division/${myIds[0]}`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Divisiones;
