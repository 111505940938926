import {
  GET_AREA_INIT,
  GET_AREA_SUCCESS,
  GET_AREA_FAILURE,
  RESET_AREA,
  UPDATE_AREA_INIT,
  UPDATE_AREA_SUCCESS,
  UPDATE_AREA_FAILURE,
  RESET_UPDATE_AREA,
} from "./types";
import API from "./api";

export function resetArea() {
  return {
    type: RESET_AREA,
  };
}

function getAreaSuccess(data) {
  return {
    type: GET_AREA_SUCCESS,
    payload: data,
  };
}

function getAreaFailure(error) {
  console.log(error);
  return {
    type: GET_AREA_FAILURE,
    payload: error,
  };
}

function getAreaInit(data) {
  return {
    type: GET_AREA_INIT,
    payload: data.loading,
  };
}

export function getArea(data) {
  return async (dispatch) => {
    dispatch(getAreaInit(data));
    try {
      const resp = await API.data.getArea(data);
      return dispatch(getAreaSuccess(resp));
    } catch (error) {
      return dispatch(getAreaFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateArea() {
  return {
    type: RESET_UPDATE_AREA,
  };
}

function updateAreaSuccess(data) {
  return {
    type: UPDATE_AREA_SUCCESS,
    payload: data,
  };
}

function updateAreaFailure(error) {
  return {
    type: UPDATE_AREA_FAILURE,
    payload: error,
  };
}

function updateAreaInit() {
  return {
    type: UPDATE_AREA_INIT,
  };
}

export function updateArea(data) {
  return async (dispatch) => {
    dispatch(updateAreaInit());
    try {
      const resp = await API.data.updateArea(data);
      return dispatch(updateAreaSuccess(resp));
    } catch (error) {
      return dispatch(updateAreaFailure(error));
    }
  };
}
