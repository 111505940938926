import initialState from "./initialState";
import {
  ADD_AREA_INIT,
  ADD_AREA_SUCCESS,
  ADD_AREA_FAILURE,
  RESET_ADD_AREA,
} from "../../actions/add_area/types";

export default function area(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_ADD_AREA:
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case ADD_AREA_INIT:
      return {
        ...state,
        loading: true,
      };
    case ADD_AREA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case ADD_AREA_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    default:
      return state;
  }
}
