import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

/* Actions */
import {
  getAlternativa,
  resetAlternativa,
  updateAlternativa,
  resetUpdateAlternativa,
} from "../../actions/update_alternativa";

/* Componets */
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateAlternativa);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    q_invitados: "",
    clave: "",
    pide_invitados: "",
  });

  const [modalMessage, setModalMessage] = useState(false);
  const [nombres, setNombres] = useState([]);
  const [atributos, setAtributos] = useState([]);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getAlternativa({ alternativa_id: props.id }));
    };
    callsAPI();
  }, [dispatch, props.id]);

  useEffect(() => {
    return () => {
      dispatch(resetAlternativa());
      dispatch(resetUpdateAlternativa());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          const list_name = [];
          const list_attr = [];
          state.data.datos.glosa.forEach((item, i) => {
            list_name.push({
              value: item.glosa || "",
              index: `name-${i}`,
              id: item.id,
              label: item.lenguaje,
            });
          });

          state.data.datos.atributos_seleccionados.forEach((item, i) => {
            list_attr.push({
              value: item.seleccionado ? "1" : "0",
              index: `name-${i}`,
              id: item.atributo_id,
              label: item.atributo_nombre,
            });
          });
          setAtributos(list_attr);
          setNombres(list_name);
          setForm((prev) => ({
            ...prev,
            id: props.id,
            clave: state.data.datos.clave,
            q_invitados: state.data.datos.q_invitados,
            nombre: state.data.datos.nombre,
            pide_invitados: state.data.datos.pide_invitados ? "1" : "0",
          }));
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleAtributos = (event) => {
    event.persist();
    let list_name = [...atributos];
    const index = atributos.findIndex(
      (item) => item.index === event.target.name
    );
    list_name[index].value = event.target.value;
    setAtributos(list_name);
  };

  /* Para cambiar los nombres */
  const handleNombres = (event) => {
    event.persist();
    let list_name = [...nombres];
    const index = nombres.findIndex((item) => item.index === event.target.name);
    list_name[index].value = event.target.value;
    setNombres(list_name);
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const nombresArr = [];
    nombres.forEach((item) => {
      nombresArr.push({ lenguaje_id: item.id, glosa: item.value });
    });
    const atributosArr = [];
    atributos.forEach((item) => {
      atributosArr.push({
        atributo_id: item.id,
        seleccionado: item.value === "1" ? true : false,
      });
    });

    const obj = {
      alternativa_id: parseInt(props.id, 10),
      nombre: form.nombre,
      clave: form.clave,
      q_invitados: parseInt(form.q_invitados, 10),
      pide_invitados: form.pide_invitados === "1" ? true : false,
      atributos_seleccionados: atributosArr,
      glosa: nombresArr,
    };

    dispatch(updateAlternativa(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetUpdateAlternativa());
  };

  if (state.loading || form.id === "") {
    return <Loading />;
  } else if (state.status === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.status === 200) {
    if (!state.data.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }
  let title = null;
  let showTitle = false;
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos empresas 1</h1>
          </div>
          {nombres.map((item) => {
            return (
              <div className="containerFormBox" key={item.id}>
                <Form.Group>
                  <Form.Label>
                    {item.label} <FormattedMessage id="Name" />{" "}
                    <span>hola marrano</span>
                  </Form.Label>
                  <Form.Control
                    value={item.value}
                    onChange={handleNombres}
                    type="text"
                    name={item.index}
                  />
                </Form.Group>
              </div>
            );
          })}

          {_.orderBy(atributos, ["id", "label"], ["asc", "asc"]).map((item) => {
            if (item.tag !== title) {
              showTitle = true;
              title = item.tag;
            } else {
              showTitle = false;
            }

            return (
              <React.Fragment key={item.id}>
                {showTitle && (
                  <div className="containerFormTitle">
                    <h1>{item.tag}</h1>
                  </div>
                )}
                <div className="containerFormBox">
                  <Form.Group>
                    <Form.Label>
                      {item.id} - {item.label}
                      <span>hola marrano</span>
                    </Form.Label>
                    <Form.Control
                      value={item.value}
                      onChange={handleAtributos}
                      type="text"
                      name={item.index}
                      as="select"
                    >
                      <FormattedMessage id="Select">
                        {(message) => <option value="">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="No">
                        {(message) => <option value="0">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="Yes">
                        {(message) => <option value="1">{message}</option>}
                      </FormattedMessage>
                    </Form.Control>
                  </Form.Group>
                </div>
              </React.Fragment>
            );
          })}

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Nombre <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.nombre}
                onChange={handleChange}
                type="text"
                name="nombre"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Cantidad invitados <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.q_invitados}
                onChange={handleChange}
                type="number"
                name="q_invitados"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Clave <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.clave}
                onChange={handleChange}
                type="text"
                name="clave"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Pide invitados <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={form.pide_invitados}
                onChange={handleChange}
                name="pide_invitados"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={state.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {state.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {state.statusUpdate === 200 && !state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={state.dataUpdate.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
      {state.statusUpdate === 200 && state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyUpdated" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
