import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import {
  fetchUserUploadValidateApi,
  resetFormUserExcelValidate,
} from "../../actions/add_user_excel";

/* Componets */
import ModalMessage from "../common/modalMessage";
import FilterCompany from "../common/filterCompany";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const stateAdd = useSelector((state) => state.addUserExcel);
  const [ingresar, setIngresar] = useState(false);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    recfile: "",
    empresa_id: "",
    lenguaje_id: "es",
    formato_nombre: "nombres-separado",
  });

  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    if (stateAdd.status === 200 && ingresar === false) {
      if (stateAdd.data.ejecucion.estado) {
        setIngresar(stateAdd.data.datos.md5);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      }
    } else if (stateAdd.status === 200 && ingresar !== false) {
      if (stateAdd.data.ejecucion.estado) {
        setIngresar(false);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateAdd]);

  useEffect(() => {
    return () => {
      dispatch(resetFormUserExcelValidate());
    };
  }, [dispatch]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      nombre: form.recfile,
      lenguaje_id: form.lenguaje_id,
      activo: 1,
      formato_nombre: form.formato_nombre,
      empresa_id: form.empresa_id,
      type: ingresar === false ? "validar" : "ingresar",
      md5: ingresar,
    };

    dispatch(fetchUserUploadValidateApi(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetFormUserExcelValidate());
  };

  return (
    <React.Fragment>
      <Form>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos cargo</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Name" />
                <span>Cargado masivo</span>
              </Form.Label>
              <Form.Control
                value={form.recfile}
                onChange={handleChange}
                name="recfile"
                id="recfile"
                type="file"
              />
            </Form.Group>
          </div>
          <FilterCompany
            filterForm={true}
            name="empresa_id"
            form={form}
            handleChange={handleChange}
          />
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Lenguaje</Form.Label>
              <Form.Control
                as="select"
                value={form.lenguaje_id}
                onChange={handleChange}
                name={"lenguaje_id"}
              >
                <option value="es">Español</option>
                <option value="en">Ingles</option>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Formato nombres</Form.Label>
              <Form.Control
                as="select"
                value={form.formato_nombre}
                onChange={handleChange}
                name={"formato_nombre"}
              >
                <option value="nombres-separado">Nombres separado</option>
                <option value="nombres-junto">Nombres Juntos</option>
              </Form.Control>
            </Form.Group>
          </div>
          );
          <div className="containerFormButton">
            {ingresar !== false ? (
              <div>
                {loading === true ? (
                  <Button
                    disabled={stateAdd.loading}
                    type="button"
                    variant="warning"
                  >
                    Cargando
                  </Button>
                ) : (
                  <Button
                    disabled={stateAdd.loading}
                    type="button"
                    onClick={handleSubmit}
                    variant="warning"
                  >
                    {stateAdd.loading ? (
                      <FormattedMessage id="Loading" />
                    ) : (
                      "Ingresar"
                    )}
                  </Button>
                )}
              </div>
            ) : (
              <div>
                <Button
                  disabled={stateAdd.loading}
                  type="button"
                  onClick={handleSubmit}
                  variant="warning"
                >
                  {stateAdd.loading ? (
                    <FormattedMessage id="Loading" />
                  ) : (
                    "Validar"
                  )}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Form>
      {stateAdd.status === 200 && !stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={stateAdd.data.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={() => {
            resetForm();
          }}
          loading={stateAdd.loading}
        />
      )}
      {stateAdd.status === 200 && stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={"Operación realizada con éxito"}
          modal={modalMessage}
          setModal={setModalMessage}
          func={() => {
            resetForm();
          }}
          loading={stateAdd.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
