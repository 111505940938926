import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DatePicker from "react-datepicker";
import moment from "moment";

import MondaySection from "./monday";
import TuesdaySection from "./tuesday";
import WednesdaySection from "./wednesday";
import ThursdaySection from "./thursday";
import FridaySection from "./friday";
import SaturdaySection from "./saturday";
import SundaySection from "./sunday";
import HolidaySection from "./holiday";
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";

import ModalMessage from "../common/modalMessage";

import {
  getJornadaHoliday,
  resetJornadaHoliday,
  postNewJornada,
  resetNewJornada,
} from "../../actions/add_jornada";

import "react-datepicker/dist/react-datepicker.css";

const FormComponent = (props) => {
  const [form] = useState({
    id_fila: props.id,
  });

  const myIds = props.id.split("(--)");
  const name = myIds[0].replaceAll("&", " ");

  const idarr = name.split(" ");
  let id = idarr[0].replace("[[", "");
  id = id.replace("]]", "");

  const [holiday, setHoliday] = useState([]);
  const [count, setCount] = useState(0);
  const [modalMessage, setModalMessage] = useState(false);

  const [monday, setMonday] = useState({
    abre: "08",
    cierra: "20",
    abre1: "00",
    cierra1: "00",
    dia: "Monday",
    dia_para_otro: "0",
    atiende: "1",
    fila_id: props.id,
  });
  const [tuesday, setTuesday] = useState({
    abre: "08",
    cierra: "20",
    abre1: "00",
    cierra1: "00",
    dia: "Tuesday",
    dia_para_otro: "0",
    atiende: "1",
    fila_id: props.id,
  });
  const [wednesday, setWednesday] = useState({
    abre: "08",
    cierra: "20",
    abre1: "00",
    cierra1: "00",
    dia: "Wednesday",
    dia_para_otro: "0",
    atiende: "1",
    fila_id: props.id,
  });
  const [thursday, setThursday] = useState({
    abre: "08",
    cierra: "20",
    abre1: "00",
    cierra1: "00",
    dia: "Thursday",
    dia_para_otro: "0",
    atiende: "1",
    fila_id: props.id,
  });
  const [friday, setFriday] = useState({
    abre: "08",
    cierra: "20",
    abre1: "00",
    cierra1: "00",
    dia: "Friday",
    dia_para_otro: "0",
    atiende: "1",
    fila_id: props.id,
  });
  const [saturday, setSaturday] = useState({
    abre: "08",
    cierra: "20",
    abre1: "00",
    cierra1: "00",
    dia: "Saturday",
    dia_para_otro: "0",
    atiende: "0",
    fila_id: props.id,
  });
  const [sunday, setSunday] = useState({
    abre: "08",
    cierra: "20",
    abre1: "00",
    cierra1: "00",
    dia: "Sunday",
    dia_para_otro: "0",
    atiende: "0",
    fila_id: props.id,
  });

  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );
  const [endDate, setEndDate] = useState(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );

  const stateNewJornada = useSelector((state) => state.addJornada);
  const dispatch = useDispatch();
  const empresa_id = myIds[1];
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getJornadaHoliday({
          loading: true,
          empresa_id: empresa_id,
          name: name,
          desde: moment(new Date().setDate(new Date().getDate() + 1)).format(
            "DD-MM-YYYY"
          ),
          hasta: moment(
            new Date().setFullYear(new Date().getFullYear() + 1)
          ).format("DD-MM-YYYY"),
          abre: "09:00",
          cierra: "18:00",
        })
      );
    };
    callsAPI();
  }, [dispatch, form, empresa_id, name]);

  useEffect(() => {
    return () => {
      dispatch(resetJornadaHoliday());
      dispatch(resetNewJornada());
    };
  }, [dispatch]);

  const submitDate = async (set, date, type) => {
    setHoliday([]);
    dispatch(resetJornadaHoliday());
    set(date);
    dispatch(
      getJornadaHoliday({
        name: name,
        empresa_id: myIds[1],
        loading: false,
        desde: moment(type === "startDate" ? date : startDate).format(
          "DD-MM-YYYY"
        ),
        hasta: moment(type === "endDate" ? date : endDate).format("DD-MM-YYYY"),
        abre: "09:00",
        cierra: "18:00",
      })
    );
  };
  const handleChangeMonday = (event) => {
    event.persist();
    setMonday((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };
  const handleChangeTuesday = (event) => {
    event.persist();
    setTuesday((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleChangeWednesday = (event) => {
    event.persist();
    setWednesday((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleChangeThursday = (event) => {
    event.persist();
    setThursday((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleChangeFriday = (event) => {
    event.persist();
    setFriday((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleChangeSaturday = (event) => {
    event.persist();
    setSaturday((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleChangeSunday = (event) => {
    event.persist();
    setSunday((prev) => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };
  const handleChangeHoliday = (name, value, i) => {
    holiday[i][name] = value;
    setHoliday(holiday);
    setCount(count + 1);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    const obj = {};
    obj.empresa_id = myIds[1];
    obj.name = name;
    obj.desde = moment(startDate).format("DD-MM-YYYY");
    obj.hasta = moment(endDate).format("DD-MM-YYYY");
    obj.horario = [
      {
        abre: `${monday.abre}:${monday.abre1}`,
        cierra: `${monday.cierra}:${monday.cierra1}`,
        dia: {
          id: 1,
          nombre: "Lunes",
        },
        dia_para_otro: monday.dia_para_otro === "1" ? true : false,
        atiende: monday.atiende === "1" ? true : false,
        fila_id: id,
      },
      {
        abre: `${tuesday.abre}:${tuesday.abre1}`,
        cierra: `${tuesday.cierra}:${tuesday.cierra1}`,
        dia: {
          id: 2,
          nombre: "Martes",
        },
        dia_para_otro: tuesday.dia_para_otro === "1" ? true : false,
        atiende: tuesday.atiende === "1" ? true : false,
        fila_id: id,
      },
      {
        abre: `${wednesday.abre}:${wednesday.abre1}`,
        cierra: `${wednesday.cierra}:${wednesday.cierra1}`,
        dia: {
          id: 3,
          nombre: "Miercoles",
        },
        dia_para_otro: wednesday.dia_para_otro === "1" ? true : false,
        atiende: wednesday.atiende === "1" ? true : false,
        fila_id: id,
      },
      {
        abre: `${thursday.abre}:${thursday.abre1}`,
        cierra: `${thursday.cierra}:${thursday.cierra1}`,
        dia: {
          id: 4,
          nombre: "Jueves",
        },
        dia_para_otro: thursday.dia_para_otro === "1" ? true : false,
        atiende: thursday.atiende === "1" ? true : false,
        fila_id: id,
      },
      {
        abre: `${friday.abre}:${friday.abre1}`,
        cierra: `${friday.cierra}:${friday.cierra1}`,
        dia: {
          id: 5,
          nombre: "Viernes",
        },
        dia_para_otro: friday.dia_para_otro === "1" ? true : false,
        atiende: friday.atiende === "1" ? true : false,
        fila_id: id,
      },
      {
        abre: `${saturday.abre}:${saturday.abre1}`,
        cierra: `${saturday.cierra}:${saturday.cierra1}`,
        dia: {
          id: 6,
          nombre: "Sabado",
        },
        dia_para_otro: saturday.dia_para_otro === "1" ? true : false,
        atiende: saturday.atiende === "1" ? true : false,
        fila_id: id,
      },
      {
        abre: `${sunday.abre}:${sunday.abre1}`,
        cierra: `${sunday.cierra}:${sunday.cierra1}`,
        dia: {
          id: 0,
          nombre: "Domingo",
        },
        dia_para_otro: sunday.dia_para_otro === "1" ? true : false,
        atiende: sunday.atiende === "1" ? true : false,
        fila_id: id,
      },
    ];

    const arrHoliday = [];
    holiday.forEach((item) =>
      arrHoliday.push({
        fecha: item.fecha,
        nombre: item.nombre,
        abre: `${item.abre}:${item.abre1}`,
        cierra: `${item.cierra}:${item.cierra1}`,
        dia_para_otro: item.dia_para_otro === "1" ? true : false,
        atiende: item.atiende === "1" ? true : false,
        fila_id: item.fila_id,
      })
    );
    obj.feriados = arrHoliday;

    setModalMessage(true);
    dispatch(postNewJornada(obj));
  };

  const resetForm = () => {
    dispatch(resetNewJornada());
  };

  if (stateNewJornada.loadingJornadaHoliday) {
    return <Loading />;
  } else if (stateNewJornada.statusJornadaHoliday === 501) {
    return <ModalTimeOut show={true} />;
  }

  if (holiday.length === 0 && stateNewJornada.statusJornadaHoliday === 200) {
    if (!stateNewJornada.dataJornadaHoliday.datos.length > 0) {
      if (stateNewJornada.dataJornadaHoliday.datos.feriados.length > 0) {
        stateNewJornada.dataJornadaHoliday.datos.feriados[0].feriados.map(
          (item) => {
            const arrAbre = item.abre.split(":");
            const arrCierra = item.cierra.split(":");
            const objHoliday = {
              fecha: item.fecha,
              nombre: item.nombre,
              abre: arrAbre[0],
              abre1: arrAbre[1],
              cierra: arrCierra[0],
              cierra1: arrCierra[1],
              dia_para_otro: item.dia_para_otro ? "1" : "0",
              atiende: item.atiende ? "1" : "0",
              fila_id: item.fila_id,
            };
            return holiday.push(objHoliday);
          }
        );
      }
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      {stateNewJornada.statusNewJornada === 200 &&
        !stateNewJornada.dataNewJornada.ejecucion.estado && (
          <ModalMessage
            message={stateNewJornada.dataNewJornada.ejecucion.mensaje}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={stateNewJornada.loadingNewJornada}
          />
        )}

      {stateNewJornada.statusNewJornada === 200 &&
        stateNewJornada.dataNewJornada.ejecucion.estado && (
          <ModalMessage
            message={<FormattedMessage id="SuccessfullyAdded" />}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={stateNewJornada.loadingNewJornada}
          />
        )}
      <section className="containerForm">
        <div className="containerFormTitle">
          <h1>Datos jornada </h1>
        </div>
        <div className="containerFormBox">
          <Form.Group controlId="code">
            <Form.Label>
              <FormattedMessage id="DateStart" />
            </Form.Label>
            <DatePicker
              className="dateInput"
              dateFormat="dd-MM-yyyy"
              selected={startDate}
              onChange={(date) => submitDate(setStartDate, date, "startDate")}
            />
          </Form.Group>
        </div>
        <div className="containerFormBox">
          <Form.Group controlId="code">
            <Form.Label>
              <FormattedMessage id="DateEnd" />
            </Form.Label>
            <DatePicker
              dateFormat="dd-MM-yyyy"
              className="dateInput"
              selected={endDate}
              onChange={(date) => submitDate(setEndDate, date, "endDate")}
            />
          </Form.Group>
        </div>
      </section>
      <MondaySection day={monday} handleChange={handleChangeMonday} />
      <TuesdaySection day={tuesday} handleChange={handleChangeTuesday} />
      <WednesdaySection day={wednesday} handleChange={handleChangeWednesday} />
      <ThursdaySection day={thursday} handleChange={handleChangeThursday} />
      <FridaySection day={friday} handleChange={handleChangeFriday} />
      <SaturdaySection day={saturday} handleChange={handleChangeSaturday} />
      <SundaySection day={sunday} handleChange={handleChangeSunday} />
      {holiday.map((item, i) => {
        return (
          <HolidaySection
            key={i}
            id={i}
            day={item}
            handleChange={handleChangeHoliday}
          />
        );
      })}
      <section className="containerForm">
        <div className="containerFormButton">
          <div>
            <Button
              disabled={props.loadingNewJornada}
              variant="warning"
              type="submit"
            >
              {stateNewJornada.loadingNewJornada ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Update" />
              )}
            </Button>
          </div>
        </div>
      </section>
    </Form>
  );
};
FormComponent.propTypes = {};

export default FormComponent;
