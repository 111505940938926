import React from "react";
import Groups from "../../component/home/groups";

const GroupPage = () => {
  return (
    <section>
      <Groups />
    </section>
  );
};

export default GroupPage;
