import React from "react";
import add from "../../assets/img/add.svg";
import { Link } from "react-router-dom";

const Add = () => {
  return (
    <Link to={`/dashboard/add_company`}>
      <div className="containerAdd">
        <img alt="icon" className="imgAdd" src={add} />
      </div>
    </Link>
  );
};

export default Add;
