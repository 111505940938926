import React from "react";
import wave1 from "../../assets/img/wave1.svg";
import wave2 from "../../assets/img/wave2.svg";
import "../../assets/style/login.scss";
const Waves = () => {
  return (
    <div className="containerWaves">
      <img src={wave1} alt="wave" className="wave1" />
      <img src={wave2} alt="wave" className="wave2" />
    </div>
  );
};

export default Waves;
