import {
  ADD_AVATARS_DIVISION_INIT,
  ADD_AVATARS_DIVISION_SUCCESS,
  ADD_AVATARS_DIVISION_FAILURE,
  RESET_ADD_AVATARS_DIVISION,
} from "./types";
import API from "./api";

/* Actions Creators */

export function resetAddAvatarDivision() {
  return {
    type: RESET_ADD_AVATARS_DIVISION,
  };
}

/* Get AddAvatarDivision (Async) */

function addAvatarDivisionSuccess(data) {
  return {
    type: ADD_AVATARS_DIVISION_SUCCESS,
    payload: data,
  };
}

function addAvatarDivisionFailure(error) {
  console.log(error);
  return {
    type: ADD_AVATARS_DIVISION_FAILURE,
    payload: error,
  };
}

function addAvatarDivisionInit() {
  return {
    type: ADD_AVATARS_DIVISION_INIT,
  };
}

export function addAvatarDivision(data) {
  return async (dispatch) => {
    dispatch(addAvatarDivisionInit());
    try {
      const resp = await API.data.addAvatars(data);
      return dispatch(addAvatarDivisionSuccess(resp));
    } catch (error) {
      return dispatch(addAvatarDivisionFailure(error));
    }
  };
}
