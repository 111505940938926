import initialState from "./initialState";
import {
  FETCH_UPLOAD_USEREXCEL_INIT,
  FETCH_UPLOAD_USEREXCEL_SUCCESS,
  FETCH_UPLOAD_USEREXCEL_FAILURE,
  RESET_FORM_USEREXCEL,
} from "../../actions/load_usuario/types";

export default function addUserExcel(state = initialState, action) {
  switch (action.type) {
    case RESET_FORM_USEREXCEL:
      return {
        ...state,
        loading: false,
        status: null,
        data: {},
      };
    case FETCH_UPLOAD_USEREXCEL_INIT:
      return {
        ...state,
        loading: true,
      };
    case FETCH_UPLOAD_USEREXCEL_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case FETCH_UPLOAD_USEREXCEL_FAILURE:
      return {
        ...state,
        loading: false,
        status: 500,
      };

    default:
      return state;
  }
}
