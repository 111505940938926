import {
  ADD_ALTERNATIVA_INIT,
  ADD_ALTERNATIVA_SUCCESS,
  ADD_ALTERNATIVA_FAILURE,
  RESET_ADD_ALTERNATIVA,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddAlternativa() {
  return {
    type: RESET_ADD_ALTERNATIVA,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addAlternativaSuccess(data) {
  return {
    type: ADD_ALTERNATIVA_SUCCESS,
    payload: data,
  };
}

function addAlternativaFailure(error) {
  return {
    type: ADD_ALTERNATIVA_FAILURE,
    payload: error,
  };
}

function addAlternativaInit() {
  return {
    type: ADD_ALTERNATIVA_INIT,
  };
}

export function addAlternativa(data) {
  return async (dispatch) => {
    dispatch(addAlternativaInit());
    try {
      const resp = await API.data.addAlternativa(data);
      return dispatch(addAlternativaSuccess(resp));
    } catch (error) {
      return dispatch(addAlternativaFailure(error));
    }
  };
}
