import axios from "axios";

/* Get Companies */
export const GetCompanies = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_COMPANIES_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/companies/${encodeURIComponent(JSON.stringify(obj))}`
    );
    let data = await response.data;
    dispatch({
      type: "GET_COMPANIES_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_COMPANIES_FAIL",
    });
    console.log("Error en getCompaniesAction: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetCompanies = () => {
  return {
    type: "RESET_COMPANIES",
  };
};

export const DeleteCompany = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_COMPANY_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/companies/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_COMPANY_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_COMPANY_FAIL",
    });
    console.log("Error en delete company: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteCompany = () => {
  return {
    type: "RESET_DELETE_COMPANY",
  };
};

/* Get Companies */
export const GetCompaniesCheck = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_COMPANIES_CHECK_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/check/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_COMPANIES_CHECK_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "GET_COMPANIES_CHECK_FAIL",
    });
    console.log(
      "Error en getCompaniesCheckAction: veni a ver el desmadre",
      err
    );
  }
};

/* Reset CompaniesCheck */
export const resetCompaniesCheck = () => {
  return {
    type: "RESET_COMPANIES_CHECK",
  };
};

/* Get Companies */
export const GetCompaniesBlock = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_COMPANIES_BLOCK_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/check/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_COMPANIES_BLOCK_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "GET_COMPANIES_BLOCK_FAIL",
    });
    console.log(
      "Error en getCompaniesBlockAction: veni a ver el desmadre",
      err
    );
  }
};

/* Reset CompaniesBlock */
export const resetCompaniesBlock = () => {
  return {
    type: "RESET_COMPANIES_BLOCK",
  };
};

/* Get Companies */
export const GetCompaniesActive = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_COMPANIES_ACTIVE_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/check/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_COMPANIES_ACTIVE_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "GET_COMPANIES_ACTIVE_FAIL",
    });
    console.log(
      "Error en getCompaniesActiveAction: veni a ver el desmadre",
      err
    );
  }
};

/* Reset CompaniesActive */
export const resetCompaniesActive = () => {
  return {
    type: "RESET_COMPANIES_ACTIVE",
  };
};
