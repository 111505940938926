import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import {
  getDivisionOne,
  resetDivisionOne,
  updateDivision,
  resetUpdateDivision,
} from "../../actions/update_division";

/* Componets */
import FilterNodo from "../common/filterNodo";
import FilterDivisiones from "../common/filterDivisiones";
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateDivision);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    correo: "1",
    aforo: "",
    nodo_id: "",
    edi_division_id_padre: "",
    edificacion_id: "",
    factor_credito: "",
  });

  const [modalMessage, setModalMessage] = useState(false);
  const [nombres, setNombres] = useState([]);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getDivisionOne({
          empresa_id: props.idEmpresa,
          edi_division_id: props.id,
        })
      );
    };
    callsAPI();
  }, [dispatch, props.idEmpresa, props.id]);

  useEffect(() => {
    return () => {
      dispatch(resetDivisionOne());
      dispatch(resetUpdateDivision());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          const list_name = [];
          state.data.datos.nombre.forEach((item, i) => {
            list_name.push({
              value: item.nombre || "",
              index: `name-${i}`,
              valueLabel: item.label || "",
              valueLabelCorto: item.label_corto || "",
              id: item.id,
              label: item.lenguaje,
            });
          });
          setNombres(list_name);

          setForm((prev) => ({
            ...prev,
            id: props.id,
            nodo_id: state.data.datos.nodo_id || "",
            factor_credito: state.data.datos.factor_credito,
            glosa: state.data.datos.clave,
            edificacion_id: state.data.datos.edificacion_id,
            edi_division_id_padre: state.data.datos.edi_division_id_padre || "",
            aforo: state.data.datos.aforo || "",
            correo: state.data.datos.envio_correo_a_buzon ? "1" : "0",
          }));
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Para cambiar los nombres */
  const handleNombres = (event) => {
    event.persist();
    let list_name = [...nombres];
    const index = nombres.findIndex((item) => item.index === event.target.name);
    list_name[index][event.target.dataset.value] = event.target.value;
    setNombres(list_name);
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const nombresArr = [];
    nombres.forEach((item) => {
      nombresArr.push({
        lenguaje_id: item.id,
        nombre: item.value,
        label: item.valueLabel,
        label_corto: item.valueLabelCorto,
      });
    });

    const obj = {
      factor_credito: form.factor_credito,
      envio_correo_a_buzon: form.correo === "1" ? true : false,
      edi_division_id: props.id,
      edificacion_id: parseInt(form.edificacion_id, 10),
      aforo: form.aforo === "" ? null : parseInt(form.aforo, 10),
      nombre: nombresArr,
      nodo_id: form.nodo_id === "" ? null : parseInt(form.nodo_id, 10),
      edi_division_padre_id:
        form.edi_division_id_padre === ""
          ? null
          : parseInt(form.edi_division_id_padre, 10),
    };

    dispatch(updateDivision(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetUpdateDivision());
  };

  if (state.loading) {
    return <Loading />;
  } else if (state.status === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.status === 200) {
    if (!state.data.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos division </h1>
          </div>
          {nombres.map((item) => {
            return (
              <div className="containerFormBox" key={item.id}>
                <Form.Group>
                  <Form.Label>
                    {item.label} <FormattedMessage id="Name" />
                  </Form.Label>
                  <Form.Control
                    value={item.value}
                    onChange={handleNombres}
                    type="text"
                    data-value="value"
                    name={item.index}
                  />
                </Form.Group>
              </div>
            );
          })}
          {nombres.map((item) => {
            return (
              <div className="containerFormBox" key={item.id}>
                <Form.Group>
                  <Form.Label>
                    {item.label} Label <FormattedMessage id="Name" />
                  </Form.Label>
                  <Form.Control
                    value={item.valueLabel}
                    onChange={handleNombres}
                    type="text"
                    data-value="valueLabel"
                    name={item.index}
                  />
                </Form.Group>
              </div>
            );
          })}
          {nombres.map((item) => {
            return (
              <div className="containerFormBox" key={item.id}>
                <Form.Group>
                  <Form.Label>
                    {item.label} Label corto <FormattedMessage id="Name" />
                  </Form.Label>
                  <Form.Control
                    value={item.valueLabelCorto}
                    onChange={handleNombres}
                    type="text"
                    data-value="valueLabelCorto"
                    name={item.index}
                  />
                </Form.Group>
              </div>
            );
          })}

          <FilterNodo
            name="nodo_id"
            form={form}
            handleChange={handleChange}
            id_parent={form.edificacion_id}
          />
          <FilterDivisiones
            name="edi_division_id_padre"
            form={form}
            handleChange={handleChange}
            id_parent={form.edificacion_id}
          />

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Aforo</Form.Label>
              <Form.Control
                value={form.aforo}
                onChange={handleChange}
                type="number"
                name="aforo"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Factor credito</Form.Label>
              <Form.Control
                value={form.factor_credito}
                onChange={handleChange}
                type="number"
                name="factor_credito"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Envio correo</Form.Label>
              <Form.Control
                as="select"
                value={form.correo}
                onChange={handleChange}
                name="correo"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={state.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {state.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {state.statusUpdate === 200 && !state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={state.dataUpdate.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
      {state.statusUpdate === 200 && state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyUpdated" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
