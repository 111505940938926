import React from "react";

/* Components */
import Title from "../../component/update_division_atributos/title";
import Form from "../../component/update_division_atributos/form";

const UpdateDivisionAtributosPage = (props) => {
  const div = props.match.params.id.split("-");
  return (
    <section>
      <Title />
      <Form id={div[0]} idEmpresa={div[1]} arr={props.location.state.arrAtri} />
    </section>
  );
};

export default UpdateDivisionAtributosPage;
