import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { getBolsasRecurso, resetBolsasRecurso } from "../../actions/filters";

/* Language */
import { FormattedMessage } from "react-intl";

const FilterBolsasRecursos = (props) => {
  const state = useSelector((state) => state.filters);
  const dispatch = useDispatch();
  useEffect(() => {
    const callsAPI = async () => {
      if (!props.arr) {
        if (props.id_parent !== "") {
          dispatch(getBolsasRecurso({ empresa_id: props.id_parent }));
        } else {
          dispatch(resetBolsasRecurso());
        }
      }
    };
    callsAPI();
  }, [dispatch, props.id_parent, props.arr]);

  if (props.arr) {
    return (
      <div className="containerFormBox">
        <Form.Group>
          <Form.Label>Bolsas</Form.Label>
          <Form.Control
            as="select"
            value={props.form[props.name]}
            onChange={props.handleChange}
            name={props.name}
            requeried="true"
          >
            <FormattedMessage id="Select">
              {(message) => <option value="">{message}</option>}
            </FormattedMessage>
            {props.arr.map((item) => (
              <option key={item.id} value={item.id}>
                {item.glosa}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      </div>
    );
  }

  return (
    <div className="containerFormBox">
      <Form.Group>
        <Form.Label>Bolsas recursos</Form.Label>
        <Form.Control
          as="select"
          value={props.form[props.name]}
          onChange={props.handleChange}
          name={props.name}
          requeried="true"
        >
          <FormattedMessage id="Select">
            {(message) => <option value="">{message}</option>}
          </FormattedMessage>
          {state.statusBolsasRecurso === 200 &&
            state.dataBolsasRecurso.datos.map((item) => (
              <option key={item.id} value={item.id}>
                {item.glosa}
              </option>
            ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export default FilterBolsasRecursos;
