import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { updateCargo, resetUpdateCargo } from "../../actions/update_cargo";

/* Componets */
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateCargo);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: props.item.nombre,
  });

  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetUpdateCargo());
    };
  }, [dispatch]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      nombre: form.nombre,
      id: props.item.id,
    };

    dispatch(updateCargo(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetUpdateCargo());
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos cargo</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Name" /><span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.nombre}
                onChange={handleChange}
                type="text"
                name="nombre"
              />
            </Form.Group>
          </div>
          <div className="containerFormButton">
            <div>
              <Button
                disabled={state.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {state.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {state.statusUpdate === 200 && !state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={state.dataUpdate.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loading}
        />
      )}
      {state.statusUpdate === 200 && state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyUpdated" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
