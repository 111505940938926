const initialState = {
  data: {},
  loading: true,
  status: 0,
  dataUpdate: {},
  loadingUpdate: false,
  statusUpdate: 0,
};

export default initialState;
