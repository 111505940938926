import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import add from "../../assets/img/add.svg";

/* Actions */
import {
  GetRecursos,
  resetRecursos,
  DeleteRecurso,
  resetDeleteRecurso,
} from "../../actions/recursos/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";

const Recursos = (props) => {
  const dispatch = useDispatch();

  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);

  /* States */
  const getRecursos = useSelector((state) => state.GetRecursos);

  useEffect(() => {
    const FetchData = () => {
      const myIds = props.match.params.id.split("-");
      dispatch(GetRecursos({ bolsa_recurso_id: parseInt(myIds[0], 10) }));
    };
    FetchData();
  }, [dispatch, props.match.params.id]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetRecursos());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };

  /* Delete entidad */
  const submitDelete = () => {
    dispatch(DeleteRecurso({ recurso_id: idDelete }));
  };

  /* Reset el estado de delete*/
  const submitResetDelete = () => {
    dispatch(resetDeleteRecurso());
    if (getRecursos.statusDelete === 200) {
      if (getRecursos.dataDelete.ejecucion.estado) {
        const myIds = props.match.params.id.split("-");
        dispatch(GetRecursos({ bolsa_recurso_id: parseInt(myIds[0], 10) }));
      }
    }
  };
  const myIds1 = props.match.params.id.split("-");
  const showRecursos = () => {
    if (getRecursos.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getRecursos.data)) {
      return (
        <div className="containerAllEmpresas">
          {getRecursos.data.map((item, key) => {
            return (
              <div className="eachEmpresa" key={key}>
                <div className="leftSideEachEmpresa">
                  <p className="containerTable">{item.id}</p>
                  <p className="containerTable">{item.clave}</p>
                  <Link
                    className="containerTable"
                    to={{
                      pathname: `/dashboard/update_recurso/${item.id}`,
                      state: {
                        items: item,
                      },
                    }}
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                  <div className="containerTable">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li>
                            <Link
                              to={`/dashboard/accesos_recursos/${
                                item.id
                              }-${1}-${myIds1[1]}`}
                            >
                              Acceso all
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/dashboard/accesos_recursos/${
                                item.id
                              }-${0}-${myIds1[1]}`}
                            >
                              Acceso
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleModal({ id: item.id });
                  }}
                  className="rightSideEachEmpresa"
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getRecursos.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <div className="containerEmpresa">
        <ModalDelete
          func={() => {
            submitDelete();
          }}
          state={getRecursos}
          modal={modal}
          reset={submitResetDelete}
          setModal={setModal}
        />
        <p className="titleEmpresas">Recursos</p>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideEachEmpresa">
            <p className="containerTable">ID</p>
            <p className="containerTable">Nombre</p>
            <p className="containerTable">Editar</p>
            <p className="containerTable">Más</p>
          </div>
          <div className="rightSideEachEmpresa">
            <p>Eliminar</p>
          </div>
        </div>
        {showRecursos()}
      </div>
      <Link to={`/dashboard/add_recurso/${props.match.params.id}`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Recursos;
