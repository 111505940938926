import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import add from "../../assets/img/add.svg";

/* Actions */
import {
  GetAtributos,
  resetAtributos,
  DeleteAtributo,
  resetDeleteAtributo,
} from "../../actions/atributos/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";

const Atributos = (props) => {
  const dispatch = useDispatch();

  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);

  /* States */
  const getAtributos = useSelector((state) => state.GetAtributos);

  useEffect(() => {
    const FetchData = () => {
      dispatch(
        GetAtributos({ bolsa_atributo_id: parseInt(props.match.params.id, 10) })
      );
    };
    FetchData();
  }, [dispatch, props.match.params.id]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetAtributos());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };

  /* Delete entidad */
  const submitDelete = () => {
    dispatch(DeleteAtributo({ atributo_id: idDelete }));
  };

  /* Reset el estado de delete*/
  const submitResetDelete = () => {
    dispatch(resetDeleteAtributo());
    if (getAtributos.statusDelete === 200) {
      if (getAtributos.dataDelete.ejecucion.estado) {
        dispatch(
          GetAtributos({
            bolsa_atributo_id: parseInt(props.match.params.id, 10),
          })
        );
      }
    }
  };

  const showAtributos = () => {
    if (getAtributos.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getAtributos.data)) {
      return (
        <div className="containerAllEmpresas">
          {getAtributos.data.map((item, key) => {
            return (
              <div className="eachEmpresa" key={key}>
                <div className="leftSideEachEmpresa">
                  <p className="containerTable">{item.id}</p>
                  <p className="containerTable">{item.clave}</p>
                  <p className="containerTable">{item.numero_seleccion}</p>
                  <Link
                    className="containerTable"
                    to={{
                      pathname: `/dashboard/update_atributo/${item.id}`,
                      state: {
                        items: item,
                      },
                    }}
                  >
                    <i>iEditar</i>
                  </Link>
                  <div className="containerTable">
                    <span className="containerAction">
                      Action
                      <div>
                        <ul></ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleModal({ id: item.id });
                  }}
                  className="rightSideEachEmpresa"
                >
                  <i>Trash</i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getAtributos.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <div className="containerEmpresa">
        <ModalDelete
          func={() => {
            submitDelete();
          }}
          state={getAtributos}
          modal={modal}
          reset={submitResetDelete}
          setModal={setModal}
        />
        <p className="titleEmpresas">Atributos</p>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideEachEmpresa">
            <p className="containerTable">ID</p>
            <p className="containerTable">Nombre</p>
            <p className="containerTable">Número</p>
            <p className="containerTable">Editar</p>
            <p className="containerTable">Más</p>
          </div>
          <div className="rightSideEachEmpresa">
            <p>Eliminar</p>
          </div>
        </div>
        {showAtributos()}
      </div>
      <Link to={`/dashboard/add_atributo/${props.match.params.id}`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Atributos;
