import React from 'react'

const Title = () => {
    return (
        <div className="containerEmpresa">
        <p className="titleEmpresas">Nuevo grupo</p>
        <div className="lineGray"></div>
      </div>
    )
}

export default Title
