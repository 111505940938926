import {
  GET_VENTANA_INIT,
  GET_VENTANA_SUCCESS,
  GET_VENTANA_FAILURE,
  RESET_VENTANA,
  UPDATE_VENTANA_INIT,
  UPDATE_VENTANA_SUCCESS,
  UPDATE_VENTANA_FAILURE,
  RESET_UPDATE_VENTANA,
  UPDATE_VENTANA_USUARIO_INIT,
  UPDATE_VENTANA_USUARIO_SUCCESS,
  UPDATE_VENTANA_USUARIO_FAILURE,
  RESET_UPDATE_VENTANA_USUARIO,
} from "./types";
import API from "./api";

export function resetVentana() {
  return {
    type: RESET_VENTANA,
  };
}

function getVentanaSuccess(data) {
  return {
    type: GET_VENTANA_SUCCESS,
    payload: data,
  };
}

function getVentanaFailure(error) {
  console.log(error);
  return {
    type: GET_VENTANA_FAILURE,
    payload: error,
  };
}

function getVentanaInit(data) {
  return {
    type: GET_VENTANA_INIT,
    payload: data.loading,
  };
}

export function getVentana(data) {
  return async (dispatch) => {
    dispatch(getVentanaInit(data));
    try {
      const resp = await API.data.getVentana(data);
      return dispatch(getVentanaSuccess(resp));
    } catch (error) {
      return dispatch(getVentanaFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateVentana() {
  return {
    type: RESET_UPDATE_VENTANA,
  };
}

function updateVentanaSuccess(data) {
  return {
    type: UPDATE_VENTANA_SUCCESS,
    payload: data,
  };
}

function updateVentanaFailure(error) {
  return {
    type: UPDATE_VENTANA_FAILURE,
    payload: error,
  };
}

function updateVentanaInit() {
  return {
    type: UPDATE_VENTANA_INIT,
  };
}

export function updateVentana(data) {
  return async (dispatch) => {
    dispatch(updateVentanaInit());
    try {
      const resp = await API.data.updateVentana(data);
      return dispatch(updateVentanaSuccess(resp));
    } catch (error) {
      return dispatch(updateVentanaFailure(error));
    }
  };
}

/* Update usuario ventena  */

export function resetUpdateVentanaUsuario() {
  return {
    type: RESET_UPDATE_VENTANA_USUARIO,
  };
}

function updateVentanaUsuarioSuccess(data) {
  return {
    type: UPDATE_VENTANA_USUARIO_SUCCESS,
    payload: data,
  };
}

function updateVentanaUsuarioFailure(error) {
  return {
    type: UPDATE_VENTANA_USUARIO_FAILURE,
    payload: error,
  };
}

function updateVentanaUsuarioInit() {
  return {
    type: UPDATE_VENTANA_USUARIO_INIT,
  };
}

export function updateVentanaUsuario(data) {
  return async (dispatch) => {
    dispatch(updateVentanaUsuarioInit());
    try {
      const resp = await API.data.updateVentanaUsuario(data);
      return dispatch(updateVentanaUsuarioSuccess(resp));
    } catch (error) {
      return dispatch(updateVentanaUsuarioFailure(error));
    }
  };
}
