import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

// Actions
import {
  GetGranularity,
  ResetGranularity,
} from "../../actions/granularidad/api";

// Components
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import FilterCompany from "../common/filterCompany";

const Granularity = () => {
  const dispatch = useDispatch();
  // States de filtro
  const [form, setForm] = useState({
    id: "",
  });

  const getGranularity = useSelector((state) => state.GetGranularity);

  useEffect(() => {
    const FetchData = () => {};
    FetchData();
  }, [dispatch]);
  useEffect(() => {
    return () => {
      dispatch(ResetGranularity());
    };
  }, [dispatch]);
  //   func del filtro
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    if (event.target.value !== "") {
      dispatch(
        GetGranularity({ empresa_id: parseInt(event.target.value, 10) })
      );
    } else {
      dispatch(ResetGranularity());
    }
  };
  const showGranu = () => {
    if (getGranularity.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getGranularity.data)) {
      return (
        <div className="containerAllGranularidad">
          {getGranularity.data.map((item, key) => {
            return (
              <div className="eachGranularidad" key={key}>
                <div className="leftSideGranularidad">
                  <p className="containerTableID">{item.granularidad_id}</p>
                  <p className="containerTable">{item.nombre}</p>
                  <Link
                    className="containerTable"
                    to={{
                      pathname: `/dashboard/update_granularity/${item.granularidad_id}-${form.id}`,
                      state: {
                        items: item,
                      },
                    }}
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                  <div className="containerTable">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li>granularidad</li>
                          <li>granularidad</li>
                          <li>granularidad</li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getGranularity.errorMsg !== "") {
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <div className="containerGranularidad">
        <p className="titleGranularidad">Granularidad</p>
        <FilterCompany name="id" form={form} handleChange={handleChange} />
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideGranularidad">
            <p className="containerTableID">ID</p>
            <p className="containerTable">Nombre</p>
            <p className="containerTable">Editar</p>
            <p className="containerTable">Más</p>
          </div>
        </div>
        {showGranu()}
      </div>
    </React.Fragment>
  );
};

export default Granularity;
