import {
  GET_DESEABLES_BOLSA_INIT,
  GET_DESEABLES_BOLSA_SUCCESS,
  GET_DESEABLES_BOLSA_FAILURE,
  RESET_DESEABLES_BOLSA,
  UPDATE_DESEABLE_BOLSA_INIT,
  UPDATE_DESEABLE_BOLSA_SUCCESS,
  UPDATE_DESEABLE_BOLSA_FAILURE,
  RESET_UPDATE_DESEABLE_BOLSA,
} from "./types";
import API from "./api";

export function resetDeseablesBolsa() {
  return {
    type: RESET_DESEABLES_BOLSA,
  };
}

function getDeseablesBolsaSuccess(data) {
  return {
    type: GET_DESEABLES_BOLSA_SUCCESS,
    payload: data,
  };
}

function getDeseablesBolsaFailure(error) {
  console.log(error);
  return {
    type: GET_DESEABLES_BOLSA_FAILURE,
    payload: error,
  };
}

function getDeseablesBolsaInit(data) {
  return {
    type: GET_DESEABLES_BOLSA_INIT,
    payload: data.loading,
  };
}

export function getDeseablesBolsa(data) {
  return async (dispatch) => {
    dispatch(getDeseablesBolsaInit(data));
    try {
      const resp = await API.data.getDeseables(data);
      return dispatch(getDeseablesBolsaSuccess(resp));
    } catch (error) {
      return dispatch(getDeseablesBolsaFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateDeseableBolsa() {
  return {
    type: RESET_UPDATE_DESEABLE_BOLSA,
  };
}

function updateDeseableBolsaSuccess(data) {
  return {
    type: UPDATE_DESEABLE_BOLSA_SUCCESS,
    payload: data,
  };
}

function updateDeseableBolsaFailure(error) {
  return {
    type: UPDATE_DESEABLE_BOLSA_FAILURE,
    payload: error,
  };
}

function updateDeseableBolsaInit() {
  return {
    type: UPDATE_DESEABLE_BOLSA_INIT,
  };
}

export function updateDeseableBolsa(data) {
  return async (dispatch) => {
    dispatch(updateDeseableBolsaInit());
    try {
      const resp = await API.data.updateDeseable(data);
      return dispatch(updateDeseableBolsaSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(updateDeseableBolsaFailure(error));
    }
  };
}
