import initialState from "./initialState";
import {
  POST_DELETE_JORNADA_INIT,
  POST_DELETE_JORNADA_SUCCESS,
  POST_DELETE_JORNADA_FAILURE,
  RESET_DELETE_JORNADA,
} from "../../actions/delete_jornada/types";

export default function home(state = initialState, action) {
  switch (action.type) {
    /* Setting */

    case RESET_DELETE_JORNADA:
      return {
        ...state,
        dataDeleteJornada: [],
        loadingDeleteJornada: false,
        statusDeleteJornada: 0,
      };
    case POST_DELETE_JORNADA_INIT:
      return {
        ...state,
        loadingDeleteJornada: true,
      };
    case POST_DELETE_JORNADA_SUCCESS:
      return {
        ...state,
        dataDeleteJornada: action.payload.data,
        loadingDeleteJornada: false,
        statusDeleteJornada: action.payload.status,
      };
    case POST_DELETE_JORNADA_FAILURE:
      return {
        ...state,
        dataDeleteJornada: {},
        loadingDeleteJornada: false,
        statusDeleteJornada: 501,
      };
    default:
      return state;
  }
}
