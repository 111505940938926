import React from "react";
import Empresa from "./empresa";
import Add from "./add";

const DashBoard = () => {
  return (
    <section>
      <Empresa />
      <Add />
    </section>
  );
};

export default DashBoard;
