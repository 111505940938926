import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { getGroup } from "../../actions/filters";

/* Group */
import { FormattedMessage } from "react-intl";

const FilterGroup = (props) => {
  const state = useSelector((state) => state.filters);
  const dispatch = useDispatch();

  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getGroup({ loading: false }));
    };
    callsAPI();
  }, [dispatch]);

  return (
    <div className="containerFormBox">
      <Form.Group>
        <Form.Label>
          <FormattedMessage id="Groups" />
        </Form.Label>
        <Form.Control
          as="select"
          value={props.form[props.name]}
          onChange={props.handleChange}
          name={props.name}
          requeried="true"
        >
          <FormattedMessage id="Select">
            {(message) => <option value="">{message}</option>}
          </FormattedMessage>
          {state.statusGroup === 200 &&
            state.dataGroup.datos.map((item) => (
              <option key={item.id} value={item.id}>
                {item.nombre}
              </option>
            ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export default FilterGroup;
