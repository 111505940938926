import {
  FETCH_UPLOAD_USEREXCEL_INIT,
  FETCH_UPLOAD_USEREXCEL_SUCCESS,
  FETCH_UPLOAD_USEREXCEL_FAILURE,
  RESET_FORM_USEREXCEL,
} from "./types";
import API from "./api";

export function resetFormUserExcel() {
  return {
    type: RESET_FORM_USEREXCEL,
  };
}

function fetchUploadApiSuccess(data) {
  return {
    type: FETCH_UPLOAD_USEREXCEL_SUCCESS,
    payload: data,
  };
}

function fetchUploadApiFailure(error) {
  console.log(error);
  return {
    type: FETCH_UPLOAD_USEREXCEL_FAILURE,
    payload: error,
  };
}

function fetchUploadApiInit() {
  return {
    type: FETCH_UPLOAD_USEREXCEL_INIT,
  };
}

export function fetchUserUploadApi(obj) {
  return async (dispatch) => {
    dispatch(fetchUploadApiInit());
    try {
      const data = await API.data.handleUserUpload(obj);
      dispatch(fetchUploadApiSuccess(data));
    } catch (error) {
      dispatch(fetchUploadApiFailure(error));
    }
  };
}
