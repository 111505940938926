import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import {
  getNodo,
  resetNodo,
  updateNodo,
  resetUpdateNodo,
} from "../../actions/update_nodo";

import { getLabels, resetLabels } from "../../actions/filters";

/* Componets */
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateNodo);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    glosa: "",
  });

  const [modalMessage, setModalMessage] = useState(false);
  const [nombres, setNombres] = useState([]);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getNodo({ nodo_id: props.id }));
      dispatch(getLabels({ loading: true }));
    };
    callsAPI();
  }, [dispatch, props.id]);

  useEffect(() => {
    return () => {
      dispatch(resetLabels());
      dispatch(resetNodo());
      dispatch(resetUpdateNodo());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          const list_name = [];
          state.data.datos.nombre.forEach((item, i) => {
            list_name.push({
              value: item.nombre || "",
              index: `name-${i}`,
              id: item.id,
              label: item.lenguaje,
            });
          });
          setNombres(list_name);
          setForm((prev) => ({
            ...prev,
            id: props.id,
            glosa: state.data.datos.clave,
          }));
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Para cambiar los nombres */
  const handleNombres = (event) => {
    event.persist();
    let list_name = [...nombres];
    const index = nombres.findIndex((item) => item.index === event.target.name);
    list_name[index].value = event.target.value;
    setNombres(list_name);
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const nombresArr = [];
    nombres.forEach((item) => {
      nombresArr.push({ lenguaje_id: item.id, glosa: item.value });
    });

    const obj = {
      nodo_id: parseInt(props.id, 10),
      clave: form.glosa,
      nombre: nombresArr,
    };

    dispatch(updateNodo(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetUpdateNodo());
  };

  if (state.loading || form.id === "") {
    return <Loading />;
  } else if (state.status === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.status === 200) {
    if (!state.data.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos empresas </h1>
          </div>
          {nombres.map((item) => {
            return (
              <div className="containerFormBox" key={item.id}>
                <Form.Group>
                  <Form.Label>
                    {item.label} <FormattedMessage id="Name" />
                    <span>hola marrano</span>
                  </Form.Label>
                  <Form.Control
                    value={item.value}
                    onChange={handleNombres}
                    type="text"
                    name={item.index}
                  />
                </Form.Group>
              </div>
            );
          })}

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Glosa<span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.glosa}
                onChange={handleChange}
                type="text"
                name="glosa"
              />
            </Form.Group>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={state.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {state.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {state.statusUpdate === 200 && !state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={state.dataUpdate.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
      {state.statusUpdate === 200 && state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyUpdated" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
