const initialState = {
  data: {},
  loading: false,
  errorMsg: "",
  status: 0,
};

const GetGranularityReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_GRANULARITY_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_GET_GRANULARITY":
      return {
        ...state,
        data: {},
        loading: false,
        errorMsg: "",
      };
    case "GET_GRANULARITY_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_GRANULARITY_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "can't bring data",
      };
    default:
      return state;
  }
};

export default GetGranularityReducer;
