const initialState = {
  data: [],
  loading: false,
  errorMsg: "",

  loadingDelete: false,
  statusDelete: 0,
  errorMsgDelete: "",

  loadingAddRoles: false,
  statusAddRoles: 0,
  errorMsgAddRoles: "",
};

const GetUsuariosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USUARIOS_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_USUARIOS":
      return {
        ...state,
        data: [],
        loading: false,
        errorMsg: "",
      };
    case "GET_USUARIOS_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_USUARIOS_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
      };
    /* Delete */
    case "RESET_DELETE_USUARIO":
      return {
        ...state,
        dataDelete: [],
        loadingDelete: false,
        statusDelete: 0,
        errorMsgDelete: "",
      };
    case "DELETE_USUARIO_FAIL":
      return {
        ...state,
        loadingDelete: false,
        statusDelete: 401,
        errorMsgDelete: "cant bring data",
      };
    case "DELETE_USUARIO_LOADING":
      return {
        ...state,
        loadingDelete: true,
        errorMsgDelete: "",
      };
    case "DELETE_USUARIO_SUCESS":
      return {
        ...state,
        loadingDelete: false,
        dataDelete: action.payload,
        errorMsgDelete: "",
        statusDelete: action.status,
      };
    /* Add roles */
    case "RESET_ADD_ROLES":
      return {
        ...state,
        dataAddRoles: [],
        loadingAddRoles: false,
        statusAddRoles: 0,
        errorMsgAddRoles: "",
      };
    case "ADD_ROLES_FAIL":
      return {
        ...state,
        loadingAddRoles: false,
        statusAddRoles: 401,
        errorMsgAddRoles: "cant bring data",
      };
    case "ADD_ROLES_LOADING":
      return {
        ...state,
        loadingAddRoles: true,
        errorMsgAddRoles: "",
      };
    case "ADD_ROLES_SUCESS":
      return {
        ...state,
        loadingAddRoles: false,
        dataAddRoles: action.payload,
        errorMsgAddRoles: "",
        statusAddRoles: action.status,
      };
    default:
      return state;
  }
};

export default GetUsuariosReducer;
