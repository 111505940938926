const initialState = {
  dataCountry: {},
  loadingCountry: true,
  statusCountry: 0,
  dataLanguage: {},
  loadingLanguage: true,
  statusLanguage: 0,
  dataTime: {},
  loadingTime: true,
  statusTime: 0,
  dataGroup: {},
  loadingGroup: true,
  statusGroup: 0,
  dataDivision: {},
  loadingDivision: true,
  statusDivision: 0,
  dataSubDivision: {},
  loadingSubDivision: true,
  statusSubDivision: 0,
  dataSubSubDivision: {},
  loadingSubSubDivision: true,
  statusSubSubDivision: 0,
  dataNodos: {},
  loadingNodos: true,
  statusNodos: 0,
  dataBolsas: {},
  loadingBolsas: true,
  statusBolsas: 0,
  dataBolsasDeseable: {},
  loadingBolsasDeseable: true,
  statusBolsasDeseable: 0,
  dataBolsasRecurso: {},
  loadingBolsasRecurso: true,
  statusBolsasRecurso: 0,
  dataDespliegues: {},
  loadingDespliegues: true,
  statusDespliegues: 0,
  dataUser: {},
  loadingUser: true,
  statusUser: 0,
  dataRolesUser: {},
  loadingRolesUser: true,
  statusRolesUser: 0,
  dataAreas: {},
  loadingAreas: true,
  statusAreas: 0,
  dataTiempo: {},
  loadingTiempo: true,
  statusTiempo: 0,
};

export default initialState;
