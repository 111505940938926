import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

/* Actions */
import { GetCompaniesActive } from "../../actions/home/api/index";

function ModalCheck(props) {
  const dispatch = useDispatch();

  const close = () => {
    //props.setModal(false);
    props.func();
  };

  const submitActive = () => {
    dispatch(
      GetCompaniesActive({ query: "activar", empresa_id: props.idEmpresa })
    );
  };

  const reset = () => {
    props.reset();
    props.setModal(false);
  };

  return (
    <>
      <Modal
        size="lg"
        show={props.modal}
        onHide={() => reset()}
        onExit={() => reset()}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Check
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.state.statusCheck === 0 && (
            <div>
              {props.state.loadingCheck ? (
                <h4>
                  <FormattedMessage id="Loading" />
                </h4>
              ) : (
                <h4>
                  <FormattedMessage id="AreYouSure" />
                </h4>
              )}
            </div>
          )}
          {props.state.statusCheck === 200 && props.state.statusActive === 0 && (
            <div>
              {props.state.dataCheck.ejecucion.estado ? (
                <div>
                  <h4>
                    <FormattedMessage id="SuccessfullyCheckd" />
                  </h4>

                  <div className="modal-title1">
                    {props.state.dataCheck.datos.advertencias.descripcion}
                  </div>
                  <div>
                    {props.state.dataCheck.datos.advertencias.arreglo.map(
                      (item, i) => (
                        <p key={i}>{item}</p>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    {props.state.dataCheck.datos.faltantes_detectados.arreglo.map(
                      (item, i) => (
                        <p key={i}>{item}</p>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {props.state.statusCheck === 200 &&
            props.state.statusActive === 200 && (
              <div>
                {props.state.dataCheck.ejecucion.estado ? (
                  <h4>Activado con exito</h4>
                ) : (
                  <p>{props.state.dataActive.ejecucion.mensaje}</p>
                )}
              </div>
            )}
        </Modal.Body>
        {props.state.statusCheck === 0 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button variant="secondary" onClick={close}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
        {props.state.statusCheck === 200 && props.state.statusActive === 0 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Ok" />
            </Button>
            {props.state.dataCheck.ejecucion.estado && (
              <Button variant="secondary" onClick={submitActive}>
                Activar empresa
              </Button>
            )}
          </Modal.Footer>
        )}
        {props.state.statusCheck === 200 && props.state.statusActive === 200 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalCheck;
