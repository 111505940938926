import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import {
  getBolsaRecurso,
  resetBolsaRecurso,
  updateBolsaRecurso,
  resetUpdateBolsaRecurso,
} from "../../actions/update_bolsa_recurso";

import { getLabels, resetLabels } from "../../actions/filters";

/* Componets */
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateBolsaRecurso);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    clave: "",
    glosa: "",
  });

  const [modalMessage, setModalMessage] = useState(false);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getBolsaRecurso({ bolsa_recurso_id: props.id }));
      dispatch(getLabels({ loading: true }));
    };
    callsAPI();
  }, [dispatch, props.id]);

  useEffect(() => {
    return () => {
      dispatch(resetLabels());
      dispatch(resetBolsaRecurso());
      dispatch(resetUpdateBolsaRecurso());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          setForm((prev) => ({
            ...prev,
            id: props.id,
            clave: state.data.datos.clave,
            glosa: state.data.datos.glosa,
          }));
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      bolsa_recurso_id: parseInt(props.id, 10),
      clave: form.clave,
      glosa: form.glosa,
    };

    dispatch(updateBolsaRecurso(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetUpdateBolsaRecurso());
  };

  if (state.loading || form.id === "") {
    return <Loading />;
  } else if (state.status === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.status === 200) {
    if (!state.data.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos empresas </h1>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Clave <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.clave}
                onChange={handleChange}
                type="text"
                name="clave"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Glosa <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.glosa}
                onChange={handleChange}
                type="text"
                name="glosa"
              />
            </Form.Group>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={state.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {state.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {state.statusUpdate === 200 && !state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={state.dataUpdate.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
      {state.statusUpdate === 200 && state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyUpdated" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
