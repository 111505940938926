const initialState = {
  data: {},
  loading: false,
  status: 0,
  dataAdd: {},
  loadingAdd: false,
  statusAdd: 0,
};

export default initialState;
