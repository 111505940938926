import initialState from "./initialState";
import {
  ADD_AVATARS_EDIFICACION_INIT,
  ADD_AVATARS_EDIFICACION_SUCCESS,
  ADD_AVATARS_EDIFICACION_FAILURE,
  RESET_ADD_AVATARS_EDIFICACION,
} from "../../actions/add_edificacion_avatars/types";

export default function avatars(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_ADD_AVATARS_EDIFICACION:
      return {
        ...state,
        dataAddAvatars: {},
        loadingAddAvatars: false,
        statusAddAvatars: 0,
      };
    case ADD_AVATARS_EDIFICACION_INIT:
      return {
        ...state,
        loadingAddAvatars: true,
      };
    case ADD_AVATARS_EDIFICACION_SUCCESS:
      return {
        ...state,
        dataAddAvatars: action.payload.data,
        loadingAddAvatars: false,
        statusAddAvatars: action.payload.status,
      };
    case ADD_AVATARS_EDIFICACION_FAILURE:
      return {
        ...state,
        dataAddAvatars: {},
        loadingAddAvatars: false,
        statusAddAvatars: 501,
      };

    default:
      return state;
  }
}
