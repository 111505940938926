import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import DatePicker from "react-datepicker";

import moment from "moment";

/* Actions */
import {
  GetDevisionesReservas,
  resetDevisionesReservas,
  DeleteReserva,
  resetDeleteReserva,
} from "../../actions/reservas/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalCode from "../common/modalCode";
import ModalDelete from "../common/modalDeleteReserva";

import "react-datepicker/dist/react-datepicker.css";

const Reservas = (props) => {
  const dispatch = useDispatch();
  const [modalCode, setModalCode] = useState(false);

  const [code, setCode] = useState("");
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);

  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 30)
  );

  const [endDate, setEndDate] = useState(
    new Date().setDate(new Date().getDate() + 30)
  );

  /* States */
  const getReservas = useSelector((state) => state.reservas);

  useEffect(() => {
    const FetchData = () => {
      dispatch(
        GetDevisionesReservas({
          edi_division_id: parseInt(props.match.params.id, 10), // este es un nodo padre de hojas
          desde: moment(startDate).format("DD-MM-YYYY"),
          hasta: moment(endDate).format("DD-MM-YYYY"),
        })
      );
    };
    FetchData();
  }, [dispatch, props.match.params.id, startDate, endDate]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetDevisionesReservas());
    };
  }, [dispatch]);

  const submitDate = async (set, date, type) => {
    set(date);
  };

  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };

  const functDelete = () => {
    dispatch(
      DeleteReserva({
        edi_division_id: props.match.params.id, // este es un nodo padre de hojas
        accion: "eliminar",
        arr_reserva_id: [idDelete],
        codigo: parseInt(code, 10),
      })
    );
  };

  // reset de la funcion matar
  const resetDelete = () => {
    dispatch(resetDeleteReserva());
    if (getReservas.statusDelete === 200) {
      if (getReservas.dataDelete.ejecucion.estado) {
        dispatch(
          GetDevisionesReservas({
            edi_division_id: parseInt(props.match.params.id, 10), // este es un nodo padre de hojas
            desde: moment(startDate).format("DD-MM-YYYY"),
            hasta: moment(endDate).format("DD-MM-YYYY"),
          })
        );
      }
    }
  };

  const showReservas = () => {
    if (getReservas.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getReservas.data)) {
      return (
        <div className="containerAllEmpresas">
          <ModalDelete
            func={() => {
              functDelete();
            }}
            state={getReservas}
            modal={modal}
            reset={resetDelete}
            setModal={setModal}
            code={code}
            setCode={setCode}
          />
          {getReservas.data.map((item, key) => {
            return (
              <React.Fragment key={`padre-${key}`}>
                <div className="eachEmpresa">
                  <div className="leftSideEachEmpresa">
                    <p className="containerTable">{item.reserva_id}</p>
                    <p className="containerTable">{item.responsable}</p>
                    <p className="containerTable">{item.responsable}</p>
                    <p className="containerTable">{item.vigente}</p>
                    <p className="containerTable">
                      {moment(
                        item.desde.substr(0, 16),
                        "DD-MM-YYYY HH:mm"
                      ).format("DD-MM-YYYY HH-mm")}
                    </p>
                    <p className="containerTable">
                      {moment(
                        item.hasta.substr(0, 16),
                        "DD-MM-YYYY HH:mm"
                      ).format("DD-MM-YYYY HH-mm")}
                    </p>
                    <div
                      onClick={() => {
                        handleModal({ id: item.reserva_id });
                      }}
                      className="rightSideEachEmpresa"
                    >
                      <div className="containerTableTrash">
                        <i className="far fa-trash-alt"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      );
    } else if (getReservas.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <ModalCode modal={modalCode} setModal={setModalCode} />
      <div className="containerEmpresa">
        <p className="titleEmpresas">Reservas</p>
        <div className="containerFilter">
          <div>
            <h3>Desde</h3>
            <DatePicker
              dateFormat="dd-MM-yyyy"
              className="dateInput"
              selected={startDate}
              onChange={(date) => submitDate(setStartDate, date, "startDate")}
            />
          </div>
          <div>
            <h3>Hasta</h3>
            <DatePicker
              className="dateInput"
              dateFormat="dd-MM-yyyy"
              selected={endDate}
              onChange={(date) => submitDate(setEndDate, date, "endDate")}
            />
          </div>
          <div>
            <button onClick={() => setModalCode(true)}>Obtener código</button>
          </div>
        </div>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideEachEmpresa">
            <p className="containerTable">ID</p>
            <p className="containerTable">Responsable</p>
            <p className="containerTable">Involucrados</p>
            <p className="containerTable">Estado</p>
            <p className="containerTable">Fecha incio</p>
            <p className="containerTable">Fecha termino</p>
            <div className="rightSideEachEmpresa">
              <p>Eliminar</p>
            </div>
          </div>
        </div>
        {showReservas()}
      </div>
    </React.Fragment>
  );
};

export default Reservas;
