import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import FilterBolsas from "./filterBolsas";
import FilterBolsasDeseables from "./filterBolsasDeseable";
import FilterBolsasRecursos from "./filterBolsasRecurso";
import FilterCompany from "./filterCompany";

function ModalBolsas(props) {
  const [form, setForm] = useState({
    id: "",
  });

  const close = () => {
    //props.setModal(false);
    props.func();
  };

  const reset = () => {
    props.reset();
    props.setModal(false);
  };

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  return (
    <>
      <Modal
        show={props.modal}
        onHide={() => reset()}
        onExit={() => reset()}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Asignar bolsa
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.state.statusAsignarBolsas === 0 && (
            <div>
              {props.state.loadingAsignarBolsas ? (
                <h4>
                  <FormattedMessage id="Loading" />
                </h4>
              ) : (
                <div>
                  <FilterCompany
                    labelDark={true}
                    name={"id"}
                    form={form}
                    handleChange={handleChange}
                  />
                  {props.bolsa.type === "atributos" && (
                    <FilterBolsas
                      id_parent={form.id}
                      name="asignarBolsa"
                      form={props.form}
                      handleChange={props.handleChangeAsignar}
                    />
                  )}
                  {props.bolsa.type === "deseables" && (
                    <FilterBolsasDeseables
                      id_parent={form.id}
                      name="asignarBolsa"
                      form={props.form}
                      handleChange={props.handleChangeAsignar}
                    />
                  )}
                  {props.bolsa.type === "recursos" && (
                    <FilterBolsasRecursos
                      id_parent={form.id}
                      name="asignarBolsa"
                      form={props.form}
                      handleChange={props.handleChangeAsignar}
                    />
                  )}
                </div>
              )}
            </div>
          )}
          {props.state.statusAsignarBolsas === 200 && (
            <div>
              {props.state.dataAsignarBolsas.ejecucion.estado ? (
                <h4>Asignada con éxito</h4>
              ) : (
                <p>{props.state.dataAsignarBolsas.ejecucion.mensaje}</p>
              )}
            </div>
          )}
        </Modal.Body>
        {props.state.statusAsignarBolsas === 0 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button variant="secondary" onClick={close}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
        {props.state.statusAsignarBolsas === 200 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalBolsas;
