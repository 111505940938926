import React from "react";
import user from "../../assets/img/userpic.png";

const TopBar = () => {
  return (
    <div className="containerTopBar">
      <div></div>
      <div className="topBarFunct">
        <div className="conatinerImgTopBar">
          <img className="imgTopBar" alt="userPic" src={user} />
        </div>
        <div className="userNameTopBar">
          <p>Kantoor</p>
          <p className="subtitle">Admin</p>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
