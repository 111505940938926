import React from "react";
//import { FormattedMessage } from "react-intl";

/* Components */
import Title from "../../component/accesos/title";
import Content from "../../component/accesos/content";

const Accesos = (props) => {
  const div = props.match.params.id.split("-");

  return (
    <section>
      <Title />
      <Content
        users={props?.location?.state?.users}
        id={div[0]}
        acceso={div[1]}
        user={div[2] ? div[2] : false}
      />
    </section>
  );
};

export default Accesos;
