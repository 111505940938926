import React from "react";

/* Components */
import Title from "../../component/update_ventana/title";
import Form from "../../component/update_ventana/form";

const UpdateVentanaPage = (props) => {
  const ids = props.match.params.id.split("-");
  return (
    <section>
      <Title />
      <Form id={ids[0]} company={ids[1]} />
    </section>
  );
};

export default UpdateVentanaPage;
