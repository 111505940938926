import {
  ADD_BOLSA_DESEABLE_INIT,
  ADD_BOLSA_DESEABLE_SUCCESS,
  ADD_BOLSA_DESEABLE_FAILURE,
  RESET_ADD_BOLSA_DESEABLE,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddBolsaDeseable() {
  return {
    type: RESET_ADD_BOLSA_DESEABLE,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addBolsaDeseableSuccess(data) {
  return {
    type: ADD_BOLSA_DESEABLE_SUCCESS,
    payload: data,
  };
}

function addBolsaDeseableFailure(error) {
  return {
    type: ADD_BOLSA_DESEABLE_FAILURE,
    payload: error,
  };
}

function addBolsaDeseableInit() {
  return {
    type: ADD_BOLSA_DESEABLE_INIT,
  };
}

export function addBolsaDeseable(data) {
  return async (dispatch) => {
    dispatch(addBolsaDeseableInit());
    try {
      const resp = await API.data.addBolsa(data);
      return dispatch(addBolsaDeseableSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(addBolsaDeseableFailure(error));
    }
  };
}
