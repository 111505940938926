import axios from "axios";

/* Get Alternativas*/
export const GetAlternativas = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_ALTERNATIVAS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/alternativas/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_ALTERNATIVAS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_ALTERNATIVAS_FAIL",
    });
    console.log("Error en getAlternativas Action: veni a ver el desmadre", err);
  }
};

/* Reset Alternativas */
export const resetAlternativas = () => {
  return {
    type: "RESET_ALTERNATIVAS",
  };
};

export const DeleteAlternativa = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_ALTERNATIVA_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/alternativas/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_ALTERNATIVA_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_ALTERNATIVA_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteAlternativa = () => {
  return {
    type: "RESET_DELETE_ALTERNATIVA",
  };
};
