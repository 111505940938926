import initialState from "./initialState";
import {
  ADD_PREGUNTA_INIT,
  ADD_PREGUNTA_SUCCESS,
  ADD_PREGUNTA_FAILURE,
  RESET_ADD_PREGUNTA,
} from "../../actions/add_pregunta/types";

export default function pregunta(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_ADD_PREGUNTA:
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case ADD_PREGUNTA_INIT:
      return {
        ...state,
        loading: true,
      };
    case ADD_PREGUNTA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case ADD_PREGUNTA_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    default:
      return state;
  }
}
