import React from "react";
import logo from "../../assets/img/icon.svg";
import { NavLink } from "react-router-dom";

const Menu = ({ logOut }) => {
  return (
    <div className="containerMenu">
      <div className="topSideMenu">
        <div className="containerLogoMenu">
          <img alt="logo" src={logo} className="logoMenu" />
        </div>
        <div className="functionsMenu">
          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/home`}
          >
            <div className="containerIconEachFunct">
              <i className="far fa-building"></i>
            </div>
            <p className="titleEachFunctMenu">Empresas</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/areas`}
          >
            <div className="containerIconEachFunct">
              <i className="fa fa-university" aria-hidden="true"></i>
            </div>
            <p className="titleEachFunctMenu">Areas</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/groups`}
          >
            <div className="containerIconEachFunct">
              <i className="fas fa-layer-group"></i>
            </div>
            <p className="titleEachFunctMenu">Grupos</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/cargos`}
          >
            <div className="containerIconEachFunct">
              <i className="fas fa-hard-hat"></i>
            </div>
            <p className="titleEachFunctMenu">Cargos</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/edificaciones`}
          >
            <div className="containerIconEachFunct">
              <i className="fas fa-city"></i>
            </div>
            <p className="titleEachFunctMenu">Edificaciones</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/bolsas_atributos`}
          >
            <div className="containerIconEachFunct">
              <i className="fas fa-shopping-bag"></i>
            </div>
            <p className="titleEachFunctMenu">Bolsa atributos</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/bolsas_recursos`}
          >
            <div className="containerIconEachFunct">
              <i className="fas fa-briefcase"></i>
            </div>
            <p className="titleEachFunctMenu">Bolsa recursos</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/bolsas_deseables`}
          >
            <div className="containerIconEachFunct">
              <i className="fas fa-suitcase"></i>
            </div>
            <p className="titleEachFunctMenu">Bolsa deseables</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/usuarios`}
          >
            <div className="containerIconEachFunct">
              <i className="fas fa-users"></i>
            </div>
            <p className="titleEachFunctMenu">Usuarios</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/companies_politicas`}
          >
            <div className="containerIconEachFunct">
              <i className="far fa-building"></i>
            </div>
            <p className="titleEachFunctMenu">Empresas Acceso</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/granularity`}
          >
            <div className="containerIconEachFunct">
              <i className="fas fa-project-diagram"></i>
            </div>
            <p className="titleEachFunctMenu">Granularidad</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/creditos`}
          >
            <div className="containerIconEachFunct">
              <i className="fas fa-coins"></i>
            </div>
            <p className="titleEachFunctMenu">Creditos</p>
          </NavLink>

          <NavLink
            activeClassName="eachFunctMenu-active"
            className="eachFunctMenu"
            exact
            to={`/dashboard/ventanas`}
          >
            <div className="containerIconEachFunct">
              <i className="fa fa-window-maximize" aria-hidden="true"></i>
            </div>
            <p className="titleEachFunctMenu">Ventanas</p>
          </NavLink>
        </div>
      </div>
      <div className="bottomSideMenu">
        <p className="titleEachFunctMenu" onClick={logOut}>
          <i className="fas fa-sign-out-alt"></i> Cerrar sesión
        </p>
      </div>
    </div>
  );
};

export default Menu;
//
