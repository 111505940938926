import {
  ADD_RECURSO_INIT,
  ADD_RECURSO_SUCCESS,
  ADD_RECURSO_FAILURE,
  RESET_ADD_RECURSO,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddRecurso() {
  return {
    type: RESET_ADD_RECURSO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addRecursoSuccess(data) {
  return {
    type: ADD_RECURSO_SUCCESS,
    payload: data,
  };
}

function addRecursoFailure(error) {
  return {
    type: ADD_RECURSO_FAILURE,
    payload: error,
  };
}

function addRecursoInit() {
  return {
    type: ADD_RECURSO_INIT,
  };
}

export function addRecurso(data) {
  return async (dispatch) => {
    dispatch(addRecursoInit());
    try {
      const resp = await API.data.addRecurso(data);
      return dispatch(addRecursoSuccess(resp));
    } catch (error) {
      return dispatch(addRecursoFailure(error));
    }
  };
}
