import {
  GET_RECURSO_INIT,
  GET_RECURSO_SUCCESS,
  GET_RECURSO_FAILURE,
  RESET_RECURSO,
  UPDATE_RECURSO_INIT,
  UPDATE_RECURSO_SUCCESS,
  UPDATE_RECURSO_FAILURE,
  RESET_UPDATE_RECURSO,
} from "./types";
import API from "./api";

export function resetRecurso() {
  return {
    type: RESET_RECURSO,
  };
}

function getRecursoSuccess(data) {
  return {
    type: GET_RECURSO_SUCCESS,
    payload: data,
  };
}

function getRecursoFailure(error) {
  console.log(error);
  return {
    type: GET_RECURSO_FAILURE,
    payload: error,
  };
}

function getRecursoInit(data) {
  return {
    type: GET_RECURSO_INIT,
    payload: data.loading,
  };
}

export function getRecurso(data) {
  return async (dispatch) => {
    dispatch(getRecursoInit(data));
    try {
      const resp = await API.data.getRecurso(data);
      return dispatch(getRecursoSuccess(resp));
    } catch (error) {
      return dispatch(getRecursoFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateRecurso() {
  return {
    type: RESET_UPDATE_RECURSO,
  };
}

function updateRecursoSuccess(data) {
  return {
    type: UPDATE_RECURSO_SUCCESS,
    payload: data,
  };
}

function updateRecursoFailure(error) {
  return {
    type: UPDATE_RECURSO_FAILURE,
    payload: error,
  };
}

function updateRecursoInit() {
  return {
    type: UPDATE_RECURSO_INIT,
  };
}

export function updateRecurso(data) {
  return async (dispatch) => {
    dispatch(updateRecursoInit());
    try {
      const resp = await API.data.updateRecurso(data);
      return dispatch(updateRecursoSuccess(resp));
    } catch (error) {
      return dispatch(updateRecursoFailure(error));
    }
  };
}
