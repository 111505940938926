const messages = {
  Hello: "Hello",
  Yes: "Yes",
  No: "No",
  Select: "Select",
  Name: "Name",
  TimeZone: "Time zone",
  Countries: "Countries",
  Languages: "Languages",
  Group: "Group",
  Email: "Email",
  LastName: "Last name",
  SecondLastName: "Second last name",
  Groups: "Groups",
  ExpiredSession: "Expired session",
  PleaseReconnect: "Please reconnect",
  Open: "Open",
  Password: "Password",
  Add: "Add",
  Loading: "Loading",
  Ok: "Ok",
  SuccessfullyAdded: "Successfully added",
  SuccessfullyUpdated: "Successfully updated",
  SuccessfullyDeleted: "Successfully deleted",
  ThereWasAnError: "There was an error",
  Information: "Information",
  Update: "Update",
  Cancel: "Cancel",
  Delete: "Delete",
  AreYouSure: "Are you sure?",
  Avatar1: "Avatar 1",
  Avatar1IsOk: "Avatar 1 is ok",
  Avatar2: "Avatar 2",
  Avatar2IsOk: "Avatar 2 is ok",
  Avatar3: "Avatar 3",
  Avatar3IsOk: "Avatar 3 is ok",
  Companies: "Companies",
  DateStart: "Date start",
  DateEnd: "Date end",
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: " Wednesday:",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
  Holiday: "Holiday",
  Date: "Date",
  HourStart: "Hour start",
  HourEnd: "Hour end",
  AttendTheNextDay: "Atendiende el siguiente día",
};

export default messages;
