import axios from "axios";

/* Get Recursos*/
export const GetRecursos = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_RECURSOS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/recursos/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_RECURSOS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_RECURSOS_FAIL",
    });
    console.log("Error en getRecursos Action: veni a ver el desmadre", err);
  }
};

/* Reset Recursos */
export const resetRecursos = () => {
  return {
    type: "RESET_RECURSOS",
  };
};

export const DeleteRecurso = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_RECURSO_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/recursos/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_RECURSO_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_RECURSO_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteRecurso = () => {
  return {
    type: "RESET_DELETE_RECURSO",
  };
};
