import initialState from "./initialState";
import {
  GET_VENTANA_INIT,
  GET_VENTANA_SUCCESS,
  GET_VENTANA_FAILURE,
  RESET_VENTANA,
  UPDATE_VENTANA_INIT,
  UPDATE_VENTANA_SUCCESS,
  UPDATE_VENTANA_FAILURE,
  RESET_UPDATE_VENTANA,
  UPDATE_VENTANA_USUARIO_INIT,
  UPDATE_VENTANA_USUARIO_SUCCESS,
  UPDATE_VENTANA_USUARIO_FAILURE,
  RESET_UPDATE_VENTANA_USUARIO,
} from "../../actions/update_ventana/types";

export default function ventana(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_VENTANA:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_VENTANA_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_VENTANA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_VENTANA_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case RESET_UPDATE_VENTANA:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_VENTANA_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_VENTANA_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_VENTANA_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };
    case RESET_UPDATE_VENTANA_USUARIO:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_VENTANA_USUARIO_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_VENTANA_USUARIO_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_VENTANA_USUARIO_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };
    default:
      return state;
  }
}
