import React from "react";
//import { FormattedMessage } from "react-intl";

/* Components */
import Title from "../../component/add_division_avatars/title";
import Form from "../../component/add_division_avatars/form";

const AddDivisionAvatarsPage = (props) => {
  const div = props.match.params.id.split("-");
  return (
    <section>
      <Title />
      <Form id={div[0]} idEmpresa={div[1]} />
    </section>
  );
};

export default AddDivisionAvatarsPage;
