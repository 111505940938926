import initialState from "./initialState";
import {
  GET_JORNADA_HOLIDAY_INIT,
  GET_JORNADA_HOLIDAY_SUCCESS,
  GET_JORNADA_HOLIDAY_FAILURE,
  POST_NEW_JORNADA_INIT,
  POST_NEW_JORNADA_SUCCESS,
  POST_NEW_JORNADA_FAILURE,
  RESET_JORNADA_HOLIDAY,
  RESET_NEW_JORNADA,
} from "../../actions/add_jornada/types";

export default function home(state = initialState, action) {
  switch (action.type) {
    /* Setting */
    case RESET_JORNADA_HOLIDAY:
      return {
        ...state,
        dataJornadaHoliday: [],
        loadingJornadaHoliday: true,
        statusJornadaHoliday: 0,
      };
    case GET_JORNADA_HOLIDAY_INIT:
      return {
        ...state,
        loadingJornadaHoliday: action.payload,
      };
    case GET_JORNADA_HOLIDAY_SUCCESS:
      return {
        ...state,
        dataJornadaHoliday: action.payload.data,
        loadingJornadaHoliday: false,
        statusJornadaHoliday: action.payload.status,
      };
    case GET_JORNADA_HOLIDAY_FAILURE:
      return {
        ...state,
        dataJornadaHoliday: [],
        loadingJornadaHoliday: false,
        statusJornadaHoliday: 501,
      };
    case RESET_NEW_JORNADA:
      return {
        ...state,
        dataNewJornada: [],
        loadingNewJornada: false,
        statusNewJornada: 0,
      };
    case POST_NEW_JORNADA_INIT:
      return {
        ...state,
        loadingNewJornada: true,
      };
    case POST_NEW_JORNADA_SUCCESS:
      return {
        ...state,
        dataNewJornada: action.payload.data,
        loadingNewJornada: false,
        statusNewJornada: action.payload.status,
      };
    case POST_NEW_JORNADA_FAILURE:
      return {
        ...state,
        dataNewJornada: {},
        loadingNewJornada: false,
        statusNewJornada: 501,
      };
    default:
      return state;
  }
}
