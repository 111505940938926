import React from "react";
//import { FormattedMessage } from "react-intl";

/* Components */
import Title from "../../component/add_bolsa_deseable/title";
import Form from "../../component/add_bolsa_deseable/form";

const AddBolsaDeseablePage = (props) => {
  return (
    <section>
      <Title />
      <Form id={props.match.params.id} />
    </section>
  );
};

export default AddBolsaDeseablePage;
