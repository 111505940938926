import {
  GET_COUNTRY_INIT,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILURE,
  RESET_COUNTRY,
  GET_LANGUAGE_INIT,
  GET_LANGUAGE_SUCCESS,
  GET_LANGUAGE_FAILURE,
  RESET_LANGUAGE,
  GET_TIME_INIT,
  GET_TIME_SUCCESS,
  GET_TIME_FAILURE,
  RESET_TIME,
  GET_GROUP_INIT,
  GET_GROUP_SUCCESS,
  GET_GROUP_FAILURE,
  RESET_GROUP,
  GET_DIVISION_INIT,
  GET_DIVISION_SUCCESS,
  GET_DIVISION_FAILURE,
  RESET_DIVISION,
  GET_SUBDIVISION_INIT,
  GET_SUBDIVISION_SUCCESS,
  GET_SUBDIVISION_FAILURE,
  RESET_SUBDIVISION,
  GET_SUBSUBDIVISION_INIT,
  GET_SUBSUBDIVISION_SUCCESS,
  GET_SUBSUBDIVISION_FAILURE,
  RESET_SUBSUBDIVISION,
  GET_LABELS_INIT,
  GET_LABELS_SUCCESS,
  GET_LABELS_FAILURE,
  RESET_LABELS,
  GET_NODOS_INIT,
  GET_NODOS_SUCCESS,
  GET_NODOS_FAILURE,
  RESET_NODOS,
  GET_DIVISIONES_INIT,
  GET_DIVISIONES_SUCCESS,
  GET_DIVISIONES_FAILURE,
  RESET_DIVISIONES,
  GET_BOLSAS_INIT,
  GET_BOLSAS_SUCCESS,
  GET_BOLSAS_FAILURE,
  RESET_BOLSAS,
  GET_BOLSAS_DESEABLE_INIT,
  GET_BOLSAS_DESEABLE_SUCCESS,
  GET_BOLSAS_DESEABLE_FAILURE,
  RESET_BOLSAS_DESEABLE,
  GET_BOLSAS_RECURSO_INIT,
  GET_BOLSAS_RECURSO_SUCCESS,
  GET_BOLSAS_RECURSO_FAILURE,
  RESET_BOLSAS_RECURSO,
  GET_DESPLIEGUES_INIT,
  GET_DESPLIEGUES_SUCCESS,
  GET_DESPLIEGUES_FAILURE,
  RESET_DESPLIEGUES,
  GET_ROLES_INIT,
  GET_ROLES_SUCCESS,
  GET_ROLES_FAILURE,
  RESET_ROLES,
  GET_ROLES_USER_INIT,
  GET_ROLES_USER_SUCCESS,
  GET_ROLES_USER_FAILURE,
  RESET_ROLES_USER,
  GET_AREAS_INIT,
  GET_AREAS_SUCCESS,
  GET_AREAS_FAILURE,
  RESET_AREAS,
  GET_TIEMPO_INIT,
  GET_TIEMPO_SUCCESS,
  GET_TIEMPO_FAILURE,
  RESET_TIEMPO,
} from "./types";
import API from "./api";

/* Country */
export function resetCountry() {
  return {
    type: RESET_COUNTRY,
  };
}

function getCountrySuccess(data) {
  return {
    type: GET_COUNTRY_SUCCESS,
    payload: data,
  };
}

function getCountryFailure(error) {
  return {
    type: GET_COUNTRY_FAILURE,
    payload: error,
  };
}

function getCountryInit(data) {
  return {
    type: GET_COUNTRY_INIT,
    loading: data,
  };
}

export function getCountry(data) {
  return async (dispatch) => {
    dispatch(getCountryInit(data.loading));
    try {
      const resp = await API.data.getCountry(data);
      return dispatch(getCountrySuccess(resp));
    } catch (error) {
      return dispatch(getCountryFailure(error));
    }
  };
}

/* Language */
export function resetLanguage() {
  return {
    type: RESET_LANGUAGE,
  };
}

function getLanguageSuccess(data) {
  return {
    type: GET_LANGUAGE_SUCCESS,
    payload: data,
  };
}

function getLanguageFailure(error) {
  return {
    type: GET_LANGUAGE_FAILURE,
    payload: error,
  };
}

function getLanguageInit(data) {
  return {
    type: GET_LANGUAGE_INIT,
    loading: data,
  };
}

export function getLanguage(data) {
  return async (dispatch) => {
    dispatch(getLanguageInit(data.loading));
    try {
      const resp = await API.data.getLanguage(data);
      return dispatch(getLanguageSuccess(resp));
    } catch (error) {
      return dispatch(getLanguageFailure(error));
    }
  };
}

/* Time */
export function resetTime() {
  return {
    type: RESET_TIME,
  };
}

function getTimeSuccess(data) {
  return {
    type: GET_TIME_SUCCESS,
    payload: data,
  };
}

function getTimeFailure(error) {
  return {
    type: GET_TIME_FAILURE,
    payload: error,
  };
}

function getTimeInit(data) {
  return {
    type: GET_TIME_INIT,
    loading: data,
  };
}

export function getTime(data) {
  return async (dispatch) => {
    dispatch(getTimeInit(data.loading));
    try {
      const resp = await API.data.getTime(data);
      return dispatch(getTimeSuccess(resp));
    } catch (error) {
      return dispatch(getTimeFailure(error));
    }
  };
}

/* group */
export function resetGroup() {
  return {
    type: RESET_GROUP,
  };
}

function getGroupSuccess(data) {
  return {
    type: GET_GROUP_SUCCESS,
    payload: data,
  };
}

function getGroupFailure(error) {
  return {
    type: GET_GROUP_FAILURE,
    payload: error,
  };
}

function getGroupInit(data) {
  return {
    type: GET_GROUP_INIT,
    loading: data,
  };
}

export function getGroup(data) {
  return async (dispatch) => {
    dispatch(getGroupInit(data.loading));
    try {
      const resp = await API.data.getGroup(data);
      return dispatch(getGroupSuccess(resp));
    } catch (error) {
      return dispatch(getGroupFailure(error));
    }
  };
}

/* division */
export function resetDivision() {
  return {
    type: RESET_DIVISION,
  };
}

function getDivisionSuccess(data) {
  return {
    type: GET_DIVISION_SUCCESS,
    payload: data,
  };
}

function getDivisionFailure(error) {
  return {
    type: GET_DIVISION_FAILURE,
    payload: error,
  };
}

function getDivisionInit(data) {
  return {
    type: GET_DIVISION_INIT,
    loading: data,
  };
}

export function getDivision(data) {
  return async (dispatch) => {
    dispatch(getDivisionInit(data.loading));
    try {
      const resp = await API.data.getDivision(data);
      return dispatch(getDivisionSuccess(resp));
    } catch (error) {
      return dispatch(getDivisionFailure(error));
    }
  };
}

/* sub division */
export function resetSubDivision() {
  return {
    type: RESET_SUBDIVISION,
  };
}

function getSubDivisionSuccess(data) {
  return {
    type: GET_SUBDIVISION_SUCCESS,
    payload: data,
  };
}

function getSubDivisionFailure(error) {
  return {
    type: GET_SUBDIVISION_FAILURE,
    payload: error,
  };
}

function getSubDivisionInit(data) {
  return {
    type: GET_SUBDIVISION_INIT,
    loading: data,
  };
}

export function getSubDivision(data) {
  return async (dispatch) => {
    dispatch(getSubDivisionInit(data.loading));
    try {
      const resp = await API.data.getSubDivision(data);
      return dispatch(getSubDivisionSuccess(resp));
    } catch (error) {
      return dispatch(getSubDivisionFailure(error));
    }
  };
}

/* sub sub division */
export function resetSubSubDivision() {
  return {
    type: RESET_SUBSUBDIVISION,
  };
}

function getSubSubDivisionSuccess(data) {
  return {
    type: GET_SUBSUBDIVISION_SUCCESS,
    payload: data,
  };
}

function getSubSubDivisionFailure(error) {
  return {
    type: GET_SUBSUBDIVISION_FAILURE,
    payload: error,
  };
}

function getSubSubDivisionInit(data) {
  return {
    type: GET_SUBSUBDIVISION_INIT,
    loading: data,
  };
}

export function getSubSubDivision(data) {
  return async (dispatch) => {
    dispatch(getSubSubDivisionInit(data.loading));
    try {
      const resp = await API.data.getSubSubDivision(data);
      return dispatch(getSubSubDivisionSuccess(resp));
    } catch (error) {
      return dispatch(getSubSubDivisionFailure(error));
    }
  };
}

/* Labels */
export function resetLabels() {
  return {
    type: RESET_LABELS,
  };
}

function getLabelsSuccess(data) {
  return {
    type: GET_LABELS_SUCCESS,
    payload: data,
  };
}

function getLabelsFailure(error) {
  return {
    type: GET_LABELS_FAILURE,
    payload: error,
  };
}

function getLabelsInit(data) {
  return {
    type: GET_LABELS_INIT,
    loading: data,
  };
}

export function getLabels(data) {
  return async (dispatch) => {
    dispatch(getLabelsInit(data.loading));
    try {
      const resp = await API.data.getLabels(data);
      return dispatch(getLabelsSuccess(resp));
    } catch (error) {
      return dispatch(getLabelsFailure(error));
    }
  };
}

/* Nodos */
export function resetNodos() {
  return {
    type: RESET_NODOS,
  };
}

function getNodosSuccess(data) {
  return {
    type: GET_NODOS_SUCCESS,
    payload: data,
  };
}

function getNodosFailure(error) {
  return {
    type: GET_NODOS_FAILURE,
    payload: error,
  };
}

function getNodosInit(data) {
  return {
    type: GET_NODOS_INIT,
    loading: data,
  };
}

export function getNodos(data) {
  return async (dispatch) => {
    dispatch(getNodosInit(data.loading));
    try {
      const resp = await API.data.getNodos(data);
      return dispatch(getNodosSuccess(resp));
    } catch (error) {
      return dispatch(getNodosFailure(error));
    }
  };
}

/* Divisiones */
export function resetDivisiones() {
  return {
    type: RESET_DIVISIONES,
  };
}

function getDivisionesSuccess(data) {
  return {
    type: GET_DIVISIONES_SUCCESS,
    payload: data,
  };
}

function getDivisionesFailure(error) {
  return {
    type: GET_DIVISIONES_FAILURE,
    payload: error,
  };
}

function getDivisionesInit(data) {
  return {
    type: GET_DIVISIONES_INIT,
    loading: data,
  };
}

export function getDivisiones(data) {
  return async (dispatch) => {
    dispatch(getDivisionesInit(data.loading));
    try {
      const resp = await API.data.getDivisiones(data);
      return dispatch(getDivisionesSuccess(resp));
    } catch (error) {
      return dispatch(getDivisionesFailure(error));
    }
  };
}

/* bolsas */
export function resetBolsas() {
  return {
    type: RESET_BOLSAS,
  };
}

function getBolsasSuccess(data) {
  return {
    type: GET_BOLSAS_SUCCESS,
    payload: data,
  };
}

function getBolsasFailure(error) {
  return {
    type: GET_BOLSAS_FAILURE,
    payload: error,
  };
}

function getBolsasInit(data) {
  return {
    type: GET_BOLSAS_INIT,
    loading: data,
  };
}

export function getBolsas(data) {
  return async (dispatch) => {
    dispatch(getBolsasInit(data.loading));
    try {
      const resp = await API.data.getBolsas(data);
      return dispatch(getBolsasSuccess(resp));
    } catch (error) {
      return dispatch(getBolsasFailure(error));
    }
  };
}

/* bolsas deseable*/
export function resetBolsasDeseable() {
  return {
    type: RESET_BOLSAS_DESEABLE,
  };
}

function getBolsasDeseableSuccess(data) {
  return {
    type: GET_BOLSAS_DESEABLE_SUCCESS,
    payload: data,
  };
}

function getBolsasDeseableFailure(error) {
  return {
    type: GET_BOLSAS_DESEABLE_FAILURE,
    payload: error,
  };
}

function getBolsasDeseableInit(data) {
  return {
    type: GET_BOLSAS_DESEABLE_INIT,
    loading: data,
  };
}

export function getBolsasDeseable(data) {
  return async (dispatch) => {
    dispatch(getBolsasDeseableInit(data.loading));
    try {
      const resp = await API.data.getBolsasDeseables(data);
      return dispatch(getBolsasDeseableSuccess(resp));
    } catch (error) {
      return dispatch(getBolsasDeseableFailure(error));
    }
  };
}

/* bolsas recurso*/
export function resetBolsasRecurso() {
  return {
    type: RESET_BOLSAS_RECURSO,
  };
}

function getBolsasRecursoSuccess(data) {
  return {
    type: GET_BOLSAS_RECURSO_SUCCESS,
    payload: data,
  };
}

function getBolsasRecursoFailure(error) {
  return {
    type: GET_BOLSAS_RECURSO_FAILURE,
    payload: error,
  };
}

function getBolsasRecursoInit(data) {
  return {
    type: GET_BOLSAS_RECURSO_INIT,
    loading: data,
  };
}

export function getBolsasRecurso(data) {
  return async (dispatch) => {
    dispatch(getBolsasRecursoInit(data.loading));
    try {
      const resp = await API.data.getBolsasRecursos(data);
      return dispatch(getBolsasRecursoSuccess(resp));
    } catch (error) {
      return dispatch(getBolsasRecursoFailure(error));
    }
  };
}

/* Despliegues */
export function resetDespliegues() {
  return {
    type: RESET_DESPLIEGUES,
  };
}

function getDesplieguesSuccess(data) {
  return {
    type: GET_DESPLIEGUES_SUCCESS,
    payload: data,
  };
}

function getDesplieguesFailure(error) {
  return {
    type: GET_DESPLIEGUES_FAILURE,
    payload: error,
  };
}

function getDesplieguesInit(data) {
  return {
    type: GET_DESPLIEGUES_INIT,
    loading: data,
  };
}

export function getDespliegues(data) {
  return async (dispatch) => {
    dispatch(getDesplieguesInit(data.loading));
    try {
      const resp = await API.data.getDespliegues(data);
      return dispatch(getDesplieguesSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(getDesplieguesFailure(error));
    }
  };
}

/* Roles */
export function resetRoles() {
  return {
    type: RESET_ROLES,
  };
}

function getRolesSuccess(data) {
  return {
    type: GET_ROLES_SUCCESS,
    payload: data,
  };
}

function getRolesFailure(error) {
  return {
    type: GET_ROLES_FAILURE,
    payload: error,
  };
}

function getRolesInit(data) {
  return {
    type: GET_ROLES_INIT,
    loading: data,
  };
}

export function getRoles(data) {
  return async (dispatch) => {
    dispatch(getRolesInit(data.loading));
    try {
      const resp = await API.data.getRoles(data);
      return dispatch(getRolesSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(getRolesFailure(error));
    }
  };
}

/* Despliegues */
export function resetRolesUser() {
  return {
    type: RESET_ROLES_USER,
  };
}

function getRolesUserSuccess(data) {
  return {
    type: GET_ROLES_USER_SUCCESS,
    payload: data,
  };
}

function getRolesUserFailure(error) {
  return {
    type: GET_ROLES_USER_FAILURE,
    payload: error,
  };
}

function getRolesUserInit(data) {
  return {
    type: GET_ROLES_USER_INIT,
    loading: data,
  };
}

export function getRolesUser(data) {
  return async (dispatch) => {
    dispatch(getRolesUserInit(data.loading));
    try {
      const resp = await API.data.getRolesUser(data);
      return dispatch(getRolesUserSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(getRolesUserFailure(error));
    }
  };
}

/* Despliegues */
export function resetAreas() {
  return {
    type: RESET_AREAS,
  };
}

function getAreasSuccess(data) {
  return {
    type: GET_AREAS_SUCCESS,
    payload: data,
  };
}

function getAreasFailure(error) {
  return {
    type: GET_AREAS_FAILURE,
    payload: error,
  };
}

function getAreasInit(data) {
  return {
    type: GET_AREAS_INIT,
    loading: data,
  };
}

export function getAreas(data) {
  return async (dispatch) => {
    dispatch(getAreasInit(data.loading));
    try {
      const resp = await API.data.getAreas(data);
      return dispatch(getAreasSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(getAreasFailure(error));
    }
  };
}

/* Tiempo */
export function resetTiempo() {
  return {
    type: RESET_TIEMPO,
  };
}

function getTiempoSuccess(data) {
  return {
    type: GET_TIEMPO_SUCCESS,
    payload: data,
  };
}

function getTiempoFailure(error) {
  return {
    type: GET_TIEMPO_FAILURE,
    payload: error,
  };
}

function getTiempoInit(data) {
  return {
    type: GET_TIEMPO_INIT,
    loading: data,
  };
}

export function getTiempo(data) {
  return async (dispatch) => {
    dispatch(getTiempoInit(data.loading));
    try {
      const resp = await API.data.getTiempo(data);
      return dispatch(getTiempoSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(getTiempoFailure(error));
    }
  };
}
