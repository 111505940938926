import {
  ADD_VENTANA_INIT,
  ADD_VENTANA_SUCCESS,
  ADD_VENTANA_FAILURE,
  RESET_ADD_VENTANA,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddVentana() {
  return {
    type: RESET_ADD_VENTANA,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addVentanaSuccess(data) {
  return {
    type: ADD_VENTANA_SUCCESS,
    payload: data,
  };
}

function addVentanaFailure(error) {
  return {
    type: ADD_VENTANA_FAILURE,
    payload: error,
  };
}

function addVentanaInit() {
  return {
    type: ADD_VENTANA_INIT,
  };
}

export function addVentana(data) {
  return async (dispatch) => {
    dispatch(addVentanaInit());
    try {
      const resp = await API.data.addVentana(data);
      return dispatch(addVentanaSuccess(resp));
    } catch (error) {
      return dispatch(addVentanaFailure(error));
    }
  };
}
