import axios from "axios";

/* Get Jornadas*/
export const GetJornadas = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_JORNADAS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/jornadas/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_JORNADAS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_JORNADAS_FAIL",
    });
    console.log("Error en getJornadasAction: veni a ver el desmadre", err);
  }
};

/* Reset Divisiones */
export const resetJornadas = () => {
  return {
    type: "RESET_JORNADAS",
  };
};
