import axios from "axios";

/* Get Cargos*/
export const GetCargos = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_CARGOS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/cargos/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_CARGOS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_CARGOS_FAIL",
    });
    console.log("Error en getCargosAction: veni a ver el desmadre", err);
  }
};

/* Reset Cargos */
export const resetCargos = () => {
  return {
    type: "RESET_CARGOS",
  };
};

export const DeleteCargo = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_CARGO_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/cargos/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_CARGO_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_CARGO_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteCargo = () => {
  return {
    type: "RESET_DELETE_CARGO",
  };
};
