import axios from "axios";

/* Get Bolsa Recursos */
export const GetBolsaRecursos = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_BOLSA_RECURSOS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/bolsasrecurso/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_BOLSA_RECURSOS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_BOLSA_RECURSOS_FAIL",
    });
    console.log("Error en getBolsaRecursosAction: veni a ver el desmadre", err);
  }
};

/* Reset Bolsa Recursos */
export const resetBolsaRecursos = () => {
  return {
    type: "RESET_BOLSA_RECURSOS",
  };
};

export const DeleteBolsaRecurso = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_BOLSA_RECURSO_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/bolsasrecurso/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_BOLSA_RECURSO_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_BOLSA_RECURSO_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteBolsaRecurso = () => {
  return {
    type: "RESET_DELETE_BOLSA_RECURSO",
  };
};
