import {
  ADD_BOLSA_RECURSO_INIT,
  ADD_BOLSA_RECURSO_SUCCESS,
  ADD_BOLSA_RECURSO_FAILURE,
  RESET_ADD_BOLSA_RECURSO,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddBolsaRecurso() {
  return {
    type: RESET_ADD_BOLSA_RECURSO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addBolsaRecursoSuccess(data) {
  return {
    type: ADD_BOLSA_RECURSO_SUCCESS,
    payload: data,
  };
}

function addBolsaRecursoFailure(error) {
  return {
    type: ADD_BOLSA_RECURSO_FAILURE,
    payload: error,
  };
}

function addBolsaRecursoInit() {
  return {
    type: ADD_BOLSA_RECURSO_INIT,
  };
}

export function addBolsaRecurso(data) {
  return async (dispatch) => {
    dispatch(addBolsaRecursoInit());
    try {
      const resp = await API.data.addBolsa(data);
      return dispatch(addBolsaRecursoSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(addBolsaRecursoFailure(error));
    }
  };
}
