import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { addVentana, resetAddVentana } from "../../actions/add_ventana";
import { GetUsuarios, resetUsuarios } from "../../actions/usuarios/index";
import { GetAreas, resetAreas } from "../../actions/areas/";
import { GetCreditos, resetCreditos } from "../../actions/credito/";

/* Componets */
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const stateAdd = useSelector((state) => state.addVentana);
  const getUsuarios = useSelector((state) => state.GetUsuarios);
  const getAreas = useSelector((state) => state.GetAreas);
  const getCreditos = useSelector((state) => state.GetCreditos);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    cantidad_creditos_por_ventana: "",
    usuarios_con_topes: "",
    tope_horas: "1",
  });

  const [usuarios, setUsuarios] = useState([]);
  const [areas, setAreas] = useState([]);
  const [credito, setCredito] = useState(false);

  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    dispatch(GetUsuarios({ empresa_id: parseInt(props.id, 10) }));
    dispatch(GetAreas({ empresa_id: parseInt(props.id, 10) }));
    dispatch(GetCreditos({ empresa_id: parseInt(props.id, 10) }));

    return () => {
      dispatch(resetAddVentana());
      dispatch(resetUsuarios());
      dispatch(resetAreas());
      dispatch(resetCreditos());
    };
    // eslint-disable-next-line
  }, [dispatch]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      empresa_id: parseInt(props.id, 10),
      credito_id: credito,
      nombre: form.nombre,
      usuarios_con_topes: form.usuarios_con_topes === "1" ? true : false,
      cantidad_creditos_por_ventana: form.cantidad_creditos_por_ventana,
      arr_area_id: areas.length > 0 ? areas : null,
      arr_user_id: usuarios.length > 0 ? usuarios : null,
      tope_horas: form.tope_horas,
      arr_edi_division_id:
        props.divisiones.length > 0 ? props.divisiones : null,
    };

    dispatch(addVentana(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    setForm({
      cantidad_creditos_por_ventana: "",
      nombre: "",
      usuarios_con_topes: "",
      tope_horas: "1",
    });
  };

  const addUsuarios = (division) => {
    const arr = [...usuarios];

    const index = arr.findIndex((o) => o === division);

    if (index < 0) {
      arr.push(division);
    } else {
      arr.splice(index, 1);
    }
    setUsuarios(arr);
  };

  const addAreas = (division) => {
    const arr = [...areas];

    const index = arr.findIndex((o) => o === division);

    if (index < 0) {
      arr.push(division);
    } else {
      arr.splice(index, 1);
    }
    setAreas(arr);
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Ventana</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control
                value={form.nombre}
                onChange={handleChange}
                type="text"
                name="nombre"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Cantidad creditos por ventana</Form.Label>
              <Form.Control
                value={form.cantidad_creditos_por_ventana}
                onChange={handleChange}
                type="number"
                name="cantidad_creditos_por_ventana"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Label>Credito</Form.Label>
            <section className="multiSelect">
              {getCreditos.data.length > 0 && (
                <ul>
                  {getCreditos.data.map((item, key) => {
                    return (
                      <li
                        onClick={() => setCredito(item.id)}
                        key={key}
                        className={credito === item.id ? "active" : ""}
                      >
                        {item.descripcion}
                      </li>
                    );
                  })}
                </ul>
              )}
            </section>
          </div>

          <div className="containerFormBox">
            <Form.Label>Areas</Form.Label>
            <section className="multiSelect">
              {getAreas.data.length > 0 && (
                <ul>
                  {getAreas.data.map((item, key) => {
                    const index = areas.findIndex((o) => o === item.id);
                    return (
                      <li
                        onClick={() => addAreas(item.id)}
                        key={key}
                        className={index >= 0 ? "active" : ""}
                      >
                        {item.nombre}
                      </li>
                    );
                  })}
                </ul>
              )}
            </section>
          </div>

          <div className="containerFormBox">
            <Form.Label>Usuarios</Form.Label>
            <section className="multiSelect">
              {getUsuarios.data.length > 0 && (
                <ul>
                  {getUsuarios.data.map((item, key) => {
                    const index = usuarios.findIndex((o) => o === item.user_id);
                    return (
                      <li
                        onClick={() => addUsuarios(item.user_id)}
                        key={key}
                        className={index >= 0 ? "active" : ""}
                      >
                        {item.email} / {item.nombre_completo} / tope hora{" "}
                        {item.tope_horas}
                      </li>
                    );
                  })}
                </ul>
              )}
            </section>
          </div>
          <div className="containerFormBox" />
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Usuarios con tope</Form.Label>
              <Form.Control
                as="select"
                value={form.usuarios_con_topes}
                onChange={handleChange}
                name="usuarios_con_topes"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Tope horas </Form.Label>
              <Form.Control
                value={form.tope_horas}
                onChange={handleChange}
                type="text"
                name="tope_horas"
              />
            </Form.Group>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={stateAdd.loading}
                type="submit"
                variant="warning"
              >
                {stateAdd.loading ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Add" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {stateAdd.status === 200 && !stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={stateAdd.data.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
      {stateAdd.status === 200 && stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyAdded" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
