import axios from "axios";

/* Get CompaniesPoliticas*/
export const GetCompaniesPoliticas = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_COMPANIES_POLITICAS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/companiespoliticas/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_COMPANIES_POLITICAS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_COMPANIES_POLITICAS_FAIL",
    });
    console.log(
      "Error en getCompaniesPoliticasAction: veni a ver el desmadre",
      err
    );
  }
};

/* Reset CompaniesPoliticas */
export const resetCompaniesPoliticas = () => {
  return {
    type: "RESET_COMPANIES_POLITICAS",
  };
};
