import {
  ADD_PREGUNTA_INIT,
  ADD_PREGUNTA_SUCCESS,
  ADD_PREGUNTA_FAILURE,
  RESET_ADD_PREGUNTA,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddPregunta() {
  return {
    type: RESET_ADD_PREGUNTA,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addPreguntaSuccess(data) {
  return {
    type: ADD_PREGUNTA_SUCCESS,
    payload: data,
  };
}

function addPreguntaFailure(error) {
  return {
    type: ADD_PREGUNTA_FAILURE,
    payload: error,
  };
}

function addPreguntaInit() {
  return {
    type: ADD_PREGUNTA_INIT,
  };
}

export function addPregunta(data) {
  return async (dispatch) => {
    dispatch(addPreguntaInit());
    try {
      const resp = await API.data.addPregunta(data);
      return dispatch(addPreguntaSuccess(resp));
    } catch (error) {
      return dispatch(addPreguntaFailure(error));
    }
  };
}
