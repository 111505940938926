import {
  GET_ALTERNATIVA_INIT,
  GET_ALTERNATIVA_SUCCESS,
  GET_ALTERNATIVA_FAILURE,
  RESET_ALTERNATIVA,
  UPDATE_ALTERNATIVA_INIT,
  UPDATE_ALTERNATIVA_SUCCESS,
  UPDATE_ALTERNATIVA_FAILURE,
  RESET_UPDATE_ALTERNATIVA,
} from "./types";
import API from "./api";

export function resetAlternativa() {
  return {
    type: RESET_ALTERNATIVA,
  };
}

function getAlternativaSuccess(data) {
  return {
    type: GET_ALTERNATIVA_SUCCESS,
    payload: data,
  };
}

function getAlternativaFailure(error) {
  console.log(error);
  return {
    type: GET_ALTERNATIVA_FAILURE,
    payload: error,
  };
}

function getAlternativaInit(data) {
  return {
    type: GET_ALTERNATIVA_INIT,
    payload: data.loading,
  };
}

export function getAlternativa(data) {
  return async (dispatch) => {
    dispatch(getAlternativaInit(data));
    try {
      const resp = await API.data.getAlternativa(data);
      return dispatch(getAlternativaSuccess(resp));
    } catch (error) {
      return dispatch(getAlternativaFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateAlternativa() {
  return {
    type: RESET_UPDATE_ALTERNATIVA,
  };
}

function updateAlternativaSuccess(data) {
  return {
    type: UPDATE_ALTERNATIVA_SUCCESS,
    payload: data,
  };
}

function updateAlternativaFailure(error) {
  return {
    type: UPDATE_ALTERNATIVA_FAILURE,
    payload: error,
  };
}

function updateAlternativaInit() {
  return {
    type: UPDATE_ALTERNATIVA_INIT,
  };
}

export function updateAlternativa(data) {
  return async (dispatch) => {
    dispatch(updateAlternativaInit());
    try {
      const resp = await API.data.updateAlternativa(data);
      return dispatch(updateAlternativaSuccess(resp));
    } catch (error) {
      return dispatch(updateAlternativaFailure(error));
    }
  };
}
