import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import {
  updateVentanaUsuario,
  resetUpdateVentanaUsuario,
  getVentana,
  resetVentana,
} from "../../actions/update_ventana";
import { GetUsuarios, resetUsuarios } from "../../actions/usuarios/index";

/* Componets */
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateVentana);
  const getUsuarios = useSelector((state) => state.GetUsuarios);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    tope_horas: "",
    usuarios_con_topes: "",
  });

  const [usuarios, setUsuarios] = useState([]);

  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    dispatch(getVentana({ ventana_id: props.id }));
    dispatch(GetUsuarios({ empresa_id: parseInt(props.company, 10) }));

    return () => {
      dispatch(resetUpdateVentanaUsuario());
      dispatch(resetUsuarios());
      dispatch(resetVentana());
    };
    // eslint-disable-next-line
  }, [dispatch, state.loadingUpdate]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          setForm((prev) => ({
            ...prev,
            id: props.id,
            tope_horas: parseInt(state.data.datos.datos_propios.tope_horas, 10),
          }));

          let arrUser = [];
          /* state.data.datos.usuarios.forEach((item) => {
            arrUser.push(item.id);
          }); */
          setUsuarios(arrUser);
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      tope_horas: form.tope_horas,
      arr_user_id: usuarios.length > 0 ? usuarios : null,
    };

    dispatch(updateVentanaUsuario(obj));
    setModalMessage(true);
  };

  const resetForm = () => {};

  const addUsuarios = (division) => {
    const arr = [...usuarios];

    const index = arr.findIndex((o) => o === division);

    if (index < 0) {
      arr.push(division);
    } else {
      arr.splice(index, 1);
    }
    setUsuarios(arr);
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Ventana</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Tope horario</Form.Label>
              <Form.Control
                value={form.tope_horas}
                onChange={handleChange}
                type="number"
                name="tope_horas"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Label>Usuarios</Form.Label>
            <section className="multiSelect">
              {getUsuarios.data.length > 0 && (
                <ul>
                  {getUsuarios.data.map((item, key) => {
                    const index = usuarios.findIndex((o) => o === item.user_id);
                    return (
                      <li
                        onClick={() => addUsuarios(item.user_id)}
                        key={key}
                        className={index >= 0 ? "active" : ""}
                      >
                        {item.email} / {item.nombre_completo} / Tope horario ={" "}
                        {item.tope_horas}
                      </li>
                    );
                  })}
                </ul>
              )}
            </section>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={state.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {state.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {state.statusUpdate === 200 && !state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={state.dataUpdate.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loading}
        />
      )}
      {state.statusUpdate === 200 && state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyUpdated" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
