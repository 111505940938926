import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

// Actions
import {
  GetVentanasReserva,
  resetVentanasReserva,
} from "../../actions/ventana_reserva/";
// Components
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";

const VentanasReserva = (props) => {
  const dispatch = useDispatch();

  // State
  const getVentanasReserva = useSelector((state) => state.GetVentanasReserva);

  const myIds = props.match.params.id;

  useEffect(() => {
    dispatch(GetVentanasReserva({ ventana_id: parseInt(myIds, 10) }));
  }, [dispatch, myIds]);

  // AQUI RESET K NO SIRVE PA NA
  useEffect(() => {
    return () => {
      dispatch(resetVentanasReserva());
    };
  }, [dispatch]);

  const showVentanas = () => {
    if (getVentanasReserva.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getVentanasReserva.data)) {
      return (
        <div className="containerAllEmpresas">
          {getVentanasReserva.data.map((group, key) => {
            return (
              <div className="eachEmpresa" key={key}>
                <div className="leftSideEachEmpresa">
                  <p className="containerTable">{group.reserva_id}</p>
                  <p className="containerTable">{group.email_responsable}</p>
                  <p className="containerTable">{group.creditos_usados}</p>
                  <p className="containerTable">{group.desde}</p>
                  <p className="containerTable">{group.hasta}</p>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getVentanasReserva.errorMsg !== "") {
      return <ModalTimeOut show={true} />;
    }
  };

  return (
    <React.Fragment>
      <div className="containerEmpresa">
        <p className="titleEmpresas">Listado reservas en ventana</p>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideEachEmpresa">
            <p className="containerTable">ID</p>
            <p className="containerTable">Email</p>
            <p className="containerTable">Credito</p>
            <p className="containerTable">Inicio</p>
            <p className="containerTable">Termino</p>
          </div>
        </div>
        {showVentanas()}
      </div>
    </React.Fragment>
  );
};

export default VentanasReserva;
