import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

import moment from "moment";

/* Actions */
import { GetHorarios, resetHorarios } from "../../actions/horarios/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";

const Horarios = (props) => {
  const dispatch = useDispatch();

  /* States */
  const getHorarios = useSelector((state) => state.GetHorarios);
  const myIds = props.match.params.id.split("(--)");
  const name = myIds[0].replaceAll("&", " ");

  const idarr = name.split(" ");
  let id = idarr[0].replace("[[", "");
  id = id.replace("]]", "");
  const empresa = myIds[1];
  useEffect(() => {
    const FetchData = () => {
      dispatch(
        GetHorarios({
          empresa_id: empresa,
          edi_division_id: id,
        })
      );
    };
    FetchData();
  }, [dispatch, id, empresa]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetHorarios());
    };
  }, [dispatch]);

  const showHorarios = () => {
    if (getHorarios.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getHorarios.data)) {
      return (
        <div className="containerAllEmpresas">
          {getHorarios.data.jornadas.map((item, key) => {
            return (
              <React.Fragment key={`padre-${key}`}>
                <div className="eachEmpresa">
                  <div className="leftSideEachEmpresa">
                    <p className="containerTable">{item.id}</p>
                    <p className="containerTable">
                      {moment(item.finicio.substr(0, 16)).format(
                        "DD-MM-YYYY HH-mm"
                      )}
                    </p>
                    <p className="containerTable">
                      {moment(item.ftermino.substr(0, 16)).format(
                        "DD-MM-YYYY HH-mm"
                      )}
                    </p>
                    <Link
                      className="containerTable"
                      to={{
                        pathname: `/dashboard/add_horario/${props.match.params.id}`,
                        state: {
                          items: item,
                        },
                      }}
                    >
                      <i>iEditar</i>
                    </Link>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      );
    } else if (getHorarios.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <div className="containerEmpresa">
        <p className="titleEmpresas">Horarios</p>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideEachEmpresa">
            <p className="containerTable">ID</p>
            <p className="containerTable">Fecha incio</p>
            <p className="containerTable">Fecha termino</p>
            <p className="containerTable">Editar</p>
          </div>
        </div>
        {showHorarios()}
      </div>
    </React.Fragment>
  );
};

export default Horarios;
