import React, { useState } from "react";
import { Redirect } from "react-router-dom";

/* Redux */
import { useSelector, useDispatch } from "react-redux";

/* Component */
import { Alert } from "react-bootstrap";

/* Style */
import "../../assets/style/login.scss";

/* Actions  */
import { postLogin } from "../../actions/login";

const Login = () => {
  /*  Use  states  */
  const [form, setForm] = useState({
    user: "",
    pass: "",
  });

  /*  User Select from redux  */
  const state = useSelector((state) => state.login);
  const dispatch = useDispatch();

  /*  Función para cambiar los use state de form */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /*  Post  Login  */
  const handleLogin = (event) => {
    dispatch(
      postLogin({
        provider: "kantoor",
        facebook: { uid: "", name: "", token: "" },
        kantoor: { email: form.user.trim(), password: form.pass },
      })
    );
  };

  /* Local store */
  let auth = localStorage.getItem("auth");
  let ruta = "/dashboard/home";
  auth = auth === "true";

  return auth ? (
    <Redirect to={ruta} />
  ) : (
    <div className="containerLogIn">
      <div className="containerInputs">
        <p className="welcome">Bienvenido</p>
        <p className="titleLogin">Usuario</p>
        <input
          value={form.user}
          onChange={(e) => handleChange(e)}
          name="user"
          className="inputLogIn"
          type="email"
          placeholder="usuario@mail.com"
        />
        <p className="titleLogin">Contraseña</p>
        <input
          value={form.pass}
          onChange={(e) => handleChange(e)}
          name="pass"
          className="inputLogIn"
          type="password"
          placeholder="*****************"
        />
        <button onClick={handleLogin} className="btnLogIn">
          {state.loading ? "Cargando" : "Iniciar sesión"}
        </button>
      </div>
      {state.status === 204 && (
        <Alert variant={"danger"} className="top center">
          Usuario o password incorrecto
        </Alert>
      )}
    </div>
  );
};

export default Login;
