import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DatePicker from "react-datepicker";
import moment from "moment";

import ModalTimeOut from "../common/modalExpiredSession";

import ModalMessage from "../common/modalMessage";

import {
  postDeleteJornada,
  resetDeleteJornada,
} from "../../actions/delete_jornada";

import "react-datepicker/dist/react-datepicker.css";

const FormComponent = (props) => {
  const myIds = props.id.split("(--)");
  const name = myIds[0].replaceAll("&", " ");

  const [modalMessage, setModalMessage] = useState(false);

  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() + 1)
  );
  const [endDate, setEndDate] = useState(
    new Date().setFullYear(new Date().getFullYear() + 1)
  );

  const stateDeleteJornada = useSelector((state) => state.deleteJornada);
  const dispatch = useDispatch();

  const submitDate = async (set, date, type) => {
    set(date);
  };

  useEffect(() => {
    return () => {
      dispatch(resetDeleteJornada());
    };
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    const obj = {};
    obj.empresa_id = myIds[1];
    obj.name = name;
    obj.desde = moment(startDate).format("DD-MM-YYYY");
    obj.hasta = moment(endDate).format("DD-MM-YYYY");

    console.log(obj);
    setModalMessage(true);
    dispatch(postDeleteJornada(obj));
  };

  const resetForm = () => {
    dispatch(resetDeleteJornada());
  };

  if (stateDeleteJornada.statusJornadaHoliday === 501) {
    return <ModalTimeOut show={true} />;
  }

  return (
    <Form onSubmit={handleSubmit}>
      {stateDeleteJornada.statusDeleteJornada === 200 &&
        !stateDeleteJornada.dataDeleteJornada.ejecucion.estado && (
          <ModalMessage
            message={stateDeleteJornada.dataDeleteJornada.ejecucion.mensaje}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={stateDeleteJornada.loadingDeleteJornada}
          />
        )}

      {stateDeleteJornada.statusDeleteJornada === 200 &&
        stateDeleteJornada.dataDeleteJornada.ejecucion.estado && (
          <ModalMessage
            message={<FormattedMessage id="SuccessfullyAdded" />}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={stateDeleteJornada.loadingDeleteJornada}
          />
        )}
      <section className="containerForm">
        <div className="containerFormTitle">
          <h1>Datos jornada </h1>
        </div>
        <div className="containerFormBox">
          <Form.Group controlId="code">
            <Form.Label>
              <FormattedMessage id="DateStart" />
            </Form.Label>
            <DatePicker
              className="dateInput"
              dateFormat="dd-MM-yyyy"
              selected={startDate}
              onChange={(date) => submitDate(setStartDate, date, "startDate")}
            />
          </Form.Group>
        </div>
        <div className="containerFormBox">
          <Form.Group controlId="code">
            <Form.Label>
              <FormattedMessage id="DateEnd" />
            </Form.Label>
            <DatePicker
              dateFormat="dd-MM-yyyy"
              className="dateInput"
              selected={endDate}
              onChange={(date) => submitDate(setEndDate, date, "endDate")}
            />
          </Form.Group>
        </div>
      </section>

      <section className="containerForm">
        <div className="containerFormButton">
          <div>
            <Button
              disabled={props.loadingDeleteJornada}
              variant="warning"
              type="submit"
            >
              {stateDeleteJornada.loadingDeleteJornada ? (
                <FormattedMessage id="Loading" />
              ) : (
                <FormattedMessage id="Update" />
              )}
            </Button>
          </div>
        </div>
      </section>
    </Form>
  );
};
FormComponent.propTypes = {};

export default FormComponent;
