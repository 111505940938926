import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { getLanguage, resetLanguage } from "../../actions/filters";
import {
  addBolsaDeseable,
  resetAddBolsaDeseable,
} from "../../actions/add_bolsa_deseable";

/* Componets */

import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.filters);
  const stateAdd = useSelector((state) => state.addBolsaDeseable);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    clave: "",
    glosa: "",
  });

  const [modalMessage, setModalMessage] = useState(false);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getLanguage({ loading: true }));
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetLanguage());
      dispatch(resetAddBolsaDeseable());
    };
  }, [dispatch]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      empresa_id: parseInt(props.id, 10),
      clave: form.clave,
      glosa: form.glosa,
    };

    dispatch(addBolsaDeseable(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    setForm({
      nombre: "",
      clave: "",
      glosa: "",
    });
  };

  if (state.loadingLanguage) {
    return <Loading />;
  } else if (state.statusLanguage === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.statusLanguage === 200) {
    if (!state.dataLanguage.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos bolsa deseable</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Clave<span>hola</span></Form.Label>
              <Form.Control
                value={form.clave}
                onChange={handleChange}
                type="text"
                name="clave"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Glosa<span>hola</span></Form.Label>
              <Form.Control
                value={form.glosa}
                onChange={handleChange}
                type="text"
                name="glosa"
              />
            </Form.Group>
          </div>
          <div className="containerFormButton">
            <div>
              <Button
                disabled={stateAdd.loading}
                type="submit"
                variant="warning"
              >
                {stateAdd.loading ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Add" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {stateAdd.status === 200 && !stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={stateAdd.data.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
      {stateAdd.status === 200 && stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyAdded" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
