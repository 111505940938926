import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { addCargo, resetAddCargo } from "../../actions/add_cargo";

/* Componets */
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const stateAdd = useSelector((state) => state.addCargo);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
  });

  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetAddCargo());
    };
  }, [dispatch]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      nombre: form.nombre,
      empresa_id: props.id,
    };

    dispatch(addCargo(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    setForm({
      nombre: "",
      empresa_id: props.id,
    });
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos cargo</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Name" /><span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.nombre}
                onChange={handleChange}
                type="text"
                name="nombre"
              />
            </Form.Group>
          </div>
          <div className="containerFormButton">
            <div>
              <Button
                disabled={stateAdd.loading}
                type="submit"
                variant="warning"
              >
                {stateAdd.loading ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Add" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {stateAdd.status === 200 && !stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={stateAdd.data.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
      {stateAdd.status === 200 && stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyAdded" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
