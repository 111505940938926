import {
  GET_NODO_INIT,
  GET_NODO_SUCCESS,
  GET_NODO_FAILURE,
  RESET_NODO,
  UPDATE_NODO_INIT,
  UPDATE_NODO_SUCCESS,
  UPDATE_NODO_FAILURE,
  RESET_UPDATE_NODO,
} from "./types";
import API from "./api";

export function resetNodo() {
  return {
    type: RESET_NODO,
  };
}

function getNodoSuccess(data) {
  return {
    type: GET_NODO_SUCCESS,
    payload: data,
  };
}

function getNodoFailure(error) {
  console.log(error);
  return {
    type: GET_NODO_FAILURE,
    payload: error,
  };
}

function getNodoInit(data) {
  return {
    type: GET_NODO_INIT,
    payload: data.loading,
  };
}

export function getNodo(data) {
  return async (dispatch) => {
    dispatch(getNodoInit(data));
    try {
      const resp = await API.data.getNodo(data);
      return dispatch(getNodoSuccess(resp));
    } catch (error) {
      return dispatch(getNodoFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateNodo() {
  return {
    type: RESET_UPDATE_NODO,
  };
}

function updateNodoSuccess(data) {
  return {
    type: UPDATE_NODO_SUCCESS,
    payload: data,
  };
}

function updateNodoFailure(error) {
  return {
    type: UPDATE_NODO_FAILURE,
    payload: error,
  };
}

function updateNodoInit() {
  return {
    type: UPDATE_NODO_INIT,
  };
}

export function updateNodo(data) {
  return async (dispatch) => {
    dispatch(updateNodoInit());
    try {
      const resp = await API.data.updateNodo(data);
      return dispatch(updateNodoSuccess(resp));
    } catch (error) {
      return dispatch(updateNodoFailure(error));
    }
  };
}
