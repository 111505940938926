import {
  ADD_AREA_INIT,
  ADD_AREA_SUCCESS,
  ADD_AREA_FAILURE,
  RESET_ADD_AREA,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddArea() {
  return {
    type: RESET_ADD_AREA,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addAreaSuccess(data) {
  return {
    type: ADD_AREA_SUCCESS,
    payload: data,
  };
}

function addAreaFailure(error) {
  return {
    type: ADD_AREA_FAILURE,
    payload: error,
  };
}

function addAreaInit() {
  return {
    type: ADD_AREA_INIT,
  };
}

export function addArea(data) {
  return async (dispatch) => {
    dispatch(addAreaInit());
    try {
      const resp = await API.data.addArea(data);
      return dispatch(addAreaSuccess(resp));
    } catch (error) {
      return dispatch(addAreaFailure(error));
    }
  };
}
