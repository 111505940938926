import initialState from "./initialState";
import {
  ADD_HORARIO_INIT,
  ADD_HORARIO_SUCCESS,
  ADD_HORARIO_FAILURE,
  RESET_ADD_HORARIO,
} from "../../actions/add_horario/types";

export default function horario(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_ADD_HORARIO:
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case ADD_HORARIO_INIT:
      return {
        ...state,
        loading: true,
      };
    case ADD_HORARIO_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case ADD_HORARIO_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    default:
      return state;
  }
}
