import React from "react";
//import { FormattedMessage } from "react-intl";

/* Components */
import Title from "../../component/add_ventana/title";
import Form from "../../component/add_ventana/form";

const AddCredito = (props) => {
  return (
    <section>
      <Title />
      <Form
        divisiones={props.location.state.items}
        id={props.match.params.id}
      />
    </section>
  );
};

export default AddCredito;
