import {
  ADD_BOLSA_ATRIBUTO_INIT,
  ADD_BOLSA_ATRIBUTO_SUCCESS,
  ADD_BOLSA_ATRIBUTO_FAILURE,
  RESET_ADD_BOLSA_ATRIBUTO,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddBolsaAtributo() {
  return {
    type: RESET_ADD_BOLSA_ATRIBUTO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addBolsaAtributoSuccess(data) {
  return {
    type: ADD_BOLSA_ATRIBUTO_SUCCESS,
    payload: data,
  };
}

function addBolsaAtributoFailure(error) {
  return {
    type: ADD_BOLSA_ATRIBUTO_FAILURE,
    payload: error,
  };
}

function addBolsaAtributoInit() {
  return {
    type: ADD_BOLSA_ATRIBUTO_INIT,
  };
}

export function addBolsaAtributo(data) {
  return async (dispatch) => {
    dispatch(addBolsaAtributoInit());
    try {
      const resp = await API.data.addBolsa(data);
      return dispatch(addBolsaAtributoSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(addBolsaAtributoFailure(error));
    }
  };
}
