import {
  UPDATE_PASSWORD_INIT,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  RESET_UPDATE_PASSWORD,
} from "./types";
import API from "./api";

/* Update  */

export function resetUpdatePassword() {
  return {
    type: RESET_UPDATE_PASSWORD,
  };
}

function updatePasswordSuccess(data) {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: data,
  };
}

function updatePasswordFailure(error) {
  console.log(error);
  return {
    type: UPDATE_PASSWORD_FAILURE,
    payload: error,
  };
}

function updatePasswordInit() {
  return {
    type: UPDATE_PASSWORD_INIT,
  };
}

export function updatePassword(data) {
  return async (dispatch) => {
    dispatch(updatePasswordInit());
    try {
      const resp = await API.data.updatePassword(data);
      return dispatch(updatePasswordSuccess(resp));
    } catch (error) {
      return dispatch(updatePasswordFailure(error));
    }
  };
}
