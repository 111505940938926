export const RESET_COUNTRY = "RESET_COUNTRY";
export const GET_COUNTRY_INIT = "GET_COUNTRY_INIT";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAILURE = "GET_COUNTRY_FAILURE";

export const RESET_LANGUAGE = "RESET_LANGUAGE";
export const GET_LANGUAGE_INIT = "GET_LANGUAGE_INIT";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_FAILURE = "GET_LANGUAGE_FAILURE";

export const RESET_TIME = "RESET_TIME";
export const GET_TIME_INIT = "GET_TIME_INIT";
export const GET_TIME_SUCCESS = "GET_TIME_SUCCESS";
export const GET_TIME_FAILURE = "GET_TIME_FAILURE";

export const RESET_GROUP = "RESET_GROUP";
export const GET_GROUP_INIT = "GET_GROUP_INIT";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAILURE = "GET_GROUP_FAILURE";

export const RESET_DIVISION = "RESET_DIVISION";
export const GET_DIVISION_INIT = "GET_DIVISION_INIT";
export const GET_DIVISION_SUCCESS = "GET_DIVISION_SUCCESS";
export const GET_DIVISION_FAILURE = "GET_DIVISION_FAILURE";

export const RESET_SUBDIVISION = "RESET_SUBDIVISION";
export const GET_SUBDIVISION_INIT = "GET_SUBDIVISION_INIT";
export const GET_SUBDIVISION_SUCCESS = "GET_SUBDIVISION_SUCCESS";
export const GET_SUBDIVISION_FAILURE = "GET_SUBDIVISION_FAILURE";

export const RESET_SUBSUBDIVISION = "RESET_SUBSUBDIVISION";
export const GET_SUBSUBDIVISION_INIT = "GET_SUBSUBDIVISION_INIT";
export const GET_SUBSUBDIVISION_SUCCESS = "GET_SUBSUBDIVISION_SUCCESS";
export const GET_SUBSUBDIVISION_FAILURE = "GET_SUBSUBDIVISION_FAILURE";

export const RESET_LABELS = "RESET_LABELS";
export const GET_LABELS_INIT = "GET_LABELS_INIT";
export const GET_LABELS_SUCCESS = "GET_LABELS_SUCCESS";
export const GET_LABELS_FAILURE = "GET_LABELS_FAILURE";

export const RESET_NODOS = "RESET_NODOS";
export const GET_NODOS_INIT = "GET_NODOS_INIT";
export const GET_NODOS_SUCCESS = "GET_NODOS_SUCCESS";
export const GET_NODOS_FAILURE = "GET_NODOS_FAILURE";

export const RESET_DIVISIONES = "RESET_DIVISIONES";
export const GET_DIVISIONES_INIT = "GET_DIVISIONES_INIT";
export const GET_DIVISIONES_SUCCESS = "GET_DIVISIONES_SUCCESS";
export const GET_DIVISIONES_FAILURE = "GET_DIVISIONES_FAILURE";

export const RESET_BOLSAS = "RESET_BOLSAS";
export const GET_BOLSAS_INIT = "GET_BOLSAS_INIT";
export const GET_BOLSAS_SUCCESS = "GET_BOLSAS_SUCCESS";
export const GET_BOLSAS_FAILURE = "GET_BOLSAS_FAILURE";

export const RESET_BOLSAS_DESEABLE = "RESET_BOLSAS_DESEABLE";
export const GET_BOLSAS_DESEABLE_INIT = "GET_BOLSAS_DESEABLE_INIT";
export const GET_BOLSAS_DESEABLE_SUCCESS = "GET_BOLSAS_DESEABLE_SUCCESS";
export const GET_BOLSAS_DESEABLE_FAILURE = "GET_BOLSAS_DESEABLE_FAILURE";

export const RESET_BOLSAS_RECURSO = "RESET_BOLSAS_RECURSO";
export const GET_BOLSAS_RECURSO_INIT = "GET_BOLSAS_RECURSO_INIT";
export const GET_BOLSAS_RECURSO_SUCCESS = "GET_BOLSAS_RECURSO_SUCCESS";
export const GET_BOLSAS_RECURSO_FAILURE = "GET_BOLSAS_RECURSO_FAILURE";

export const RESET_DESPLIEGUES = "RESET_DESPLIEGUES";
export const GET_DESPLIEGUES_INIT = "GET_DESPLIEGUES_INIT";
export const GET_DESPLIEGUES_SUCCESS = "GET_DESPLIEGUES_SUCCESS";
export const GET_DESPLIEGUES_FAILURE = "GET_DESPLIEGUES_RECURSO_FAILURE";

export const RESET_ROLES = "RESET_ROLES";
export const GET_ROLES_INIT = "GET_ROLES_INIT";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const RESET_ROLES_USER = "RESET_ROLES_USER";
export const GET_ROLES_USER_INIT = "GET_ROLES_USER_INIT";
export const GET_ROLES_USER_SUCCESS = "GET_ROLES_USER_SUCCESS";
export const GET_ROLES_USER_FAILURE = "GET_ROLES_USER_FAILURE";

export const RESET_AREAS = "RESET_AREAS";
export const GET_AREAS_INIT = "GET_AREAS_INIT";
export const GET_AREAS_SUCCESS = "GET_AREAS_SUCCESS";
export const GET_AREAS_FAILURE = "GET_AREAS_FAILURE";

export const RESET_TIEMPO = "RESET_TIEMPO";
export const GET_TIEMPO_INIT = "GET_TIEMPO_INIT";
export const GET_TIEMPO_SUCCESS = "GET_TIEMPO_SUCCESS";
export const GET_TIEMPO_FAILURE = "GET_TIEMPO_FAILURE";
