import axios from "axios";

/* Get Ventanas*/
export const GetVentanas = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_VENTANAS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/ventana/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_VENTANAS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_VENTANAS_FAIL",
    });
    console.log("Error en getVentanas Action: veni a ver el desmadre", err);
  }
};

/* Reset Ventanas */
export const resetVentanas = () => {
  return {
    type: "RESET_VENTANAS",
  };
};

export const DeleteVentana = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_VENTANA_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/ventana/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_VENTANA_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_VENTANA_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const ResetDeleteVentana = () => {
  return {
    type: "RESET_DELETE_VENTANA",
  };
};
