import {
  GET_ACCESOS_INIT,
  GET_ACCESOS_SUCCESS,
  GET_ACCESOS_FAILURE,
  RESET_ACCESOS,
  ADD_ACCESO_INIT,
  ADD_ACCESO_SUCCESS,
  ADD_ACCESO_FAILURE,
  RESET_ADD_ACCESO,
} from "./types";
import API from "./api";

/* Accesos */
export function resetAccesos() {
  return {
    type: RESET_ACCESOS,
  };
}

function getAccesosSuccess(data) {
  return {
    type: GET_ACCESOS_SUCCESS,
    payload: data,
  };
}

function getAccesosFailure(error) {
  return {
    type: GET_ACCESOS_FAILURE,
    payload: error,
  };
}

function getAccesosInit(data) {
  return {
    type: GET_ACCESOS_INIT,
    loading: data,
  };
}

export function getAccesos(data) {
  return async (dispatch) => {
    dispatch(getAccesosInit(data.loading));
    try {
      const resp = await API.data.getAccesos(data);
      return dispatch(getAccesosSuccess(resp));
    } catch (error) {
      return dispatch(getAccesosFailure(error));
    }
  };
}

export function resetAddAcceso() {
  return {
    type: RESET_ADD_ACCESO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addAccesoSuccess(data) {
  return {
    type: ADD_ACCESO_SUCCESS,
    payload: data,
  };
}

function addAccesoFailure(error) {
  return {
    type: ADD_ACCESO_FAILURE,
    payload: error,
  };
}

function addAccesoInit() {
  return {
    type: ADD_ACCESO_INIT,
  };
}

export function addAcceso(data) {
  return async (dispatch) => {
    dispatch(addAccesoInit());
    try {
      const resp = await API.data.addAcceso(data);
      return dispatch(addAccesoSuccess(resp));
    } catch (error) {
      return dispatch(addAccesoFailure(error));
    }
  };
}
