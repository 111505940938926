import React from "react";
import Title from "../../component/delete_jornada/title";
import Form from "../../component/delete_jornada/form";

const DeleteJornada = (props) => {
  return (
    <section>
      <Title />
      <Form id={props.match.params.id} />
    </section>
  );
};

export default DeleteJornada;
