import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import _ from "lodash";

/* Actions */
import {
  getDivisionOne,
  resetDivisionOne,
} from "../../actions/update_division";

/* Actions */
import {
  getRecursosBolsa,
  resetRecursosBolsa,
  updateRecursoBolsa,
  resetUpdateRecursoBolsa,
} from "../../actions/update_division_recurso";

/* Componets */
import FilterBolsas from "../common/filterBolsasRecurso";

import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateDivision);
  const stateUpdate = useSelector((state) => state.updateDivisionRecursos);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    bolsa_id: "",
  });

  const [modalMessage, setModalMessage] = useState(false);
  const [nombres, setNombres] = useState([]);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(
        getDivisionOne({
          empresa_id: props.idEmpresa,
          edi_division_id: props.id,
        })
      );
    };
    callsAPI();
  }, [dispatch, props.idEmpresa, props.id]);

  useEffect(() => {
    return () => {
      dispatch(resetDivisionOne());
      dispatch(resetRecursosBolsa());
      dispatch(resetUpdateRecursoBolsa());
    };
  }, [dispatch]);

  /* Datos generales  */
  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          const isthereattr =
            state.data.datos.recursos.length > 0 ? true : false;
          if (isthereattr) {
            dispatch(
              getRecursosBolsa({
                bolsa_recurso_id: state.data.datos.recursos[0].bolsa_recurso.id,
              })
            );
          }
          if (!props.arr) {
            setForm((prev) => ({
              ...prev,
              id: props.id,
              bolsa_id: isthereattr
                ? state.data.datos.recursos[0].bolsa_recurso.id
                : "",
            }));
          } else {
            setForm((prev) => ({
              ...prev,
              id: props.id,
            }));
          }
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id, props.arr]);

  /* Datos deseables */
  useEffect(() => {
    const callsAPI = async () => {
      if (stateUpdate.status === 200 && state.status === 200) {
        if (stateUpdate.data.ejecucion.estado) {
          const list_name = [];
          let value = "";
          stateUpdate.data.datos.forEach((item, i) => {
            if (state.data.ejecucion.estado) {
              if (state.data.datos.recursos.length > 0) {
                const index =
                  state.data.datos.recursos[0].recursos_clasificados.findIndex(
                    (el) => el.atributo_id === item.id
                  );
                if (index >= 0) {
                  value = state.data.datos.recursos[0].recursos_clasificados[
                    index
                  ].seleccionado
                    ? "1"
                    : "0";
                }
              }
            }
            list_name.push({
              value,
              index: `name-${i}`,
              id: item.id,
              label: item.clave,
              tag: item.tag,
            });
          });
          setNombres(list_name);
        }
      }
    };
    callsAPI();
  }, [
    dispatch,
    stateUpdate.data,
    stateUpdate.status,
    props.id,
    state.data,
    state.status,
  ]);

  useEffect(() => {
    const callsAPI = async () => {
      if (props.arr.length > 0) {
        setForm((prev) => ({ ...prev, bolsa_id: props.arr[0].id }));
        dispatch(
          getRecursosBolsa({
            bolsa_recurso_id: props.arr[0].id,
          })
        );
      }
    };
    callsAPI();
  }, [dispatch, props.arr]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    if (event.target.value !== "") {
      dispatch(
        getRecursosBolsa({
          bolsa_recurso_id: event.target.value,
        })
      );
    } else {
      setNombres([]);
    }
  };

  /* Para cambiar los nombres */
  const handleNombres = (event) => {
    event.persist();
    let list_name = [...nombres];
    const index = nombres.findIndex((item) => item.index === event.target.name);
    list_name[index][event.target.dataset.value] = event.target.value;
    setNombres(list_name);
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const nombresArr = [];
    nombres.forEach((item) => {
      nombresArr.push({
        recurso_id: item.id,
        seleccionado: item.value === "1" ? true : false,
      });
    });

    const obj = {
      edi_division_id: props.id,
      recursos_seleccionados: nombresArr,
    };

    dispatch(updateRecursoBolsa(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetUpdateRecursoBolsa());
  };

  if (state.loading || stateUpdate.loading) {
    return <Loading />;
  } else if (state.status === 501 || stateUpdate.status === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.status === 200) {
    if (!state.data.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }
  let title = null;
  let showTitle = false;
  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos division recurso</h1>
          </div>

          <FilterBolsas
            arr={props.arr}
            name="bolsa_id"
            form={form}
            handleChange={handleChange}
            id_parent={props.idEmpresa}
          />
        </div>
        <div className="containerForm">
          {_.orderBy(nombres, ["tag", "label"], ["asc", "asc"]).map((item) => {
            if (item.tag !== title) {
              showTitle = true;
              title = item.tag;
            } else {
              showTitle = false;
            }
            return (
              <React.Fragment key={item.id}>
                {showTitle && (
                  <div className="containerFormTitle">
                    <h1>{item.tag}</h1>
                  </div>
                )}
                <div className="containerFormBox">
                  <Form.Group>
                    <Form.Label>
                      {item.label}
                      <span>Atributo que corresponde a {item.tag}</span>
                    </Form.Label>
                    <Form.Control
                      value={item.value}
                      onChange={handleNombres}
                      type="text"
                      data-value="value"
                      name={item.index}
                      as="select"
                    >
                      <FormattedMessage id="Select">
                        {(message) => <option value="">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="No">
                        {(message) => <option value="0">{message}</option>}
                      </FormattedMessage>
                      <FormattedMessage id="Yes">
                        {(message) => <option value="1">{message}</option>}
                      </FormattedMessage>
                    </Form.Control>
                  </Form.Group>
                </div>
              </React.Fragment>
            );
          })}
          <div className="containerFormButton">
            <div>
              <Button
                disabled={stateUpdate.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {stateUpdate.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {stateUpdate.statusUpdate === 200 &&
        !stateUpdate.dataUpdate.ejecucion.estado && (
          <ModalMessage
            message={stateUpdate.dataUpdate.ejecucion.mensaje}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={stateUpdate.loadingUpdate}
          />
        )}
      {stateUpdate.statusUpdate === 200 &&
        stateUpdate.dataUpdate.ejecucion.estado && (
          <ModalMessage
            message={<FormattedMessage id="SuccessfullyUpdated" />}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={stateUpdate.loadingUpdate}
          />
        )}
    </React.Fragment>
  );
};

export default FormComponent;
