import axios from "axios";

/* Get Horarios*/
export const GetHorarios = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_HORARIOS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/horarios/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_HORARIOS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_HORARIOS_FAIL",
    });
    console.log("Error en getHorariosAction: veni a ver el desmadre", err);
  }
};

/* Reset Divisiones */
export const resetHorarios = () => {
  return {
    type: "RESET_HORARIOS",
  };
};
