import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

function ModalMessage(props) {
  const close = () => {
    props.setModal(false);
    props.func();
  };

  return (
    <>
      <Modal
        show={props.modal}
        onHide={() => close()}
        onExit={() => {
          close();
        }}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <FormattedMessage id="Information" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.loading ? (
            <FormattedMessage id="Loading" />
          ) : (
            <div>
              {props.message.data1 && (
                <p>
                  {props.message.data1.ejecucion.estado ? (
                    <FormattedMessage id="SuccessfullyAdded" />
                  ) : (
                    props.message.data1.ejecucion.mensaje
                  )}
                </p>
              )}
              {props.message.data2 && (
                <p>
                  {props.message.data2.ejecucion.estado ? (
                    <FormattedMessage id="SuccessfullyAdded" />
                  ) : (
                    props.message.data2.ejecucion.mensaje
                  )}
                </p>
              )}
              {props.message.data3 && (
                <p>
                  {props.message.data3.ejecucion.estado ? (
                    <FormattedMessage id="SuccessfullyAdded" />
                  ) : (
                    props.message.data3.ejecucion.mensaje
                  )}
                </p>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={close}>
            <FormattedMessage id="Ok" />
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalMessage;
