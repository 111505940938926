import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";

/* Actions */
import { getLanguage, resetLanguage } from "../../actions/filters";
import { addCompany, resetAddCompany } from "../../actions/add_company";

/* Componets */
import FilterCountry from "../common/filterCountry";
import FilterLanguage from "../common/filterLanguage";
import FilterTime from "../common/filterTime";
import FilterGroup from "../common/filterGroup";
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

import "react-datepicker/dist/react-datepicker.css";

const FormComponent = () => {
  const state = useSelector((state) => state.filters);
  const stateAdd = useSelector((state) => state.addCompany);

  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    uid: "",
    huso_horario: "",
    pais_id: "",
    lenguaje_id: "",
    grupo_id: "",
    email: "",
    abierta: "",
    user_nombres: "",
    customizado: "{}",
    user_pais_id: "",
    user_lenguaje_id: "",
    user_apellido_paterno: "",
    user_apellido_materno: "",
    user_uid: "",
    user_password: "",
    accesos: "",
    retroalimentacion: "",
    replica_automatico_ventana: "",
    sobreventa_permitida_en_ventana: "",
    tiempo_autonalulado: "0",
    q_dias_permitidos_a_futuro: "0",
  });

  const [modalMessage, setModalMessage] = useState(false);
  const [nombres, setNombres] = useState([]);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getLanguage({ loading: true }));
    };
    callsAPI();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetLanguage());
      dispatch(resetAddCompany());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.statusLanguage === 200) {
        if (state.dataLanguage.ejecucion.estado) {
          const list_name = [];
          state.dataLanguage.datos.forEach((item, i) => {
            list_name.push({
              value: "",
              index: `name-${i}`,
              id: item.id,
              label: item.glosa,
            });
          });
          setNombres(list_name);
        }
      }
    };
    callsAPI();
  }, [dispatch, state]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Para cambiar los nombres */
  const handleNombres = (event) => {
    event.persist();
    let list_name = [...nombres];
    const index = nombres.findIndex((item) => item.index === event.target.name);
    list_name[index].value = event.target.value;
    setNombres(list_name);
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const nombresArr = [];
    nombres.forEach((item) => {
      nombresArr.push({ lenguaje_id: item.id, nombre: item.value });
    });

    const obj = {
      datos_propios: {
        customizado: form.customizado,
        abierta: form.abierta === "1" ? true : false,
        accesos: form.accesos === "1" ? true : false,
        retroalimentacion: form.retroalimentacion === "1" ? true : false,
        pais_id: form.pais_id,
        q_dias_permitidos_a_futuro: form.q_dias_permitidos_a_futuro,
        huso_horario: form.huso_horario,
        uid: form.uid,
        tiempo_autonalulado:
          form.tiempo_autonalulado === "" ? "0" : form.tiempo_autonalulado,
        lenguaje_id: form.lenguaje_id,
        grupo_id: form.grupo_id === "" ? null : form.grupo_id,
        nombre: nombresArr,
        replica_automatico_ventana:
          form.replica_automatico_ventana === "1" ? true : false,
        sobreventa_permitida_en_ventana:
          form.sobreventa_permitida_en_ventana === "1" ? true : false,
        parte_autoanulado: startDate
          ? moment(startDate).format("DD-MM-YYYY")
          : null,
      },
      usuario_admin: {
        email: form.email,
        password: form.user_password,
        lenguaje_id: form.user_lenguaje_id,
        pais_id: form.user_pais_id,
        uid: form.user_uid,
        nombres: form.user_nombres,
        apellido_paterno: form.user_apellido_paterno,
        apellido_materno: form.user_apellido_materno,
      },
    };

    dispatch(addCompany(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    setStartDate(null);
    setForm({
      nombre: "",
      uid: "",
      huso_horario: "",
      pais_id: "",
      lenguaje_id: "",
      grupo_id: "",
      email: "",
      abierta: "",
      user_nombres: "",
      user_pais_id: "",
      user_lenguaje_id: "",
      user_apellido_paterno: "",
      user_apellido_materno: "",
      user_uid: "",
      user_password: "",
      accesos: "",
      retroalimentacion: "",
      replica_automatico_ventana: "",
      sobreventa_permitida_en_ventana: "",
      customizado: "{}",
      tiempo_autonalulado: "0",
      q_dias_permitidos_a_futuro: "0",
    });
    const list_name = [];
    state.dataLanguage.datos.forEach((item, i) => {
      list_name.push({
        value: "",
        index: `name-${i}`,
        id: item.id,
        label: item.glosa,
      });
    });
    setNombres(list_name);
  };

  if (state.loadingLanguage) {
    return <Loading />;
  } else if (state.statusLanguage === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.statusLanguage === 200) {
    if (!state.dataLanguage.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos empresas </h1>
          </div>
          {nombres.map((item) => {
            return (
              <div className="containerFormBox" key={item.id}>
                <Form.Group>
                  <Form.Label>
                    {item.label} <FormattedMessage id="Name" />
                    <span>hola marrano</span>
                  </Form.Label>
                  <Form.Control
                    value={item.value}
                    onChange={handleNombres}
                    type="text"
                    name={item.index}
                  />
                </Form.Group>
              </div>
            );
          })}

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                UID<span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.uid}
                onChange={handleChange}
                type="text"
                name="uid"
              />
            </Form.Group>
          </div>
          <FilterTime
            name="huso_horario"
            form={form}
            handleChange={handleChange}
          />
          <FilterCountry
            name="pais_id"
            form={form}
            handleChange={handleChange}
          />
          <FilterLanguage
            name="lenguaje_id"
            form={form}
            handleChange={handleChange}
          />
          <FilterGroup
            name="grupo_id"
            form={form}
            handleChange={handleChange}
          />
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Open" />
                <span>hola marrano</span>
              </Form.Label>

              <Form.Control
                as="select"
                value={form.abierta}
                onChange={handleChange}
                name="abierta"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label className="form-label">
                Accesos<span>hola marrano</span>
              </Form.Label>

              <Form.Control
                as="select"
                value={form.accesos}
                onChange={handleChange}
                name="accesos"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Retroalimentación<span>hola marrano</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={form.retroalimentacion}
                onChange={handleChange}
                name="retroalimentacion"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Replica automatico ventana<span>hola marrano</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={form.replica_automatico_ventana}
                onChange={handleChange}
                name="replica_automatico_ventana"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Sobreventa permitida en ventana<span>hola marrano</span>
              </Form.Label>
              <Form.Control
                as="select"
                value={form.sobreventa_permitida_en_ventana}
                onChange={handleChange}
                name="sobreventa_permitida_en_ventana"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                Tiempo anulado
                <span>Tiempo anulado</span>
              </Form.Label>
              <Form.Control
                value={form.tiempo_autonalulado}
                onChange={handleChange}
                type="text"
                name="tiempo_autonalulado"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                dias permitidos a futuro
                <span>q_dias_permitidos_a_futuro</span>
              </Form.Label>
              <Form.Control
                value={form.q_dias_permitidos_a_futuro}
                onChange={handleChange}
                type="number"
                name="q_dias_permitidos_a_futuro"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group controlId="code">
              <Form.Label>Fecha parte auto anulado</Form.Label>
              <DatePicker
                className="dateInput"
                dateFormat="dd-MM-yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Form.Group>
          </div>
          <div className="containerFormBox full">
            <Form.Group>
              <Form.Label> Customizado</Form.Label>
              <Form.Control
                value={form.customizado}
                onChange={handleChange}
                name="customizado"
                as="textarea"
                rows={10}
              />
            </Form.Group>
          </div>
        </div>

        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos usuarios</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Email" />
                <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.email}
                onChange={handleChange}
                type="text"
                name="email"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Password" />
                <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.user_password}
                onChange={handleChange}
                type="text"
                name="user_password"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="Name" />
                <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.user_nombres}
                onChange={handleChange}
                type="text"
                name="user_nombres"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                UID<span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.user_uid}
                onChange={handleChange}
                type="text"
                name="user_uid"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="LastName" />
                <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.user_apellido_paterno}
                onChange={handleChange}
                type="text"
                name="user_apellido_paterno"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="SecondLastName" />
                <span>hola marrano</span>
              </Form.Label>
              <Form.Control
                value={form.user_apellido_materno}
                onChange={handleChange}
                type="text"
                name="user_apellido_materno"
              />
            </Form.Group>
          </div>
          <FilterCountry
            form={form}
            name="user_pais_id"
            handleChange={handleChange}
          />
          <FilterLanguage
            name="user_lenguaje_id"
            form={form}
            handleChange={handleChange}
          />

          <div className="containerFormButton">
            <div>
              <Button
                disabled={stateAdd.loading}
                type="submit"
                variant="warning"
              >
                {stateAdd.loading ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Add" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {stateAdd.status === 200 && !stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={stateAdd.data.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
      {stateAdd.status === 200 && stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyAdded" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
