// Compañies politicas ocupa style de granularidad
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

/* Actions */
import {
  GetCompaniesPoliticas,
  resetCompaniesPoliticas,
} from "../../actions/companies_politicas/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";

const CompaniesPoliticas = (props) => {
  const dispatch = useDispatch();

  /* States */
  const getCompaniesPoliticas = useSelector(
    (state) => state.GetCompaniesPoliticas
  );

  useEffect(() => {
    const FetchData = () => {
      dispatch(GetCompaniesPoliticas({}));
    };
    FetchData();
  }, [dispatch, props.match.params.id]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetCompaniesPoliticas());
    };
  }, [dispatch]);

  const showCompaniesPoliticas = () => {
    if (getCompaniesPoliticas.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getCompaniesPoliticas.data)) {
      return (
        <div className="containerAllGranularidad">
          {getCompaniesPoliticas.data.map((item, key) => {
            return (
              <div className="eachGranularidad" key={key}>
                <div className="leftSideGranularidad">
                  <p className="containerTableID">{item.id}</p>
                  <p className="containerTable">{item.nombre}</p>
                  <p className="containerTable">
                    {item.libereda ? "SI" : "No"}
                  </p>
                  <div className="containerTable">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li>
                            <Link to={`/dashboard/accesos/${item.id}-${1}`}>
                              Edific grupo
                            </Link>
                          </li>
                          <li>
                            <Link to={`/dashboard/accesos/${item.id}-${0}`}>
                              Edificación
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div></div>
              </div>
            );
          })}
        </div>
      );
    } else if (getCompaniesPoliticas.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <div className="containerGranularidad">
        <p className="titleGranularidad">Empresas politicas</p>
        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideGranularidad">
            <p className="containerTableID">ID</p>
            <p className="containerTable">Nombre</p>
            <p className="containerTable">Liberada</p>
            <p className="containerTable">Más</p>
          </div>
        </div>
        {showCompaniesPoliticas()}
      </div>
    </React.Fragment>
  );
};

export default CompaniesPoliticas;
