export const RESET_JORNADA_HOLIDAY = "RESET_JORNADA_HOLIDAY";
export const RESET_NEW_JORNADA = "RESET_NEW_JORNADA";

/* (ASYNC) */
export const GET_JORNADA_HOLIDAY_INIT = "GET_JORNADA_HOLIDAY_INIT";
export const GET_JORNADA_HOLIDAY_SUCCESS = "GET_JORNADA_HOLIDAY_SUCCESS";
export const GET_JORNADA_HOLIDAY_FAILURE = "GET_JORNADA_HOLIDAY_FAILURE";

export const POST_NEW_JORNADA_INIT = "POST_NEW_JORNADA_INIT";
export const POST_NEW_JORNADA_SUCCESS = "POST_NEW_JORNADA_SUCCESS";
export const POST_NEW_JORNADA_FAILURE = "POST_NEW_JORNADA_FAILURE";
