import React from "react";
import { Modal, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import FilterRoles from "./filterRoles";

function ModalAddRoles(props) {
  const close = () => {
    //props.setModal(false);
    props.func();
  };

  const reset = () => {
    props.reset();
    props.setModal(false);
  };

  return (
    <>
      <Modal
        show={props.modal}
        onHide={() => reset()}
        onExit={() => reset()}
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Agregar Role
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.state.statusAddRoles === 0 && (
            <div>
              {props.state.loadingAddRoles ? (
                <h4>
                  <FormattedMessage id="Loading" />
                </h4>
              ) : (
                <div>
                  <FilterRoles
                    name="role"
                    form={props.form}
                    handleChange={props.handleChange}
                  />
                </div>
              )}
            </div>
          )}
          {props.state.statusAddRoles === 200 && (
            <div>
              {props.state.dataAddRoles.ejecucion.estado ? (
                <h4>Asignada con éxito</h4>
              ) : (
                <p>{props.state.dataAddRoles.ejecucion.mensaje}</p>
              )}
            </div>
          )}
        </Modal.Body>
        {props.state.statusAddRoles === 0 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button variant="secondary" onClick={close}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
        {props.state.statusAddRoles === 200 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => reset()}>
              <FormattedMessage id="Ok" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}

export default ModalAddRoles;
