import axios from "axios";

/* Get VentanasReserva*/
export const GetVentanasReserva = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_VENTANAS_RESERVA_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/ventana/reserva/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_VENTANAS_RESERVA_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_VENTANAS_RESERVA_FAIL",
    });
    console.log(
      "Error en getVentanasReserva Action: veni a ver el desmadre",
      err
    );
  }
};

/* Reset Ventanas */
export const resetVentanasReserva = () => {
  return {
    type: "RESET_VENTANAS_RESERVA",
  };
};
