import initialState from "./initialState";
import {
  GET_CREDITO_INIT,
  GET_CREDITO_SUCCESS,
  GET_CREDITO_FAILURE,
  RESET_CREDITO,
  UPDATE_CREDITO_INIT,
  UPDATE_CREDITO_SUCCESS,
  UPDATE_CREDITO_FAILURE,
  RESET_UPDATE_CREDITO,
} from "../../actions/update_credito/types";

export default function credito(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_CREDITO:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_CREDITO_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_CREDITO_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_CREDITO_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case RESET_UPDATE_CREDITO:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_CREDITO_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_CREDITO_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_CREDITO_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };
    default:
      return state;
  }
}
