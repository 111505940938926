const initialState = {
  data: {},
  loading: false,
  errorMsg: "",
  status: 0,
  dataUpdate: {},
  loadingUpdate: false,
  statusUpdate: 0,
  dataDelete: [],
  loadingDelete: false,
  statusDelete: 0,
  errorMsgDelete: "",
};

const GetGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET
    case "GET_GROUPS_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_GROUPS":
      return {
        ...state,
        data: {},
        loading: false,
        errorMsg: "",
      };
    case "GET_GROUPS_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_GROUPS_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
      };
    // UPDATE
    case "RESET_UPDATE_GROUPS":
      return {
        ...state,
        data: {},
        loadingUpdate: false,
        errorMsg: "",
        statusUpdate: 0,
      };
    case "UPDATE_GROUPS_LOADING":
      return {
        ...state,
        loadingUpdate: true,
      };
    case "UPDATE_GROUPS_SUCESS":
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case "UPDATE_GROUPS_FAIL":
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
        errorMsg: "cant bring data",
      };
    // delete
    case "RESET_DELETE_GROUP":
      return {
        ...state,
        dataDelete: {},
        loadingDelete: false,
        statusDelete: 0,
        errorMsgDelete: "",
      };
    case "DELETE_GROUPS_FAIL":
      return {
        ...state,
        loadingDelete: false,
        statusDelete: 401,
        errorMsgDelete: "cant bring data",
      };
    case "DELETE_GROUPS_LOADING":
      return {
        ...state,
        loadingDelete: true,
        errorMsgDelete: "",
      };
    case "DELETE_GROUPS_SUCESS":
      return {
        ...state,
        loadingDelete: false,
        dataDelete: action.payload,
        errorMsgDelete: "",
        statusDelete: action.status,
      };
    default:
      return state;
  }
};

export default GetGroupsReducer;
