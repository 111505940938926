const initialState = {
  data: [],
  loading: false,
  errorMsg: "",
  status: 0,
};

const code = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CODE_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_CODE":
      return {
        ...state,
        data: [],
        loading: false,
        errorMsg: "",
        status: 0,
      };
    case "GET_CODE_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
        status: action.status,
      };
    case "GET_CODE_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
        status: 401,
      };

    default:
      return state;
  }
};

export default code;
