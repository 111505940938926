import {
  GET_DIVISIONONE_INIT,
  GET_DIVISIONONE_SUCCESS,
  GET_DIVISIONONE_FAILURE,
  RESET_DIVISIONONE,
  UPDATE_DIVISION_INIT,
  UPDATE_DIVISION_SUCCESS,
  UPDATE_DIVISION_FAILURE,
  RESET_UPDATE_DIVISION,
} from "./types";
import API from "./api";

export function resetDivisionOne() {
  return {
    type: RESET_DIVISIONONE,
  };
}

function getDivisionOneSuccess(data) {
  return {
    type: GET_DIVISIONONE_SUCCESS,
    payload: data,
  };
}

function getDivisionOneFailure(error) {
  console.log(error);
  return {
    type: GET_DIVISIONONE_FAILURE,
    payload: error,
  };
}

function getDivisionOneInit(data) {
  return {
    type: GET_DIVISIONONE_INIT,
    payload: data.loading,
  };
}

export function getDivisionOne(data) {
  return async (dispatch) => {
    dispatch(getDivisionOneInit(data));
    try {
      const resp = await API.data.getDivision(data);
      return dispatch(getDivisionOneSuccess(resp));
    } catch (error) {
      return dispatch(getDivisionOneFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateDivision() {
  return {
    type: RESET_UPDATE_DIVISION,
  };
}

function updateDivisionSuccess(data) {
  return {
    type: UPDATE_DIVISION_SUCCESS,
    payload: data,
  };
}

function updateDivisionFailure(error) {
  return {
    type: UPDATE_DIVISION_FAILURE,
    payload: error,
  };
}

function updateDivisionInit() {
  return {
    type: UPDATE_DIVISION_INIT,
  };
}

export function updateDivision(data) {
  return async (dispatch) => {
    dispatch(updateDivisionInit());
    try {
      const resp = await API.data.updateDivision(data);
      return dispatch(updateDivisionSuccess(resp));
    } catch (error) {
      return dispatch(updateDivisionFailure(error));
    }
  };
}
