import React from "react";
//import { FormattedMessage } from "react-intl";

/* Components */
import Title from "../../component/add_company/title";
import Form from "../../component/add_company/form";

const AddCompanyPage = () => {
  return (
    <section>
      <Title />
      <Form />
    </section>
  );
};

export default AddCompanyPage;
