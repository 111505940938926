import React from "react";
//import { FormattedMessage } from "react-intl";

/* Components */
import Title from "../../component/add_alternativa_avatars/title";
import Form from "../../component/add_alternativa_avatars/form";

const AddAlternativaPage = (props) => {
  return (
    <section>
      <Title />
      <Form id={props.match.params.id} />
    </section>
  );
};

export default AddAlternativaPage;
