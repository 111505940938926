import {
  GET_DESEABLE_INIT,
  GET_DESEABLE_SUCCESS,
  GET_DESEABLE_FAILURE,
  RESET_DESEABLE,
  UPDATE_DESEABLE_INIT,
  UPDATE_DESEABLE_SUCCESS,
  UPDATE_DESEABLE_FAILURE,
  RESET_UPDATE_DESEABLE,
} from "./types";
import API from "./api";

export function resetDeseable() {
  return {
    type: RESET_DESEABLE,
  };
}

function getDeseableSuccess(data) {
  return {
    type: GET_DESEABLE_SUCCESS,
    payload: data,
  };
}

function getDeseableFailure(error) {
  console.log(error);
  return {
    type: GET_DESEABLE_FAILURE,
    payload: error,
  };
}

function getDeseableInit(data) {
  return {
    type: GET_DESEABLE_INIT,
    payload: data.loading,
  };
}

export function getDeseable(data) {
  return async (dispatch) => {
    dispatch(getDeseableInit(data));
    try {
      const resp = await API.data.getDeseable(data);
      return dispatch(getDeseableSuccess(resp));
    } catch (error) {
      return dispatch(getDeseableFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateDeseable() {
  return {
    type: RESET_UPDATE_DESEABLE,
  };
}

function updateDeseableSuccess(data) {
  return {
    type: UPDATE_DESEABLE_SUCCESS,
    payload: data,
  };
}

function updateDeseableFailure(error) {
  return {
    type: UPDATE_DESEABLE_FAILURE,
    payload: error,
  };
}

function updateDeseableInit() {
  return {
    type: UPDATE_DESEABLE_INIT,
  };
}

export function updateDeseable(data) {
  return async (dispatch) => {
    dispatch(updateDeseableInit());
    try {
      const resp = await API.data.updateDeseable(data);
      return dispatch(updateDeseableSuccess(resp));
    } catch (error) {
      return dispatch(updateDeseableFailure(error));
    }
  };
}
