import {
  GET_RECURSOS_BOLSA_INIT,
  GET_RECURSOS_BOLSA_SUCCESS,
  GET_RECURSOS_BOLSA_FAILURE,
  RESET_RECURSOS_BOLSA,
  UPDATE_RECURSO_BOLSA_INIT,
  UPDATE_RECURSO_BOLSA_SUCCESS,
  UPDATE_RECURSO_BOLSA_FAILURE,
  RESET_UPDATE_RECURSO_BOLSA,
} from "./types";
import API from "./api";

export function resetRecursosBolsa() {
  return {
    type: RESET_RECURSOS_BOLSA,
  };
}

function getRecursosBolsaSuccess(data) {
  return {
    type: GET_RECURSOS_BOLSA_SUCCESS,
    payload: data,
  };
}

function getRecursosBolsaFailure(error) {
  console.log(error);
  return {
    type: GET_RECURSOS_BOLSA_FAILURE,
    payload: error,
  };
}

function getRecursosBolsaInit(data) {
  return {
    type: GET_RECURSOS_BOLSA_INIT,
    payload: data.loading,
  };
}

export function getRecursosBolsa(data) {
  return async (dispatch) => {
    dispatch(getRecursosBolsaInit(data));
    try {
      const resp = await API.data.getRecursos(data);
      return dispatch(getRecursosBolsaSuccess(resp));
    } catch (error) {
      return dispatch(getRecursosBolsaFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateRecursoBolsa() {
  return {
    type: RESET_UPDATE_RECURSO_BOLSA,
  };
}

function updateRecursoBolsaSuccess(data) {
  return {
    type: UPDATE_RECURSO_BOLSA_SUCCESS,
    payload: data,
  };
}

function updateRecursoBolsaFailure(error) {
  return {
    type: UPDATE_RECURSO_BOLSA_FAILURE,
    payload: error,
  };
}

function updateRecursoBolsaInit() {
  return {
    type: UPDATE_RECURSO_BOLSA_INIT,
  };
}

export function updateRecursoBolsa(data) {
  return async (dispatch) => {
    dispatch(updateRecursoBolsaInit());
    try {
      const resp = await API.data.updateRecurso(data);
      return dispatch(updateRecursoBolsaSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(updateRecursoBolsaFailure(error));
    }
  };
}
