import React from "react";
import Title from "../../component/add_jornada/title";
import Form from "../../component/add_jornada/form";

const AddJornada = (props) => {
  return (
    <section>
      <Title />
      <Form id={props.match.params.id} />
    </section>
  );
};

export default AddJornada;
