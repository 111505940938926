import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { addUsuario, resetAddUsuario } from "../../actions/add_usuario";

/* Componets */
import FilterCountry from "../common/filterCountry";
import FilterLanguage from "../common/filterLanguage";
import FilterAreas from "../common/filterAreas";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const stateAdd = useSelector((state) => state.addUsuario);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombres: "",
    apellido_paterno: "",
    apellido_materno: "",
    email: "",
    estado: "1",
    uid: "",
    pais_id: "173",
    lenguaje_id: "3",
    area_id: "",
    password: "123456",
    enviar_email_reserva: "1",
    permite_reserva_para_otro: "1",
  });

  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(resetAddUsuario());
    };
  }, [dispatch]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      empresa_id: parseInt(props.id, 10),
      nombres: form.nombres,
      apellido_paterno: form.apellido_paterno,
      apellido_materno: form.apellido_materno,
      email: form.email,
      password: form.password,
      pais_id: form.pais_id,
      uid: form.uid,
      area_id: form.area_id,
      lenguaje_id: form.lenguaje_id,
      estado: form.estado === "1" ? 1 : 0,
      enviar_email_reserva: form.enviar_email_reserva === "1" ? true : false,
      permite_reserva_para_otro:
        form.permite_reserva_para_otro === "1" ? true : false,
    };

    dispatch(addUsuario(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    setForm({
      nombres: "",
      apellido_paterno: "",
      apellido_materno: "",
      email: "",
      estado: "1",
      uid: "",
      pais_id: "173",
      lenguaje_id: "3",
      password: "123456",
      area_id: "",
      enviar_email_reserva: "1",
      permite_reserva_para_otro: "1",
    });
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos usuarios </h1>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Nombres</Form.Label>
              <Form.Control
                value={form.nombres}
                onChange={handleChange}
                type="text"
                name="nombres"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Apellido paterno</Form.Label>
              <Form.Control
                value={form.apellido_paterno}
                onChange={handleChange}
                type="text"
                name="apellido_paterno"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Apellido materno</Form.Label>
              <Form.Control
                value={form.apellido_materno}
                onChange={handleChange}
                type="text"
                name="apellido_materno"
              />
            </Form.Group>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>UID</Form.Label>
              <Form.Control
                value={form.uid}
                onChange={handleChange}
                type="text"
                name="uid"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                value={form.email}
                onChange={handleChange}
                type="text"
                name="email"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                value={form.password}
                onChange={handleChange}
                type="text"
                name="password"
              />
            </Form.Group>
          </div>

          <FilterCountry
            name="pais_id"
            form={form}
            handleChange={handleChange}
          />
          <FilterLanguage
            name="lenguaje_id"
            form={form}
            handleChange={handleChange}
          />
          <FilterAreas
            id_parent={parseInt(props.id, 10)}
            name="area_id"
            form={form}
            handleChange={handleChange}
          />

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Form.Control
                as="select"
                value={form.estado}
                onChange={handleChange}
                name="estado"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Notificar reserva</Form.Label>
              <Form.Control
                as="select"
                value={form.enviar_email_reserva}
                onChange={handleChange}
                name="enviar_email_reserva"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Permite reserva para otro</Form.Label>
              <Form.Control
                as="select"
                value={form.permite_reserva_para_otro}
                onChange={handleChange}
                name="permite_reserva_para_otro"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={stateAdd.loading}
                type="submit"
                variant="warning"
              >
                {stateAdd.loading ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Add" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {stateAdd.status === 200 && !stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={stateAdd.data.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
      {stateAdd.status === 200 && stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyAdded" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
