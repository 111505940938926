import initialState from "./initialState";
import {
  GET_DIVISIONONE_INIT,
  GET_DIVISIONONE_SUCCESS,
  GET_DIVISIONONE_FAILURE,
  RESET_DIVISIONONE,
  UPDATE_DIVISION_INIT,
  UPDATE_DIVISION_SUCCESS,
  UPDATE_DIVISION_FAILURE,
  RESET_UPDATE_DIVISION,
} from "../../actions/update_division/types";

export default function division(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_DIVISIONONE:
      return {
        ...state,
        data: {},
        loading: true,
        status: 0,
      };
    case GET_DIVISIONONE_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_DIVISIONONE_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_DIVISIONONE_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case RESET_UPDATE_DIVISION:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_DIVISION_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_DIVISION_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_DIVISION_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };
    default:
      return state;
  }
}
