import initialState from "./initialState";
import {
  UPDATE_PASSWORD_INIT,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  RESET_UPDATE_PASSWORD,
} from "../../actions/update_password/types";

export default function usuario(state = initialState, action) {
  switch (action.type) {
    case RESET_UPDATE_PASSWORD:
      return {
        ...state,
        dataPassword: {},
        loadingPassword: false,
        statusPassword: 0,
      };
    case UPDATE_PASSWORD_INIT:
      return {
        ...state,
        loadingPassword: true,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        dataPassword: action.payload.data,
        loadingPassword: false,
        statusPassword: action.payload.status,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        dataPassword: {},
        loadingPassword: false,
        statusPassword: 501,
      };
    default:
      return state;
  }
}
