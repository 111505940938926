import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
// Actions
import {
  UpdateGranularity,
  ResetUpdateGranularity,
} from "../../actions/granularidad/api";
// Components
import ModalMessage from "../common/modalMessage";
/* Language */
import { FormattedMessage } from "react-intl";

const FormGranu = (props) => {
  const updateGranu = useSelector((state) => state.UpdateGranularity);

  const dispatch = useDispatch();

  const [form, setForm] = useState({
    valor: props.item.valor,
    granularidad_id: props.item.granularidad_id,
    empresa_id: props.id,
  });

  const arrayWithIds = form.empresa_id;
  const newArray = arrayWithIds.split("-");
  const empresaId = newArray[1];

  const [modalMessage, setModalMessage] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(ResetUpdateGranularity());
    };
  }, [dispatch]);

  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const obj = {
      granularidad_id: form.granularidad_id,
      empresa_id: empresaId,
      valor: form.valor === "1" ? true : false,
    };

    dispatch(UpdateGranularity(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(ResetUpdateGranularity());
  };

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos granularidad</h1>
          </div>
          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Valor</Form.Label>
              <Form.Control
                as="select"
                value={form.valor}
                onChange={handleChange}
                name="valor"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>
          <div className="containerFormButton">
            <div>
              <Button
                disabled={updateGranu.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {updateGranu.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {updateGranu.statusUpdate === 200 &&
        !updateGranu.dataUpdate.ejecucion.estado && (
          <ModalMessage
            message={updateGranu.dataUpdate.ejecucion.mensaje}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={updateGranu.loading}
          />
        )}
      {updateGranu.statusUpdate === 200 &&
        updateGranu.dataUpdate.ejecucion.estado && (
          <ModalMessage
            message={<FormattedMessage id="SuccessfullyUpdated" />}
            modal={modalMessage}
            setModal={setModalMessage}
            func={resetForm}
            loading={updateGranu.loadingUpdate}
          />
        )}
    </React.Fragment>
  );
};

export default FormGranu;
