import React from "react";

/* Components */
import Title from "../../component/update_ventana_usuario/title";
import Form from "../../component/update_ventana_usuario/form";

const UpdateVentanaUsuarioPage = (props) => {
  const ids = props.match.params.id.split("-");
  return (
    <section>
      <Title />
      <Form id={ids[0]} company={ids[1]} />
    </section>
  );
};

export default UpdateVentanaUsuarioPage;
