import axios from "axios";

/* Get Areas*/
export const GetAreas = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_AREAS_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/areas/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_AREAS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_AREAS_FAIL",
    });
    console.log("Error en getAreas Action: veni a ver el desmadre", err);
  }
};

/* Reset Areas */
export const resetAreas = () => {
  return {
    type: "RESET_AREAS",
  };
};

export const DeleteArea = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_AREA_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/areas/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_AREA_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_AREA_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const ResetDeleteArea = () => {
  return {
    type: "RESET_DELETE_AREA",
  };
};
