import {
  GET_ATRIBUTOS_BOLSA_INIT,
  GET_ATRIBUTOS_BOLSA_SUCCESS,
  GET_ATRIBUTOS_BOLSA_FAILURE,
  RESET_ATRIBUTOS_BOLSA,
  UPDATE_ATRIBUTO_BOLSA_INIT,
  UPDATE_ATRIBUTO_BOLSA_SUCCESS,
  UPDATE_ATRIBUTO_BOLSA_FAILURE,
  RESET_UPDATE_ATRIBUTO_BOLSA,
} from "./types";
import API from "./api";

export function resetAtributosBolsa() {
  return {
    type: RESET_ATRIBUTOS_BOLSA,
  };
}

function getAtributosBolsaSuccess(data) {
  return {
    type: GET_ATRIBUTOS_BOLSA_SUCCESS,
    payload: data,
  };
}

function getAtributosBolsaFailure(error) {
  console.log(error);
  return {
    type: GET_ATRIBUTOS_BOLSA_FAILURE,
    payload: error,
  };
}

function getAtributosBolsaInit(data) {
  return {
    type: GET_ATRIBUTOS_BOLSA_INIT,
    payload: data.loading,
  };
}

export function getAtributosBolsa(data) {
  return async (dispatch) => {
    dispatch(getAtributosBolsaInit(data));
    try {
      const resp = await API.data.getAtributos(data);
      return dispatch(getAtributosBolsaSuccess(resp));
    } catch (error) {
      return dispatch(getAtributosBolsaFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateAtributoBolsa() {
  return {
    type: RESET_UPDATE_ATRIBUTO_BOLSA,
  };
}

function updateAtributoBolsaSuccess(data) {
  return {
    type: UPDATE_ATRIBUTO_BOLSA_SUCCESS,
    payload: data,
  };
}

function updateAtributoBolsaFailure(error) {
  return {
    type: UPDATE_ATRIBUTO_BOLSA_FAILURE,
    payload: error,
  };
}

function updateAtributoBolsaInit() {
  return {
    type: UPDATE_ATRIBUTO_BOLSA_INIT,
  };
}

export function updateAtributoBolsa(data) {
  return async (dispatch) => {
    dispatch(updateAtributoBolsaInit());
    try {
      const resp = await API.data.updateAtributos(data);
      return dispatch(updateAtributoBolsaSuccess(resp));
    } catch (error) {
      console.log(error);
      return dispatch(updateAtributoBolsaFailure(error));
    }
  };
}
