import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import add from "../../assets/img/add.svg";

/* Actions */
import {
  GetUsuarios,
  resetUsuarios,
  DeleteUsuario,
  resetDeleteUsuario,
  AddRoles,
  resetAddRoles,
} from "../../actions/usuarios/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";
import ModalRoles from "../common/modalRoles";
import ModalDeleteRoles from "../common/modalDeleteRoles";
import FilterCompany from "../common/filterCompany";

const Usuarios = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: localStorage.getItem("user.empresa_id")
      ? localStorage.getItem("user.empresa_id")
      : "",
    role: "",
  });
  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [idDelete, setIdDelete] = useState(false);
  const [usuarios, setUsuarios] = useState([]);

  /* States */
  const getUsuarios = useSelector((state) => state.GetUsuarios);

  useEffect(() => {
    const FetchData = () => {
      dispatch(
        GetUsuarios({
          empresa_id: parseInt(localStorage.getItem("user.empresa_id"), 10),
        })
      );
    };
    if (
      localStorage.getItem("user.empresa_id") &&
      localStorage.getItem("user.empresa_id") !== ""
    ) {
      FetchData();
    }
  }, [dispatch]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetUsuarios());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };

  const handleModalAdd = (obj) => {
    setModalAdd(true);
    setIdDelete(obj.id);
  };

  const handleModalDelete = (obj) => {
    setModalDelete(true);
    setIdDelete(obj.id);
  };

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setUsuarios([]);
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    if (event.target.name === "id") {
      if (event.target.value !== "") {
        localStorage.setItem("user.empresa_id", event.target.value);
        dispatch(GetUsuarios({ empresa_id: parseInt(event.target.value, 10) }));
      } else {
        localStorage.setItem("user.empresa_id", "");
        dispatch(resetUsuarios());
      }
    }
  };

  /* Delete entidad */
  const submitDelete = () => {
    dispatch(DeleteUsuario({ user_id: idDelete }));
  };

  /* Reset el estado de delete*/
  const submitResetDelete = () => {
    dispatch(resetDeleteUsuario());
    if (getUsuarios.statusDelete === 200) {
      if (getUsuarios.dataDelete.ejecucion.estado) {
        dispatch(GetUsuarios({ empresa_id: form.id }));
      }
    }
  };

  /* Add roles*/
  const submitAddRoles = (type) => {
    if (type === "add") {
      dispatch(AddRoles({ user_id: idDelete, rol_id: form.role, type: type }));
    } else {
      dispatch(AddRoles({ id: form.role, type: type }));
    }
  };

  /* Reset el estado de roles*/
  const submitResetAddRoles = () => {
    dispatch(resetAddRoles());
    if (getUsuarios.statusAddRoles === 200) {
      if (getUsuarios.dataAddRoles.ejecucion.estado) {
        dispatch(GetUsuarios({ empresa_id: form.id }));
      }
    }
  };

  const addUsuarios = (division) => {
    const arr = [...usuarios];

    const index = arr.findIndex((o) => o.user_id === division.user_id);

    if (index < 0) {
      arr.push(division);
    } else {
      arr.splice(index, 1);
    }
    setUsuarios(arr);
  };

  const allUsuarios = () => {
    const arr = [];
    if (!_.isEmpty(getUsuarios.data)) {
      if (usuarios.length === getUsuarios.data.length) {
        setUsuarios([]);
      } else {
        getUsuarios.data.forEach((element) => {
          arr.push(element);
        });
      }

      setUsuarios(arr);
    } else {
      setUsuarios([]);
    }
  };

  const showUsuarios = () => {
    if (getUsuarios.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getUsuarios.data)) {
      return (
        <div className="containerAllUsuarios">
          {getUsuarios.data.map((item, key) => {
            const index = usuarios.findIndex((o) => o.user_id === item.user_id);
            return (
              <div className="eachUsuario" key={key}>
                <div className="leftSideEachUser">
                  <p className="containerTableId">{item.user_id}</p>
                  <p className="containerTable">{item.email}</p>
                  <p className="containerTable">{item.nombre_completo}</p>
                  <p className="containerTable">{item.area_nombre}</p>
                  <p className="containerTable">
                    <button
                      onClick={() => addUsuarios(item)}
                      className={index >= 0 ? "activeButton" : "normalButton"}
                    >
                      Seleccionar
                    </button>
                  </p>
                  <p className="containerTable">
                    {item.enviar_email_reserva ? "Si" : "No"}
                  </p>
                  <Link
                    className="containerTableMore"
                    to={{
                      pathname: `/dashboard/update_usuario/${item.user_id}`,
                      state: {
                        items: form.id,
                      },
                    }}
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                  <div className="containerTableMore">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li
                            onClick={() => handleModalAdd({ id: item.user_id })}
                          >
                            Agregar Role
                          </li>
                          <li
                            onClick={() =>
                              handleModalDelete({ id: item.user_id })
                            }
                          >
                            Eliminar Role
                          </li>
                          <li>
                            <Link
                              to={{
                                pathname: `/dashboard/accesos/${form.id}-${0}-${
                                  item.user_id
                                }`,
                                state: {
                                  users: usuarios,
                                },
                              }}
                            >
                              Accesos
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="containerTableMore"
                              to={{
                                pathname: `/dashboard/update_password/${item.user_id}`,
                                state: {
                                  items: form.id,
                                },
                              }}
                            >
                              Cambio de password
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleModal({ id: item.user_id });
                  }}
                  className="rightSideEachUser"
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getUsuarios.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };

  return (
    <React.Fragment>
      <div className="containerUsuarios">
        <ModalDelete
          func={() => {
            submitDelete();
          }}
          state={getUsuarios}
          modal={modal}
          reset={submitResetDelete}
          setModal={setModal}
        />
        <ModalRoles
          func={() => {
            submitAddRoles("add");
          }}
          form={form}
          state={getUsuarios}
          modal={modalAdd}
          handleChange={handleChange}
          reset={submitResetAddRoles}
          setModal={setModalAdd}
        />
        <ModalDeleteRoles
          func={() => {
            submitAddRoles("delete");
          }}
          form={form}
          state={getUsuarios}
          modal={modalDelete}
          handleChange={handleChange}
          reset={submitResetAddRoles}
          setModal={setModalDelete}
          userId={idDelete}
        />
        <p className="titleUsuario">Usuarios</p>
        <FilterCompany name="id" form={form} handleChange={handleChange} />

        <section className="containerMasivo">
          <span className="cargadorMasivo">
            <Link to={`/dashboard/load_usuario/`}>
              <i className="fas fa-file-excel"></i> Cargador masivo de usuarios
            </Link>
          </span>
          <span className="cargadorMasivo">
            <Link to={`/dashboard/add_user_excel/`}>
              <i className="fas fa-file-excel"></i> Cargador masivo de usuarios
              con validador
            </Link>
          </span>
          <span className="cargadorMasivo" onClick={allUsuarios}>
            <a>
              <i className="fas fa-user"></i> Seleccionar todo
            </a>
          </span>
        </section>

        <div className="lineGray"></div>
        <div className="containerSubTitleTable">
          <div className="leftSideTable">
            <p className="containerTableID">ID</p>
            <p className="containerTable">Email</p>

            <p className="containerTable">Nombre</p>
            <p className="containerTable">Area</p>
            <p className="containerTable">Seleccionar</p>
            <p className="containerTable">Mail</p>
            <p className="containerTableMore">Editar</p>
            <p className="containerTableMore">Más</p>
          </div>
          <div className="rightSideTable">
            <p>Eliminar</p>
          </div>
        </div>
        {showUsuarios()}
      </div>
      <Link to={`/dashboard/add_usuario/${form.id}`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Usuarios;
