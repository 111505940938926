const initialState = {
  data: [],
  loading: false,
  errorMsg: "",
};

const GetVentanaReservaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VENTANAS_RESERVA_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_VENTANAS_RESERVA":
      return {
        ...state,
        data: [],
        loading: false,
        errorMsg: "",
      };
    case "GET_VENTANAS_RESERVA_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_VENTANAS_RESERVA_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
      };

    default:
      return state;
  }
};

export default GetVentanaReservaReducer;
