const initialState = {
  data: [],
  loading: true,
  errorMsg: "",
  dataDelete: [],
  loadingDelete: false,
  statusDelete: 0,
  errorMsgDelete: "",
  dataCheck: [],
  loadingCheck: false,
  statusCheck: 0,
  errorMsgCheck: "",
  dataBlock: [],
  loadingBlock: false,
  statusBlock: 0,
  errorMsgBlock: "",
  dataActive: [],
  loadingActive: false,
  statusActive: 0,
  errorMsgActive: "",
};

const GetCompaniesReducer = (state = initialState, action) => {
  switch (action.type) {
    /* Gets */
    case "RESET_COMPANIES":
      return {
        ...state,
        data: [],
        loading: true,
        errorMsg: "",
      };
    case "GET_COMPANIES_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "GET_COMPANIES_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_COMPANIES_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
      };
    /* Delete */
    case "RESET_DELETE_COMPANY":
      return {
        ...state,
        dataDelete: [],
        loadingDelete: false,
        statusDelete: 0,
        errorMsgDelete: "",
      };
    case "DELETE_COMPANY_FAIL":
      return {
        ...state,
        loadingDelete: false,
        statusDelete: 401,
        errorMsgDelete: "cant bring data",
      };
    case "DELETE_COMPANY_LOADING":
      return {
        ...state,
        loadingDelete: true,
        errorMsgDelete: "",
      };
    case "DELETE_COMPANY_SUCESS":
      return {
        ...state,
        loadingDelete: false,
        dataDelete: action.payload,
        errorMsgDelete: "",
        statusDelete: action.status,
      };
    /* Check */
    case "RESET_COMPANIES_CHECK":
      return {
        ...state,
        dataCheck: [],
        loadingCheck: false,
        statusCheck: 0,
        errorMsgCheck: "",
      };
    case "GET_COMPANIES_CHECK_LOADING":
      return {
        ...state,
        loadingCheck: true,
        errorMsgCheck: "",
      };
    case "GET_COMPANIES_CHECK_SUCESS":
      return {
        ...state,
        loadingCheck: false,
        dataCheck: action.payload,
        errorMsgCheck: "",
        statusCheck: action.status,
      };
    case "GET_COMPANIES_CHECK_FAIL":
      return {
        ...state,
        loadingCheck: false,
        statusCheck: 401,
        errorMsgCheck: "cant bring data",
      };
    /* Check */
    case "RESET_COMPANIES_BLOCK":
      return {
        ...state,
        dataBlock: [],
        loadingBlock: false,
        statusBlock: 0,
        errorMsgBlock: "",
      };
    case "GET_COMPANIES_BLOCK_LOADING":
      return {
        ...state,
        loadingBlock: true,
        errorMsgBlock: "",
      };
    case "GET_COMPANIES_BLOCK_SUCESS":
      return {
        ...state,
        loadingBlock: false,
        dataBlock: action.payload,
        errorMsgBlock: "",
        statusBlock: action.status,
      };
    case "GET_COMPANIES_BLOCK_FAIL":
      return {
        ...state,
        loadingBlock: false,
        statusBlock: 401,
        errorMsgBlock: "cant bring data",
      };
    /* Check */
    case "RESET_COMPANIES_ACTIVE":
      return {
        ...state,
        dataActive: [],
        loadingActive: false,
        statusActive: 0,
        errorMsgActive: "",
      };
    case "GET_COMPANIES_ACTIVE_LOADING":
      return {
        ...state,
        loadingActive: true,
        errorMsgActive: "",
      };
    case "GET_COMPANIES_ACTIVE_SUCESS":
      return {
        ...state,
        loadingActive: false,
        dataActive: action.payload,
        errorMsgActive: "",
        statusActive: action.status,
      };
    case "GET_COMPANIES_ACTIVE_FAIL":
      return {
        ...state,
        loadingActive: false,
        statusActive: 401,
        errorMsgActive: "cant bring data",
      };
    default:
      return state;
  }
};

export default GetCompaniesReducer;
