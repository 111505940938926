import React from "react";
//import { FormattedMessage } from "react-intl";

/* Components */
import Title from "../../component/add_atributo/title";
import Form from "../../component/add_atributo/form";

const AddAtributoPage = (props) => {
  return (
    <section>
      <Title />
      <Form id={props.match.params.id} />
    </section>
  );
};

export default AddAtributoPage;
