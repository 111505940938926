import axios from "axios";

const API = {
  data: {
    async handleUserUpload(item) {
      const fullPath = document.getElementById("recfile").files;
      let formData = new FormData();
      formData.append("recfile", fullPath[0]);
      formData.append("lenguaje", item.lenguaje);
      formData.append("activo", item.activo);

      const response = await axios({
        method: "post",
        url: "/api/usuarios/upload",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      return { data: response.data, status: response.status };
    },
  },
};
export default API;
