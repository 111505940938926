import axios from "axios";

const API = {
  data: {
    async getPregunta(obj) {
      const response = await axios.get(
        `/api/pregunta/${encodeURIComponent(JSON.stringify(obj))}`
      );
      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
    async updatePregunta(obj) {
      const response = await axios.put(`/api/preguntas`, obj);

      const { status } = response;
      let data = await response.data;

      return {
        data,
        status,
      };
    },
  },
};
export default API;
