import initialState from "./initialState";
import {
  GET_RECURSOS_BOLSA_INIT,
  GET_RECURSOS_BOLSA_SUCCESS,
  GET_RECURSOS_BOLSA_FAILURE,
  RESET_RECURSOS_BOLSA,
  UPDATE_RECURSO_BOLSA_INIT,
  UPDATE_RECURSO_BOLSA_SUCCESS,
  UPDATE_RECURSO_BOLSA_FAILURE,
  RESET_UPDATE_RECURSO_BOLSA,
} from "../../actions/update_division_recurso/types";

export default function divisionAtributos(state = initialState, action) {
  switch (action.type) {
    /* Country */
    case RESET_RECURSOS_BOLSA:
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case GET_RECURSOS_BOLSA_INIT:
      return {
        ...state,
        loading: true,
      };
    case GET_RECURSOS_BOLSA_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        loading: false,
        status: action.payload.status,
      };
    case GET_RECURSOS_BOLSA_FAILURE:
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case RESET_UPDATE_RECURSO_BOLSA:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
      };
    case UPDATE_RECURSO_BOLSA_INIT:
      return {
        ...state,
        loadingUpdate: true,
      };
    case UPDATE_RECURSO_BOLSA_SUCCESS:
      return {
        ...state,
        dataUpdate: action.payload.data,
        loadingUpdate: false,
        statusUpdate: action.payload.status,
      };
    case UPDATE_RECURSO_BOLSA_FAILURE:
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
      };
    default:
      return state;
  }
}
