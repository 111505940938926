import axios from "axios";

/* Get Edificaciones*/
export const GetEdificaciones = (obj) => async (dispatch) => {
  dispatch({
    type: "GET_EDIFICACIONES_LOADING",
  });
  try {
    const response = await axios.get(
      `/api/edificaciones/${encodeURIComponent(JSON.stringify(obj))}`
    );
    // const { status } = response;
    let data = await response.data;
    dispatch({
      type: "GET_EDIFICACIONES_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_EDIFICACIONES_FAIL",
    });
    console.log("Error en getEdificacionesAction: veni a ver el desmadre", err);
  }
};

/* Reset Edificaciones */
export const resetEdificaciones = () => {
  return {
    type: "RESET_EDIFICACIONES",
  };
};

export const DeleteEdificacion = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_EDIFICACION_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/edificaciones/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "DELETE_EDIFICACION_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_EDIFICACION_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetDeleteEdificacion = () => {
  return {
    type: "RESET_DELETE_EDIFICACION",
  };
};

export const AsignarEdificacion = (obj) => async (dispatch) => {
  dispatch({
    type: "ASIGNAR_EDIFICACION_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/asignar/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "ASIGNAR_EDIFICACION_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "ASIGNAR_EDIFICACION_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetAsignarEdificacion = () => {
  return {
    type: "RESET_ASIGNAR_EDIFICACION",
  };
};

export const AsignarBolsas = (obj) => async (dispatch) => {
  dispatch({
    type: "ASIGNAR_BOLSAS_LOADING",
  });
  try {
    const response = await axios.delete(
      `/api/asignarbolsas/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "ASIGNAR_BOLSAS_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "ASIGNAR_BOLSAS_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetAsignarBolsas = () => {
  return {
    type: "RESET_ASIGNAR_BOLSAS",
  };
};

/* Obtener qr*/

export const ObtenerQr = (obj) => async (dispatch) => {
  dispatch({
    type: "OBTENER_QR_LOADING",
  });
  try {
    const response = await axios.post(`/api/edificaciones/qr`, obj);
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "OBTENER_QR_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "OBTENER_QR_FAIL",
    });
    console.log("Error en delete cargo: veni a ver el desmadre", err);
  }
};

/* Reset Companies */
export const resetQr = () => {
  return {
    type: "RESET_QR",
  };
};
