import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import { getLanguage, resetLanguage } from "../../actions/filters";
import {
  addAlternativa,
  resetAddAlternativa,
} from "../../actions/add_alternativa";

import {
  getAtributosBolsa,
  resetAtributosBolsa,
} from "../../actions/update_division_atributos";

/* Componets */

import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.filters);
  const stateAdd = useSelector((state) => state.addAlternativa);
  const stateUpdate = useSelector((state) => state.updateDivisionAtributos);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    q_invitados: "",
    clave: "",
    pide_invitados: "",
  });

  const [modalMessage, setModalMessage] = useState(false);
  const [nombres, setNombres] = useState([]);
  const [atributos, setAtributos] = useState([]);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getLanguage({ loading: true }));
      dispatch(
        getAtributosBolsa({
          bolsa_atributo_id: parseInt(props.idBolsa, 10),
        })
      );
    };
    callsAPI();
  }, [dispatch, props.idBolsa]);

  useEffect(() => {
    return () => {
      dispatch(resetAtributosBolsa());
      dispatch(resetLanguage());
      dispatch(resetAddAlternativa());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.statusLanguage === 200) {
        if (state.dataLanguage.ejecucion.estado) {
          const list_name = [];
          state.dataLanguage.datos.forEach((item, i) => {
            list_name.push({
              value: "",
              index: `name-${i}`,
              id: item.id,
              label: item.glosa,
            });
          });
          setNombres(list_name);
        }
      }
    };
    callsAPI();
  }, [
    dispatch,
    state.loadingLanguage,
    state.dataLanguage,
    state.statusLanguage,
  ]);

  useEffect(() => {
    const callsAPI = async () => {
      if (stateUpdate.status === 200) {
        if (stateUpdate.data.ejecucion.estado) {
          const list_name = [];
          stateUpdate.data.datos.forEach((item, i) => {
            list_name.push({
              value: "",
              index: `name-${i}`,
              id: item.id,
              label: item.nombre,
            });
          });
          setAtributos(list_name);
        }
      }
    };
    callsAPI();
  }, [dispatch, stateUpdate]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Para cambiar los nombres */
  const handleNombres = (event) => {
    event.persist();
    let list_name = [...nombres];
    const index = nombres.findIndex((item) => item.index === event.target.name);
    list_name[index].value = event.target.value;
    setNombres(list_name);
  };

  const handleAtributos = (event) => {
    event.persist();
    let list_name = [...atributos];
    const index = atributos.findIndex(
      (item) => item.index === event.target.name
    );
    list_name[index].value = event.target.value;
    setAtributos(list_name);
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const nombresArr = [];
    nombres.forEach((item) => {
      nombresArr.push({ lenguaje_id: item.id, glosa: item.value });
    });
    const atributosArr = [];
    atributos.forEach((item) => {
      atributosArr.push({
        atributo_id: item.id,
        seleccionado: item.value === "1" ? true : false,
      });
    });

    const obj = {
      pregunta_id: parseInt(props.id, 10),
      nombre: form.nombre,
      clave: form.clave,
      q_invitados: parseInt(form.q_invitados, 10),
      pide_invitados: form.pide_invitados === "1" ? true : false,
      atributos_seleccionados: atributosArr,
      glosa: nombresArr,
    };

    dispatch(addAlternativa(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    setForm({
      nombre: "",
      q_invitados: "",
      clave: "",
      pide_invitados: "",
    });
    const list_name = [];
    state.dataLanguage.datos.forEach((item, i) => {
      list_name.push({
        value: "",
        index: `name-${i}`,
        id: item.id,
        label: item.glosa,
      });
    });
    setNombres(list_name);
  };

  if (state.loadingLanguage || stateUpdate.loading) {
    return <Loading />;
  } else if (state.statusLanguage === 501 || stateUpdate.status === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.statusLanguage === 200) {
    if (!state.dataLanguage.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos alternativa</h1>
          </div>
          {nombres.map((item) => {
            return (
              <div className="containerFormBox" key={item.id}>
                <Form.Group>
                  <Form.Label>
                    {item.label} <FormattedMessage id="Name" /> <span>hola marrano</span>
                  </Form.Label>
                  <Form.Control
                    value={item.value}
                    onChange={handleNombres}
                    type="text"
                    name={item.index}
                  />
                </Form.Group>
              </div>
            );
          })}

          {atributos.map((item) => {
            return (
              <div className="containerFormBox" key={item.id}>
                <Form.Group>
                  <Form.Label>Tiene atributo {item.label}? <span>hola marrano</span></Form.Label>
                  <Form.Control
                    value={item.value}
                    onChange={handleAtributos}
                    type="text"
                    name={item.index}
                    as="select"
                  >
                    <FormattedMessage id="Select">
                      {(message) => <option value="">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="No">
                      {(message) => <option value="0">{message}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="Yes">
                      {(message) => <option value="1">{message}</option>}
                    </FormattedMessage>
                  </Form.Control>
                </Form.Group>
              </div>
            );
          })}

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Nombre <span>hola marrano</span></Form.Label>
              <Form.Control
                value={form.nombre}
                onChange={handleChange}
                type="text"
                name="nombre"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Clave <span>hola marrano</span></Form.Label>
              <Form.Control
                value={form.clave}
                onChange={handleChange}
                type="text"
                name="clave"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Pide invitados <span>hola marrano</span></Form.Label>
              <Form.Control
                as="select"
                value={form.pide_invitados}
                onChange={handleChange}
                name="pide_invitados"
                requeried="true"
              >
                <FormattedMessage id="Select">
                  {(message) => <option value="">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="No">
                  {(message) => <option value="0">{message}</option>}
                </FormattedMessage>
                <FormattedMessage id="Yes">
                  {(message) => <option value="1">{message}</option>}
                </FormattedMessage>
              </Form.Control>
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Cantidad invitados <span>hola marrano</span></Form.Label>
              <Form.Control
                value={form.q_invitados}
                onChange={handleChange}
                type="number"
                name="q_invitados"
              />
            </Form.Group>
          </div>

          <div className="containerFormButton">
            <div>
              <Button
                disabled={stateAdd.loading}
                type="submit"
                variant="warning"
              >
                {stateAdd.loading ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Add" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {stateAdd.status === 200 && !stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={stateAdd.data.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
      {stateAdd.status === 200 && stateAdd.data.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyAdded" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={stateAdd.loading}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
