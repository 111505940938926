import {
  GET_USUARIO_INIT,
  GET_USUARIO_SUCCESS,
  GET_USUARIO_FAILURE,
  RESET_USUARIO,
  UPDATE_USUARIO_INIT,
  UPDATE_USUARIO_SUCCESS,
  UPDATE_USUARIO_FAILURE,
  RESET_UPDATE_USUARIO,
} from "./types";
import API from "./api";

export function resetUsuario() {
  return {
    type: RESET_USUARIO,
  };
}

function getUsuarioSuccess(data) {
  return {
    type: GET_USUARIO_SUCCESS,
    payload: data,
  };
}

function getUsuarioFailure(error) {
  console.log(error);
  return {
    type: GET_USUARIO_FAILURE,
    payload: error,
  };
}

function getUsuarioInit(data) {
  return {
    type: GET_USUARIO_INIT,
    payload: data.loading,
  };
}

export function getUsuario(data) {
  return async (dispatch) => {
    dispatch(getUsuarioInit(data));
    try {
      const resp = await API.data.getUsuario(data);
      return dispatch(getUsuarioSuccess(resp));
    } catch (error) {
      return dispatch(getUsuarioFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateUsuario() {
  return {
    type: RESET_UPDATE_USUARIO,
  };
}

function updateUsuarioSuccess(data) {
  return {
    type: UPDATE_USUARIO_SUCCESS,
    payload: data,
  };
}

function updateUsuarioFailure(error) {
  return {
    type: UPDATE_USUARIO_FAILURE,
    payload: error,
  };
}

function updateUsuarioInit() {
  return {
    type: UPDATE_USUARIO_INIT,
  };
}

export function updateUsuario(data) {
  return async (dispatch) => {
    dispatch(updateUsuarioInit());
    try {
      const resp = await API.data.updateUsuario(data);
      return dispatch(updateUsuarioSuccess(resp));
    } catch (error) {
      return dispatch(updateUsuarioFailure(error));
    }
  };
}
