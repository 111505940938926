import axios from "axios";
// Get
export const GetGroups = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_GROUPS_LOADING",
    });

    const response = await axios.get(
      `/api/group/${encodeURIComponent(JSON.stringify(obj))}`
    );
    let data = await response.data;
    dispatch({
      type: "GET_GROUPS_SUCESS",
      payload: data.datos,
    });
  } catch (err) {
    dispatch({
      type: "GET_GROUPS_FAIL",
    });
    console.log("Error en GETGROUPS ACTION: veni a ver el desmadre", err);
  }
};
export const resetGroups = () => {
  return {
    type: "RESET_GROUPS",
  };
};

// Delete
export const DeleteGroups = (obj) => async (dispatch) => {
  dispatch({
    type: "DELETE_GROUPS_LOADING",
  });
  try {
    const res = await axios.delete(
      `/api/group/${encodeURIComponent(JSON.stringify(obj))}`
    );
    const { status } = res;
    let data = await res.data;
    dispatch({
      type: "DELETE_GROUPS_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "DELETE_GROUPS_FAIL",
    });
    console.log("Error en delete group u.u", err);
  }
};
export const ResetDeleteGroup = () => {
  return {
    type: "RESET_DELETE_GROUP",
  };
};

// Update
export const UpdateGroup = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: "UPDATE_GROUPS_LOADING",
    });

    const response = await axios.put(`/api/group/`, obj);
    const { status } = response;
    let data = await response.data;

    dispatch({
      type: "UPDATE_GROUPS_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "UPDATE_GROUPS_FAIL",
    });
    console.log("Error en UPDATEGROUPS ACTION: veni a ver el desmadre", err);
  }
};
export const ResetUpdateGroup = () => {
  return {
    type: "RESET_UPDATE_GROUPS",
  };
};

// Add
export const AddGroup = (obj) => async (dispatch) => {
  try {
    dispatch({
      type: "ADD_GROUPS_LOADING",
    });

    const response = await axios.post(`/api/group`, obj);
    const { status } = response;
    let data = await response.data;
    dispatch({
      type: "ADD_GROUPS_SUCESS",
      payload: data,
      status,
    });
  } catch (err) {
    dispatch({
      type: "ADD_GROUPS_FAIL",
    });
    console.log("Error en ADDGROUPS ACTION: veni a ver el desmadre", err);
  }
};
export const ResetAddGroup = () => {
  return {
    type: "RESET_ADD_GROUPS",
  };
};
