import {
  GET_CREDITO_INIT,
  GET_CREDITO_SUCCESS,
  GET_CREDITO_FAILURE,
  RESET_CREDITO,
  UPDATE_CREDITO_INIT,
  UPDATE_CREDITO_SUCCESS,
  UPDATE_CREDITO_FAILURE,
  RESET_UPDATE_CREDITO,
} from "./types";
import API from "./api";

export function resetCredito() {
  return {
    type: RESET_CREDITO,
  };
}

function getCreditoSuccess(data) {
  return {
    type: GET_CREDITO_SUCCESS,
    payload: data,
  };
}

function getCreditoFailure(error) {
  console.log(error);
  return {
    type: GET_CREDITO_FAILURE,
    payload: error,
  };
}

function getCreditoInit(data) {
  return {
    type: GET_CREDITO_INIT,
    payload: data.loading,
  };
}

export function getCredito(data) {
  return async (dispatch) => {
    dispatch(getCreditoInit(data));
    try {
      const resp = await API.data.getCredito(data);
      return dispatch(getCreditoSuccess(resp));
    } catch (error) {
      return dispatch(getCreditoFailure(error));
    }
  };
}

/* Update  */

export function resetUpdateCredito() {
  return {
    type: RESET_UPDATE_CREDITO,
  };
}

function updateCreditoSuccess(data) {
  return {
    type: UPDATE_CREDITO_SUCCESS,
    payload: data,
  };
}

function updateCreditoFailure(error) {
  return {
    type: UPDATE_CREDITO_FAILURE,
    payload: error,
  };
}

function updateCreditoInit() {
  return {
    type: UPDATE_CREDITO_INIT,
  };
}

export function updateCredito(data) {
  return async (dispatch) => {
    dispatch(updateCreditoInit());
    try {
      const resp = await API.data.updateCredito(data);
      return dispatch(updateCreditoSuccess(resp));
    } catch (error) {
      return dispatch(updateCreditoFailure(error));
    }
  };
}
