const initialState = {
  data: {},
  loading: false,
  status: 0,
};
const AddGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_ADD_GROUPS":
      return {
        ...state,
        data: {},
        loading: false,
        status: 0,
      };
    case "ADD_GROUPS_FAIL":
      return {
        ...state,
        data: {},
        loading: false,
        status: 501,
      };
    case "ADD_GROUPS_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "ADD_GROUPS_SUCESS":
      return {
        ...state,
        data: action.payload,
        loading: false,
        status: action.status,
      };

    default:
      return state;
  }
};

export default AddGroupReducer;
