const initialState = {
  dataUpdate: {},
  loadingUpdate: false,
  statusUpdate: 0,
  errorMsg: "",
};

const UpdateGranularityReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_GRANULARITY_LOADING":
      return {
        ...state,
        loadingUpdate: true,
      };
    case "RESET_UPDATE_GRANULARITY":
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 0,
        errorMsg: "",
      };
    case "UPDATE_GRANULARITY_SUCESS":
      return {
        ...state,
        dataUpdate: action.payload,
        loadingUpdate: false,
        statusUpdate: action.status,
      };
    case "UPDATE_GRANULARITY_FAIL":
      return {
        ...state,
        dataUpdate: {},
        loadingUpdate: false,
        statusUpdate: 501,
        errorMsg: "can't bring data",
      };
    default:
      return state;
  }
};

export default UpdateGranularityReducer;
