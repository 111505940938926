// Cargos ocupa el style de groups.scss
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import add from "../../assets/img/add.svg";

/* Actions */
import {
  GetCargos,
  resetCargos,
  DeleteCargo,
  resetDeleteCargo,
} from "../../actions/cargos/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";
import FilterCompany from "../common/filterCompany";

const Cargos = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    id: "",
  });
  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [idDelete, setIdDelete] = useState(false);

  /* States */
  const getCargos = useSelector((state) => state.GetCargos);

  useEffect(() => {
    const FetchData = () => {};
    FetchData();
  }, [dispatch]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetCargos());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    if (event.target.value !== "") {
      dispatch(GetCargos({ empresa_id: parseInt(event.target.value, 10) }));
    } else {
      dispatch(resetCargos());
    }
  };

  /* Delete entidad */
  const submitDelete = () => {
    dispatch(DeleteCargo({ id: idDelete }));
  };

  /* Reset el estado de delete*/
  const submitResetDelete = () => {
    dispatch(resetDeleteCargo());
    if (getCargos.statusDelete === 200) {
      if (getCargos.dataDelete.ejecucion.estado) {
        dispatch(GetCargos({ empresa_id: form.id }));
      }
    }
  };

  const showCargos = () => {
    if (getCargos.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getCargos.data)) {
      return (
        <div className="containerAllGroups">
          {getCargos.data.map((item, key) => {
            return (
              <div className="eachGroups" key={key}>
                <div className="leftSideEachGroups">
                  <p className="containerTableID">{item.id}</p>
                  <p className="containerSubTableGroupsName">{item.nombre}</p>
                  <Link
                    className="containerSubTableGroups"
                    to={{
                      pathname: `/dashboard/update_cargo/${item.id}`,
                      state: {
                        items: item,
                      },
                    }}
                  >
                    <div className="containerSubTableGroups">
                      <i className="far fa-edit"></i>
                    </div>
                  </Link>

                  <div className="containerSubTableGroups">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li>Cargos</li>
                          <li>Cargos</li>
                          <li>Cargos</li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleModal({ id: item.id });
                  }}
                  className="rightSideEachGroups"
                >
                  <i className="far fa-trash-alt"></i>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getCargos.errorMsg !== "") {
      /* Aquí va el cerrar sesión */
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <React.Fragment>
      <div className="containerGroups">
        <ModalDelete
          func={() => {
            submitDelete();
          }}
          state={getCargos}
          modal={modal}
          reset={submitResetDelete}
          setModal={setModal}
        />
        <p className="titleGroups">Cargos</p>
        <FilterCompany name="id" form={form} handleChange={handleChange} />
        <div className="lineGray"></div>
        <div className="containerTableGroups">
          <div className="leftSideTableGroups">
            <p className="titleLeftSideTableGroupsID">ID</p>
            <p className="titleLeftSideTableGroups">Nombre</p>
            <p className="titleLeftSideTableGroups">Editar</p>
            <p className="titleLeftSideTableGroups">Más</p>
          </div>
          <div className="rightSideTableGroups">
            <p>Eliminar</p>
          </div>
        </div>
        {showCargos()}
      </div>
      <Link to={`/dashboard/add_cargo/${form.id}`}>
        <div className="containerAdd">
          <img alt="icon" className="imgAdd" src={add} />
        </div>
      </Link>
    </React.Fragment>
  );
};

export default Cargos;
