const initialState = {
  data: [],
  loading: true,
  errorMsg: "",
  loadingActive: false,
  statusActive: 0,
  errorMsgActive: "",
};

const GetRecursosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ACCESOS_RECURSOS_LOADING":
      return {
        ...state,
        loading: true,
        errorMsg: "",
      };
    case "RESET_ACCESOS_RECURSOS":
      return {
        ...state,
        data: [],
        loading: false,
        errorMsg: "",
      };
    case "GET_ACCESOS_RECURSOS_SUCESS":
      return {
        ...state,
        loading: false,
        data: action.payload,
        errorMsg: "",
      };
    case "GET_ACCESOS_RECURSOS_FAIL":
      return {
        ...state,
        loading: false,
        errorMsg: "cant bring data",
      };
    /* Delete */
    /* Delete */
    case "RESET_ACTIVE_RECURSO":
      return {
        ...state,
        dataActive: [],
        loadingActive: false,
        statusActive: 0,
        errorMsgActive: "",
      };
    case "ACTIVE_RECURSO_FAIL":
      return {
        ...state,
        loadingActive: false,
        statusActive: 401,
        errorMsgActive: "cant bring data",
      };
    case "ACTIVE_RECURSO_LOADING":
      return {
        ...state,
        loadingActive: true,
        errorMsgActive: "",
      };
    case "ACTIVE_RECURSO_SUCESS":
      return {
        ...state,
        loadingActive: false,
        dataActive: action.payload,
        errorMsgActive: "",
        statusActive: action.status,
      };
    default:
      return state;
  }
};

export default GetRecursosReducer;
