import {
  ADD_ATRIBUTO_INIT,
  ADD_ATRIBUTO_SUCCESS,
  ADD_ATRIBUTO_FAILURE,
  RESET_ADD_ATRIBUTO,
} from "./types";
import API from "./api";

/*  Reset login esta llamado no requiere de async */

export function resetAddAtributo() {
  return {
    type: RESET_ADD_ATRIBUTO,
  };
}

/*  Llamada login Async manejamos 3 estados */

function addAtributoSuccess(data) {
  return {
    type: ADD_ATRIBUTO_SUCCESS,
    payload: data,
  };
}

function addAtributoFailure(error) {
  return {
    type: ADD_ATRIBUTO_FAILURE,
    payload: error,
  };
}

function addAtributoInit() {
  return {
    type: ADD_ATRIBUTO_INIT,
  };
}

export function addAtributo(data) {
  return async (dispatch) => {
    dispatch(addAtributoInit());
    try {
      const resp = await API.data.addAtributo(data);
      return dispatch(addAtributoSuccess(resp));
    } catch (error) {
      return dispatch(addAtributoFailure(error));
    }
  };
}
