import {
  FETCH_UPLOAD_USEREXCEL_VALIDATE_INIT,
  FETCH_UPLOAD_USEREXCEL_VALIDATE_SUCCESS,
  FETCH_UPLOAD_USEREXCEL_VALIDATE_FAILURE,
  RESET_FORM_USEREXCEL_VALIDATE,
} from "./types";
import API from "./api";

export function resetFormUserExcelValidate() {
  return {
    type: RESET_FORM_USEREXCEL_VALIDATE,
  };
}

function fetchUploadValidateApiSuccess(data) {
  return {
    type: FETCH_UPLOAD_USEREXCEL_VALIDATE_SUCCESS,
    payload: data,
  };
}

function fetchUploadValidateApiFailure(error) {
  console.log(error);
  return {
    type: FETCH_UPLOAD_USEREXCEL_VALIDATE_FAILURE,
    payload: error,
  };
}

function fetchUploadValidateApiInit() {
  return {
    type: FETCH_UPLOAD_USEREXCEL_VALIDATE_INIT,
  };
}

export function fetchUserUploadValidateApi(obj) {
  return async (dispatch) => {
    dispatch(fetchUploadValidateApiInit());
    try {
      const data = await API.data.handleUserUploadValidate(obj);
      dispatch(fetchUploadValidateApiSuccess(data));
    } catch (error) {
      dispatch(fetchUploadValidateApiFailure(error));
    }
  };
}
