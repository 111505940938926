import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* Actions */
import {
  getEdificacion,
  resetEdificacion,
  updateEdificacion,
  resetUpdateEdificacion,
} from "../../actions/update_edificacion";

import { getLabels, resetLabels } from "../../actions/filters";

/* Componets */
import FilterCountry from "../common/filterCountry";
import FilterDivision from "../common/filterDivision";
import FilterSubDivision from "../common/filterSubDivision";
import FilterSubSubDivision from "../common/filterSubSubDivision";
import ModalTimeOut from "../common/modalExpiredSession";
import Loading from "../common/loading";
import ModalMessage from "../common/modalMessage";

/* Language */
import { FormattedMessage } from "react-intl";

const FormComponent = (props) => {
  const state = useSelector((state) => state.updateEdificacion);
  const stateFilters = useSelector((state) => state.filters);

  const dispatch = useDispatch();

  /* Use state para form  */
  const [form, setForm] = useState({
    nombre: "",
    pais_id: "",
    glosa: "",
    latitud: "",
    longitud: "",
    division_id: "",
    subdivision_id: "",
    subsubdivision_id: "",
  });

  const [modalMessage, setModalMessage] = useState(false);
  const [nombres, setNombres] = useState([]);

  /* Llamda para saber cuantos nombres debo ingresar */
  useEffect(() => {
    const callsAPI = async () => {
      dispatch(getEdificacion({ edificacion_id: props.id }));
      dispatch(getLabels({ loading: true }));
    };
    callsAPI();
  }, [dispatch, props.id]);

  useEffect(() => {
    return () => {
      dispatch(resetLabels());
      dispatch(resetEdificacion());
      dispatch(resetUpdateEdificacion());
    };
  }, [dispatch]);

  useEffect(() => {
    const callsAPI = async () => {
      if (state.status === 200) {
        if (state.data.ejecucion.estado) {
          const list_name = [];
          state.data.datos.nombre.forEach((item, i) => {
            list_name.push({
              value: item.nombre || "",
              index: `name-${i}`,
              id: item.id,
              label: item.lenguaje,
            });
          });
          setNombres(list_name);
          setForm((prev) => ({
            ...prev,
            id: props.id,
            pais_id: state.data.datos.direccion.pais_id,
            division_id: state.data.datos.direccion.division_id,
            subdivision_id: state.data.datos.direccion.subdivision_id,
            subsubdivision_id: state.data.datos.direccion.subsubdivision_id,
            latitud: state.data.datos.direccion.latitud,
            longitud: state.data.datos.direccion.longitud,
            glosa: state.data.datos.direccion.glosa,
          }));
        }
      }
    };
    callsAPI();
  }, [dispatch, state.data, state.status, props.id]);

  /* Para cambiar los use states */
  const handleChange = (event) => {
    event.persist();
    setForm((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  /* Para cambiar los nombres */
  const handleNombres = (event) => {
    event.persist();
    let list_name = [...nombres];
    const index = nombres.findIndex((item) => item.index === event.target.name);
    list_name[index].value = event.target.value;
    setNombres(list_name);
  };

  /* Ingresar  empresa */
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();

    const nombresArr = [];
    nombres.forEach((item) => {
      nombresArr.push({ lenguaje_id: item.id, nombre: item.value });
    });

    const obj = {
      edificacion_id: parseInt(props.id, 10),
      division_id: form.division_id,
      subdivision_id: form.subdivision_id,
      subsubdivision_id: form.subsubdivision_id,
      pais_id: form.pais_id,
      glosa: form.glosa,
      latitud: form.latitud,
      longitud: form.longitud,
      nombre: nombresArr,
    };

    dispatch(updateEdificacion(obj));
    setModalMessage(true);
  };

  const resetForm = () => {
    dispatch(resetUpdateEdificacion());
  };

  if (state.loading || form.id === "" || stateFilters.loadingLabels) {
    return <Loading />;
  } else if (state.status === 501 || stateFilters.statusLabels === 501) {
    return <ModalTimeOut show={true} />;
  } else if (state.status === 200 || stateFilters.statusLabels === 200) {
    if (!state.data.ejecucion.estado) {
      return <h1>Problem API</h1>;
    } else if (!stateFilters.dataLabels.ejecucion.estado) {
      return <h1>Problem API</h1>;
    }
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit}>
        <div className="containerForm">
          <div className="containerFormTitle">
            <h1>Datos empresas </h1>
          </div>
          {nombres.map((item) => {
            return (
              <div className="containerFormBox" key={item.id}>
                <Form.Group>
                  <Form.Label>
                    {item.label} <FormattedMessage id="Name" /><span>hola marrano</span>
                  </Form.Label>
                  <Form.Control
                    value={item.value}
                    onChange={handleNombres}
                    type="text"
                    name={item.index}
                  />
                </Form.Group>
              </div>
            );
          })}

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Glosa<span>hola marrano</span></Form.Label>
              <Form.Control
                value={form.glosa}
                onChange={handleChange}
                type="text"
                name="glosa"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Latitud<span>hola marrano</span></Form.Label>
              <Form.Control
                value={form.latitud}
                onChange={handleChange}
                type="text"
                name="latitud"
              />
            </Form.Group>
          </div>

          <div className="containerFormBox">
            <Form.Group>
              <Form.Label>Longitud<span>hola marrano</span></Form.Label>
              <Form.Control
                value={form.longitud}
                onChange={handleChange}
                type="text"
                name="longitud"
              />
            </Form.Group>
          </div>

          <FilterCountry
            name="pais_id"
            form={form}
            handleChange={handleChange}
          />
          <FilterDivision
            title={stateFilters.dataLabels.datos.label_divisiones_singular}
            name="division_id"
            id_parent={form.pais_id}
            form={form}
            handleChange={handleChange}
          />
          <FilterSubDivision
            title={stateFilters.dataLabels.datos.label_subdivisiones_singular}
            name="subdivision_id"
            id_parent={form.division_id}
            form={form}
            handleChange={handleChange}
          />
          <FilterSubSubDivision
            title={
              stateFilters.dataLabels.datos.label_subsubdivisiones_singular
            }
            name="subsubdivision_id"
            id_parent={form.subdivision_id}
            form={form}
            handleChange={handleChange}
          />

          <div className="containerFormButton">
            <div>
              <Button
                disabled={state.loadingUpdate}
                type="submit"
                variant="warning"
              >
                {state.loadingUpdate ? (
                  <FormattedMessage id="Loading" />
                ) : (
                  <FormattedMessage id="Update" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      {state.statusUpdate === 200 && !state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={state.dataUpdate.ejecucion.mensaje}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
      {state.statusUpdate === 200 && state.dataUpdate.ejecucion.estado && (
        <ModalMessage
          message={<FormattedMessage id="SuccessfullyUpdated" />}
          modal={modalMessage}
          setModal={setModalMessage}
          func={resetForm}
          loading={state.loadingUpdate}
        />
      )}
    </React.Fragment>
  );
};

export default FormComponent;
