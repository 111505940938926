import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";

/* Actions */
import {
  GetCompanies,
  resetCompanies,
  DeleteCompany,
  resetDeleteCompany,
  GetCompaniesCheck,
  resetCompaniesCheck,
  GetCompaniesBlock,
  resetCompaniesBlock,
  resetCompaniesActive,
} from "../../actions/home/api/index";

/* Components */
import Loading from "../common/loading";
import ModalTimeOut from "../common/modalExpiredSession";
import ModalDelete from "../common/modalDelete";
import ModalCheck from "../common/modalCheck";
import ModalBlock from "../common/modalBlock";

const Empresa = () => {
  const dispatch = useDispatch();

  /* use state modals delete */
  const [modal, setModal] = useState(false);
  const [modalc, setModalc] = useState(false);
  const [modalb, setModalb] = useState(false);
  const [idDelete, setIdDelete] = useState(false);

  /* States */
  const getCompanies = useSelector((state) => state.GetCompanies);

  useEffect(() => {
    const FetchData = () => {
      dispatch(GetCompanies({}));
    };
    FetchData();
  }, [dispatch]);

  //  Reset state
  useEffect(() => {
    return () => {
      dispatch(resetCompanies());
    };
  }, [dispatch]);

  /* Funcion open modal */
  const handleModal = (obj) => {
    setModal(true);
    setIdDelete(obj.id);
  };

  /* Funcion open modal */
  const handleModalCheck = (obj) => {
    setModalc(true);
    setIdDelete(obj.id);
  };

  /* Funcion open modal */
  const handleModalBlock = (obj) => {
    setModalb(true);
    setIdDelete(obj.id);
  };

  /* Delete entidad */
  const submitDelete = () => {
    dispatch(DeleteCompany({ empresa_id: idDelete }));
  };

  const submitCheck = () => {
    dispatch(GetCompaniesCheck({ query: "check", empresa_id: idDelete }));
  };

  const submitBlock = () => {
    dispatch(GetCompaniesBlock({ query: "block", empresa_id: idDelete }));
  };

  /* Reset el estado de delete*/
  const submitResetDelete = () => {
    dispatch(resetDeleteCompany());
    dispatch(resetCompaniesCheck());
    dispatch(resetCompaniesBlock());
    dispatch(resetCompaniesActive());
    if (getCompanies.statusDelete === 200) {
      if (getCompanies.dataDelete.ejecucion.estado) {
        dispatch(GetCompanies({}));
      }
    }
  };

  const showCompanies = () => {
    if (getCompanies.loading) {
      return <Loading />;
    } else if (!_.isEmpty(getCompanies.data)) {
      return (
        <div className="containerAllEmpresas">
          {getCompanies.data.map((company, key) => {
            return (
              <div className="eachEmpresa" key={key}>
                <div className="leftSideEachEmpresa">
                  <p className="containerTableID">{company.id}</p>
                  <p className="containerTable">{company.nombre}</p>
                  <p className="containerTable">{company.uid}</p>
                  <p className="containerTable">{company.huso_horario}</p>
                  <Link
                    className="containerTableMore"
                    to={`/dashboard/update_company/${company.id}`}
                  >
                    <i className="far fa-edit"></i>
                  </Link>
                  <div className="containerTableMore">
                    <span className="containerAction">
                      <i className="fas fa-ellipsis-h"></i>
                      <div>
                        <ul>
                          <li>
                            <Link
                              to={`/dashboard/add_company_avatars/${company.id}`}
                            >
                              Agregar Imagen
                            </Link>
                          </li>
                          <li
                            onClick={() => {
                              handleModalCheck({ id: company.id });
                            }}
                          >
                            Checkear
                          </li>
                          <li
                            onClick={() => {
                              handleModalBlock({ id: company.id });
                            }}
                          >
                            Bloquear
                          </li>
                        </ul>
                      </div>
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    handleModal({ id: company.id });
                  }}
                  className="rightSideEachEmpresa"
                >
                  <div className="containerTableTrash">
                    <i className="far fa-trash-alt"></i>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (getCompanies.errorMsg !== "") {
      return <ModalTimeOut show={true} />;
    }
  };
  return (
    <div className="containerEmpresa">
      <ModalDelete
        func={() => {
          submitDelete();
        }}
        state={getCompanies}
        modal={modal}
        reset={submitResetDelete}
        setModal={setModal}
      />
      <ModalCheck
        func={() => {
          submitCheck();
        }}
        state={getCompanies}
        modal={modalc}
        reset={submitResetDelete}
        setModal={setModalc}
        idEmpresa={idDelete}
      />

      <ModalBlock
        func={() => {
          submitBlock();
        }}
        state={getCompanies}
        modal={modalb}
        reset={submitResetDelete}
        setModal={setModalb}
      />
      <p className="titleEmpresas">Empresas</p>
      <div className="lineGray"></div>
      <div className="containerSubTitleTable">
        <div className="leftSideEachEmpresa">
          <p className="containerTableID">ID</p>
          <p className="containerTable">Nombre</p>
          <p className="containerTable">UID</p>
          <p className="containerTable">Huso Horario</p>
          <p className="containerTableMore">Editar</p>
          <p className="containerTableMore">Más</p>
        </div>
        <div className="rightSideEachEmpresa">
          <p>Eliminar</p>
        </div>
      </div>
      {showCompanies()}
    </div>
  );
};

export default Empresa;
